import { QAReasonCard } from "@/components/Cards/dfp-submission-qa-card";
import { CopyableCellRenderer } from "@/components/Tables/Renderers/copyable-cell-renderer";
import { DFPTypography } from "@/components/Typography/dfp-typography";
import { useUserStore } from "@/stores/userStore";
import { parseDateToUTCString } from "@/utils/transforms";
import { LeftOutlined } from "@ant-design/icons";
import { Loader } from "@components/layouts/SignedIn/Loader/Loader";
import { getSubmissionData } from "@services/submission-service";
import { Button, Card, Descriptions, Image, theme } from "antd";
import { useEffect, useReducer } from "react";
import { LuXCircle } from "react-icons/lu";
import { useLocation } from "react-router-dom";
import { EditLog } from "./EditLog";
import { SubmissionDetailControls } from "./SubmissionDetailControls";

const { Title } = DFPTypography;

interface SubmissionProps {
	submissionId: string;
	formId: string;
	toggleSubmission: () => void;
	isQA: boolean;
	submissionsPageNum: number;
}

export interface SubmissionDetailsState {
	isLoading: boolean;
	responseData: any;
	formName: string;
	formLogo: string;
	address: string;
	locationId: string;
	userLocation: any;
	chosenAssetType: any;
	pdfLoading: boolean;
	assetEdits: any[];
	canEdit: boolean;
	tooltipMessage: string;
	submissionId: string;
	formId: string;
	isQA: boolean;
	submissionsPageNum: number;
	airportCode?: string;
	path?: string;
}

type Action = { type: "SET_STATE"; payload: Partial<SubmissionDetailsState> };

function reducer(
	state: SubmissionDetailsState,
	action: Action,
): SubmissionDetailsState {
	switch (action.type) {
		case "SET_STATE":
			return { ...state, ...action.payload };
		default:
			return state;
	}
}

function getAirportCode(
	location: ReturnType<typeof useLocation>,
	responseData: any,
): string | undefined {
	let airportCode = (location.state as any)?.airportCode;
	if (!airportCode && responseData?.location?.name) {
		const locationName = responseData.location.name;
		if (locationName.includes("JFK Airport - Terminal 7")) {
			airportCode = "JFK-T7";
		} else if (locationName.includes("JFK Terminal 4")) {
			airportCode = "JFK-T4";
		} else if (locationName.includes("Warehouse Midwest")) {
			airportCode = "Warehouse Midwest";
		} else if (locationName.includes("Warehouse South")) {
			airportCode = "Warehouse South";
		} else {
			airportCode = locationName;
		}
	}
	return airportCode;
}

// Map of form input keys to their display labels
const labelMap: Record<string, string> = {
	location: "Enter Your Location",
	equipmentAssetImage: "Photo of Equipment",
	wideAngleAssetImage: "Wide Angle Photo",
	manufacturersPlateAssetImage: "Photo of Manufacturer Plate",
	assetLocation: "Asset Location",
	assetType: "Asset Type",
	assetTypeCategory: "Asset Types",
	manufacturer: "Manufacturer",
	ifOtherManufacturer: "If Other, Enter Manufacturer",
	modelNumber: "Model Number",
	serialNumber: "Serial Number",
	manufacturerDate: "Manufactured Date",
	tempAlertIdAssetImage: "Temp Alert ID Image",
	tempAlertId: "Temp Alert ID",
	idTagAssetImage: "Photo of Tag ID",
	assetTag: "Asset Tag ID",
	warrantyNotFound: "No Warranty?",
	warrantyPeriod: "Warranty Period (Months)",
	warrantyStartDate: "Warranty Start Date",
	warrantyExpirationDate: "Warranty Expiration Date",
	warrantyProvider: "Warranty Provider",
	assetCondition: "Asset Condition",
	assetGeneralNotes: "Enter this asset's status comments",
	qsrStore: "Store Name",
};

// Keys that are not required to be displayed
const notRenderedKeys = [
	"userLocation",
	"audited",
	"deleted",
	"formId",
	"locationId",
	"manufacturerPlateNotAvailable",
	"manufacturerDateNotFound",
	"manufacturerNotAvailable",
	"serialNotAvailable",
	"modelNotAvailable",
	"assetTypeRef",
	"beginDate",
];

function toTitleCase(str: string) {
	return str
		.replace(/([A-Z])/g, " $1")
		.replace(/[_]+/g, " ")
		.replace(/\s+/g, " ")
		.trim()
		.replace(
			/\w\S*/g,
			(txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
		);
}

function getDisplayValue(value: any, token: any) {
	if (typeof value === "string") {
		if (
			value.match(/\.(jpeg|jpg|gif|png|webp|svg)$/i) ||
			value.startsWith("http")
		) {
			return (
				<Image
					src={value}
					alt=""
					wrapperClassName="w-50 cursor-pointer"
					style={{ maxWidth: "300px", maxHeight: "300px" }}
					wrapperStyle={{ maxWidth: "300px", maxHeight: "300px" }}
					preview={{
						destroyOnClose: true,
						closeIcon: (
							<span
								className="p-2 bg-white"
								style={{ borderRadius: token.borderRadiusDefault }}
							>
								<LuXCircle size={"1.5rem"} color={token.colorPrimary} />
							</span>
						),
						maskStyle: { backgroundColor: token.maskBg },
						toolbarRender(originalNode) {
							return (
								<div
									style={{
										color: token.colorPrimary,
										backgroundColor: token.colorWhite,
										borderRadius: token.borderRadiusDefault,
									}}
								>
									{originalNode}
								</div>
							);
						},
					}}
				/>
			);
		}
		return <CopyableCellRenderer value={value} />;
	}
	if (typeof value === "boolean") {
		return value ? "true" : "false";
	}
	if (value && typeof value === "object" && "name" in value) {
		return <CopyableCellRenderer value={value.name} />;
	}
	return "N/A";
}

const Submission: React.FC<SubmissionProps> = (props) => {
	const { submissionId, formId, toggleSubmission, isQA, submissionsPageNum } =
		props;
	const location = useLocation();
	const { userOrganization } = useUserStore();
	const { token } = theme.useToken();

	const initialState: SubmissionDetailsState = {
		isLoading: true,
		responseData: null,
		formName: "",
		formLogo: "",
		address: "",
		locationId: "",
		userLocation: undefined,
		chosenAssetType: null,
		pdfLoading: false,
		assetEdits: [],
		canEdit: false,
		tooltipMessage: "",
		submissionId,
		formId,
		isQA,
		submissionsPageNum,
		airportCode: undefined,
		path: location.pathname,
	};

	const [state, dispatch] = useReducer(reducer, initialState);

	useEffect(() => {
		if (!submissionId || !userOrganization) return;
		getSubmissionData(
			userOrganization.external_firebase_id,
			formId,
			submissionId,
			isQA,
			(data) =>
				dispatch({ type: "SET_STATE", payload: { responseData: data } }),
			(name) => dispatch({ type: "SET_STATE", payload: { formName: name } }),
			(logo) => dispatch({ type: "SET_STATE", payload: { formLogo: logo } }),
			(address) => dispatch({ type: "SET_STATE", payload: { address } }),
			(locationId) => dispatch({ type: "SET_STATE", payload: { locationId } }),
			(userLocation) =>
				dispatch({ type: "SET_STATE", payload: { userLocation } }),
			(chosenAssetType) =>
				dispatch({ type: "SET_STATE", payload: { chosenAssetType } }),
			(assetEdits) => dispatch({ type: "SET_STATE", payload: { assetEdits } }),
		).finally(() => {
			const airportCode = getAirportCode(location, state.responseData);
			dispatch({
				type: "SET_STATE",
				payload: { isLoading: false, airportCode },
			});
		});
	}, [submissionId, userOrganization, formId, location]);

	const generateQuestions = () => {
		const rawInputs = state.responseData?.raw_input || {};
		const filteredEntries = Object.entries(rawInputs).filter(
			([key]) =>
				!notRenderedKeys.includes(key) &&
				rawInputs[key] !== null &&
				rawInputs[key] !== undefined &&
				rawInputs[key] !== "" &&
				rawInputs[key] !== "N/A",
		);

		return (
			<Descriptions bordered column={1} labelStyle={{ fontWeight: "bold" }}>
				{filteredEntries.map(([key, value]) => {
					const label = labelMap[key] ? labelMap[key] : toTitleCase(key);

					let displayValue;
					if (key === "location") {
						// Special logic for location key
						displayValue = state.address;
					} else {
						displayValue = getDisplayValue(value, token);
					}

					return (
						<Descriptions.Item key={key} label={label}>
							{displayValue}
						</Descriptions.Item>
					);
				})}
			</Descriptions>
		);
	};

	const formatQAFlags = (qaFlags: any[]) => {
		return <QAReasonCard qaFlags={[...qaFlags]} />;
	};

	return state.isLoading ? (
		<Loader />
	) : (
		<div className="h-100">
			<Card className="p-3 submission-wrapper" id="submission">
				<div className="submission-header d-flex flex-row align-items-center justify-content-between h-100">
					<Button
						className="mb-4"
						type="link"
						onClick={toggleSubmission}
						icon={<LeftOutlined />}
					>
						Back to Submissions
					</Button>
					<SubmissionDetailControls
						state={state}
						dispatch={dispatch}
						toggleSubmission={toggleSubmission}
					/>
				</div>
				<div id="submission-content">
					<img
						src={state.formLogo}
						className="submission-logo mt-2"
						alt="logo"
					/>
					<div
						className="d-flex flex-row align-items-center justify-content-between"
						style={{
							backgroundColor: "#ffffff",
							border: "none",
						}}
					>
						<Title level={3} className="submission-title">
							{state.formName}
						</Title>
					</div>
					{state.responseData?.asset?.is_deleted && (
						<div
							className="w-100 p-2 bg-red text-center text-white fw-bold my-2"
							style={{ backgroundColor: "red" }}
						>
							DELETED on{" "}
							{parseDateToUTCString(state.responseData?.asset?.is_deleted)}
						</div>
					)}
					{state.responseData?.is_qa_flagged &&
						formatQAFlags(state.responseData?.qa_flags)}
					<Descriptions
						className="mb-2"
						bordered
						column={1}
						labelStyle={{ fontWeight: "bold" }}
					>
						<Descriptions.Item label="Date of Visit">
							<CopyableCellRenderer
								value={
									parseDateToUTCString(state.responseData?.end_time) || "N/A"
								}
							/>
						</Descriptions.Item>
					</Descriptions>
					{generateQuestions()}
					{state.assetEdits.length > 0 && (
						<div className="mt-4 submission-edit-log">
							<EditLog editLog={state.assetEdits} />
						</div>
					)}
				</div>
			</Card>
		</div>
	);
};

export { Submission };
