import { searchAssets } from "@/services/asset-service";
import { message } from "antd";
import type { Dispatch } from "react";
import type { Action, State } from "../children/AssetsTable";

export const fetchData = async (
	state: State,
	dispatch: Dispatch<Action>,
	browserLocationId: string,
) => {
	let { pageNum, pageSize, sortBy, sortOrder, searchVal, dateRange, filters } =
		state;
	try {
		dispatch({ type: "SET_IS_LOADING", payload: true });

		let responses = await searchAssets(
			searchVal,
			pageNum,
			pageSize,
			sortBy,
			sortOrder,
			browserLocationId,
			dateRange[0],
			dateRange[1],
			filters,
		);

		if (responses?.results?.length === 0 && pageNum > 1) {
			pageNum = 1;
			responses = await searchAssets(
				searchVal,
				pageNum,
				pageSize,
				sortBy,
				sortOrder,
				browserLocationId,
				dateRange[0],
				dateRange[1],
				filters,
			); // Fetch data again
			message.info("No results found on this page, showing first page.");
		}

		dispatch({ type: "SET_PAGE_NUM", payload: pageNum });
		dispatch({
			type: "SET_DATA_SOURCE",
			payload: responses?.results || [],
		});
		dispatch({ type: "SET_COUNT", payload: responses.count || 0 });
	} catch (exception) {
		console.error(`Failed to search for submissions: ${exception}`);
		throw new Error("Failed to search for submissions");
	} finally {
		dispatch({ type: "SET_IS_LOADING", payload: false });
	}
};
