import { useGetSelfOrganization } from "@/hooks/organizationQueries";
import { returnNAforUnknownValues } from "@/utils/isUnknownValue";
import { auth } from "@assets/services/auth-service";
import { useGetAssetTypes } from "@hooks/assetQueries";
import { getAssets as getOrgAssets } from "@services/location-service";
import { useEffect } from "react";

interface SelfOrganization {
	config: OrganizationConfig;
	external_firebase_id: string;
	full_name: string;
	health: Record<string, unknown>;
	id: number;
}

interface OrganizationConfig {
	organization_id: number;
	show_iguide?: boolean;
	show_matterport?: boolean;
	auto_save_drafts?: boolean;
	is_location_name_required?: boolean;
	allow_tag_id?: boolean;
	service_channel_export_enabled?: boolean;
	export_options?: Record<string, unknown>;
	org_specific_inputs?: Record<string, unknown>;
	sc_export_options?: Record<string, unknown>;
	unique_fields?: Record<string, unknown>;
	integration_settings?: Record<string, unknown>;
	logo_url?: string | null;
}

interface LeaveBehindReportProps {
	selectedDependencyValue: Record<string, any> | null;
	organization: Partial<SelfOrganization>;
	isLoading: boolean;
	setIsLoading: (isLoading: boolean) => void;
	showToast: (title: string, message: string, type?: string) => void;
}

interface Asset {
	id: string;
	type: string | null;
	make: string | null;
	model: string | null;
	serial: string | null;
	img: string | null;
	img2: string | null; // This will now hold the tagId
	location: string | null;
	qsrStore: string | null;
	subType?: string | null;
}

const LeaveBehindReport = ({
	selectedDependencyValue,
	organization,
	isLoading: isLeaveBehindReportLoading,
	setIsLoading,
	showToast,
}: LeaveBehindReportProps) => {
	const { userOrganization } = useGetSelfOrganization();
	const { data: orgAssetTypes } = useGetAssetTypes(
		organization?.external_firebase_id || "",
	);

	useEffect(() => {
		if (isLeaveBehindReportLoading) {
			const generateReport = async () => {
				try {
					if (!orgAssetTypes) {
						throw new Error("Asset types data not available");
					}
					if (!userOrganization) {
						throw new Error("User organization data not available");
					}

					const locationId =
						selectedDependencyValue &&
						typeof selectedDependencyValue === "object"
							? selectedDependencyValue.id
							: null;
					const address1 =
						selectedDependencyValue &&
						typeof selectedDependencyValue === "object"
							? selectedDependencyValue.address1
							: null;
					const address2 =
						selectedDependencyValue &&
						typeof selectedDependencyValue === "object"
							? `${selectedDependencyValue.city}, ${selectedDependencyValue.state} ${selectedDependencyValue.zip}`
							: null;

					if (!locationId) {
						throw new Error("Location ID is null");
					}

					// Get assets
					const orgAssets = await getOrgAssets(Number(locationId));
					const assetTypesSnap = orgAssetTypes || [];

					// Process assets
					const assetRows: (Asset | null)[] = await Promise.all(
						orgAssets.map(async (asset: any) => {
							if (asset.is_deleted !== null) {
								return null;
							}
							const tagId = asset.asset_tag;

							const newAsset: Asset = {
								id: returnNAforUnknownValues(asset.asset_tag) || "N/A",
								type: returnNAforUnknownValues(
									asset?.organization_asset_type?.name,
								),
								make: returnNAforUnknownValues(
									asset?.model?.manufacturer?.name,
								),
								model: returnNAforUnknownValues(asset?.model?.model_number),
								serial: returnNAforUnknownValues(asset.serial_number),
								img:
									asset.photos.find((item: any) => item.type === "EQUIPMENT")
										?.url || null,
								img2: tagId || null,
								location:
									organization.external_firebase_id === "SSPAMERICA"
										? selectedDependencyValue?.name || "N/A"
										: asset?.location_in_facility?.name || "N/A",
								qsrStore: asset?.unique_fields?.qsrStore || null,
							};

							const modelUnknown = newAsset.model === "UNKNOWN_MODEL";
							if (!newAsset.model || modelUnknown) {
								newAsset.model = "N/A";
							}
							if (!newAsset.make) {
								newAsset.make = "N/A";
							}

							const assetType = assetTypesSnap.find(
								(aType: any) => aType.name === newAsset.type,
							);
							if (assetType) {
								newAsset.subType = assetType?.category?.name || null;
							}
							return newAsset;
						}),
					);

					const filteredAssetRows = assetRows.filter(
						(asset): asset is Asset => asset !== null,
					);

					// Get logo
					const orgLogo = userOrganization?.config.logo_url || "";

					// Get categories
					const newCategories: Record<string, Asset[]> = {};
					for (const asset of filteredAssetRows) {
						if (newCategories[asset.location]) {
							newCategories[asset.location].push(asset);
						} else {
							newCategories[asset.location] = [asset];
						}
					}

					// Prepare raw data to send to Cloud Function
					const dataToSend = {
						organizationName: userOrganization?.full_name || "N/A",
						address1: address1 || "N/A",
						address2: address2 || "N/A",
						logoUrl: orgLogo,
						date: new Date().toISOString().split("T")[0],
						categories: newCategories,
					};

					const currentDate = new Date().toISOString().split("T")[0];

					// Prepare payload for Cloud Function
					const payload = {
						data: dataToSend,
						email: auth?.currentUser?.email,
						location: `${address1}, ${address2}`,
						date: currentDate,
						org: organization.external_firebase_id,
					};

					// Send POST request to Cloud Function
					const response = await fetch(
						"https://us-central1-property-echo-4d747.cloudfunctions.net/downloadLeaveBehind",
						{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
							},
							body: JSON.stringify(payload),
						},
					);

					if (!response.ok) {
						throw new Error(
							`Download PDF request failed with status ${response.status}`,
						);
					}

					showToast(
						"Generating PDF",
						"You will receive an email when your download link is ready.",
					);
					setIsLoading(false);
				} catch (error: any) {
					console.error("Error generating report:", error);
					showToast(
						"Error",
						error.message || "Failed to generate PDF.",
						"danger",
					);
					setIsLoading(false);
				}
			};

			generateReport();
		}
	}, [
		isLeaveBehindReportLoading,
		orgAssetTypes,
		userOrganization,
		selectedDependencyValue,
		organization,
		setIsLoading,
		showToast,
	]);

	return null;
};

export { LeaveBehindReport };

// Function specific for generating Leave Behind Report
export const generateLeaveBehindReport = (
	selectedDependencyValue: any,
	setIsLoading: (isLoading: boolean) => void,
	showToast: (title: string, message: string, type?: string) => void,
) => {
	if (!selectedDependencyValue) {
		setIsLoading(false);
		return showToast("Error", "Please select a location", "danger");
	}
	setIsLoading(true);
};
