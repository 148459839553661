import { CopyableTableCell } from "@/components/Typography/dfp-typography";
import { HealthScorePill } from "@/components/layouts/SignedIn/HealthScorePill/HealthScorePill";
import { parseDateToUTCString } from "@/utils/transforms";
import { Skeleton } from "antd";
import type { ColumnsType } from "antd/es/table";
import { BsShieldFillCheck, BsShieldFillMinus } from "react-icons/bs";
import { State } from "./AssetsTable";

export const initialColumns: ColumnsType = [
	{
		title: "Warranty",
		dataIndex: "warranty",
		key: "warranty",
		render: (text, record) => {
			if (record.isLoading)
				return (
					<Skeleton.Input
						style={{ minWidth: 50, width: 50 }}
						active
						size="small"
					/>
				);
			return (
				<div style={{ height: "22px" }}>
					{text ? (
						<BsShieldFillCheck className="green assets-table-icon fs-4" />
					) : (
						<BsShieldFillMinus className="red assets-table-icon fs-4" />
					)}
				</div>
			);
		},
		align: "center",
		width: "5%",
	},
	{
		title: "Address",
		dataIndex: "address",
		key: "address1",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (text, record) => (
			<CopyableTableCell text={text} isLoading={record.isLoading} />
		),
		width: "25%",
	},
	{
		title: "Asset Type",
		dataIndex: ["asset_type", "name"],
		key: "asset_type_name",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (text, record) => (
			<CopyableTableCell text={text} isLoading={record.isLoading} />
		),
		width: "18%",
	},
	{
		title: "Make",
		dataIndex: "manufacturer",
		key: "manufacturer",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (text, record) => (
			<CopyableTableCell text={text} isLoading={record.isLoading} />
		),

		width: 200,
	},
	{
		title: "Model",
		dataIndex: "model",
		key: "model_number",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (text, record) => (
			<CopyableTableCell text={text} isLoading={record.isLoading} />
		),
		width: 200,
	},
	{
		title: "Manufacturer Date",
		dataIndex: "manufacturer_date",
		key: "manufacturer_date",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (date: string, row: any) => {
			return (
				<CopyableTableCell
					text={parseDateToUTCString(date) || "N/A"}
					backfillValue={parseDateToUTCString(
						row?.generated_ai_data?.manufacturer_date,
					)}
					isLoading={row.isLoading}
				/>
			);
		},
		width: "12%",
	},
	{
		title: "Organization",
		dataIndex: "sub_org",
		key: "sub_org",
		render: (text, record) => (
			<CopyableTableCell text={text} isLoading={record.isLoading} />
		),
		width: 300,
		hidden: true,
	},
	{
		title: "Temp Alert ID",
		dataIndex: "temp_alert_id",
		key: "temp_alert_id",
		render: (text, record) => (
			<CopyableTableCell text={text} isLoading={record.isLoading} />
		),
		width: 300,
		hidden: true,
	},
	{
		title: "Category",
		dataIndex: "asset_type_category",
		key: "asset_type_category",
		render: (text, record) => (
			<CopyableTableCell text={text} isLoading={record.isLoading} />
		),
		hidden: true,
	},
	{
		title: "Submission Date",
		dataIndex: "submitted_date",
		key: "submitted_date",
		render: (date: string, record) => (
			<CopyableTableCell
				text={parseDateToUTCString(date) || "N/A"}
				isLoading={record.isLoading}
			/>
		),
		hidden: true,
	},
	{
		title: "Health Score",
		dataIndex: "health_score",
		key: "health_score",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (healthScore, record) =>
			record.isLoading ? (
				<Skeleton.Input style={{ width: 120 }} active size="small" />
			) : (
				<HealthScorePill healthScore={healthScore} />
			),
		width: "10%",
	},
];

export const getOrgColumns = (organization: any, state: State): ColumnsType => {
	const filterProps = {
		filters: state?.filterOptions?.assetTypes
			? state.filterOptions.assetTypes.map((type) => ({
					text: type.name,
					value: type.name,
				}))
			: undefined,
		filteredValue: state?.filters?.asset_type_name
			? state.filters.asset_type_name
			: undefined,
		filterSearch: true,
	};

	const columns: ColumnsType = initialColumns.map((column) => {
		if (column.key === "asset_type_name") {
			return { ...column, ...filterProps };
		}
		return column;
	});

	switch (organization.external_firebase_id) {
		default:
			return columns;
	}
};
