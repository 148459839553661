import { InboxOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
	Button,
	Col,
	DatePicker,
	Form,
	type FormInstance,
	Image,
	Input,
	InputNumber,
	Modal,
	Progress,
	Radio,
	Row,
	Select,
	Spin,
	Steps,
	Tag,
	Tooltip,
	Typography,
	Upload,
	message,
	theme,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import {
	Fragment,
	type ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useReducer,
	useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Loader } from "@/components/layouts/SignedIn/Loader/Loader";
import DynamicLocationPicker from "@/components/layouts/SignedIn/views/SingleForm/DynamicLocationPicker";
import { uploadFieldCaptureFromDataUrl } from "@/services/storage-service";
import {
	createDraft,
	submitSubmission,
	updateDraft,
	updateSubmission,
} from "@/services/submission-service";
import { getFormData } from "@services/form-service";
import { useFormStore, useUserStore } from "@stores";
import { debounce } from "@utils/debounce";
import type { UploadFile } from "antd/es/upload/interface";
import { LuXCircle } from "react-icons/lu";

const { Title } = Typography;
const { Dragger } = Upload;
const { Step } = Steps;

interface FieldOption {
	value: string | number;
	label: string;
}

interface FieldValidation {
	required?: boolean;
	pattern?: RegExp;
	minLength?: number;
	maxLength?: number;
	min?: number;
	max?: number;
	message?: string;
}

interface FieldDataSource {
	type: "api" | "static" | "function";
	endpoint?: string;
	options?: FieldOption[];
	dependencies?: string[];
	fetchFunction?: (values: Record<string, any>) => Promise<FieldOption[]>;
}

type ConditionalOperator =
	| "equals"
	| "notEquals"
	| "in"
	| "notIn"
	| "greaterThan"
	| "lessThan"
	| "contains";

interface FieldConditional {
	dependsOn: string;
	operator?: ConditionalOperator;
	value?: any;
	values?: any[];
}

interface FieldFormat {
	formatFn?: string;
	inputSuffix?: string;
}

interface FieldComputed {
	dependsOn: string[];
	calculate: Function | string;
}

interface TooltipLink {
	url: string;
	label: string;
}

interface FieldTooltip {
	text?: string;
	html?: string;
	links?: TooltipLink[];
}

interface FormField {
	type: string;
	name?: string;
	key?: string;
	responseTag?: string;
	label?: string;
	tooltip?: FieldTooltip;
	placeholder?: string;
	required?: boolean;
	disabled?: boolean;
	hide?: boolean;
	options?: FieldOption[];
	dataSource?: FieldDataSource;
	conditional?: FieldConditional;
	multiple?: boolean;
	draggerProps?: {
		multiple?: boolean;
		maxCount?: number;
		accept?: string;
	};
	fields?: FormField[];
	countField?: string;
	validation?: FieldValidation | FieldValidation[];
	note?: string;
	format?: FieldFormat;
	computed?: FieldComputed;
	value?: any;
}

interface FormSection {
	title: string;
	description?: string;
	fields?: FormField[];
	questions?: FormField[];
}

interface FormConfig {
	config: {
		sections: FormSection[];
	};
}

interface FormState {
	currentStep: number;
	loadingFields: Record<string, boolean>;
	fieldOptions: Record<string, FieldOption[]>;
	dynamicListState: Record<string, number>;
}

interface Action {
	type: string;
	payload?: any;
}

const initialState: FormState = {
	currentStep: 0,
	loadingFields: {},
	fieldOptions: {},
	dynamicListState: {},
};

const formReducer = (state: FormState, action: Action): FormState => {
	switch (action.type) {
		case "SET_CURRENT_STEP":
			return { ...state, currentStep: action.payload };
		case "SET_LOADING_FIELD":
			return {
				...state,
				loadingFields: { ...state.loadingFields, ...action.payload },
			};
		case "SET_FIELD_OPTIONS":
			return {
				...state,
				fieldOptions: { ...state.fieldOptions, ...action.payload },
			};
		case "SET_DYNAMIC_LIST_STATE":
			return {
				...state,
				dynamicListState: { ...state.dynamicListState, ...action.payload },
			};
		default:
			return state;
	}
};

function getDynamicListCount(
	allValues: Record<string, any>,
	field: FormField,
): number {
	if (!field.countField || !field.name) return 0;

	let countFieldPath = field.countField;
	if (!field.countField.includes(".") && field.name.includes(".")) {
		const pathSegments = field.name.split(".");
		pathSegments.pop();
		const parentPath = pathSegments.join(".");
		countFieldPath = parentPath
			? `${parentPath}.${field.countField}`
			: field.countField;
	}

	const val = allValues[countFieldPath];
	if (val === undefined || val === null) return 0;
	const num = Number.parseInt(val, 10);
	return Number.isNaN(num) ? 0 : num;
}

function renderTooltipContent(tooltip: FieldTooltip): ReactNode {
	const { text, html, links } = tooltip;
	return (
		<div style={{ maxWidth: "300px" }}>
			{html && (
				<div
					dangerouslySetInnerHTML={{ __html: html }}
					style={{ marginBottom: text || links ? "8px" : 0 }}
				/>
			)}
			{!html && text && (
				<div style={{ marginBottom: links ? "8px" : 0 }}>{text}</div>
			)}
			{links && links.length > 0 && (
				<ul style={{ paddingLeft: "18px", margin: 0 }}>
					{links.map((link, idx) => (
						<li key={`${link.url}-${idx}`}>
							<a
								style={{ color: "white" }}
								href={link.url}
								target="_blank"
								rel="noopener noreferrer"
							>
								{link.label}
							</a>
						</li>
					))}
				</ul>
			)}
		</div>
	);
}

function getFieldLabel(field: FormField): ReactNode {
	if (!field.label && !field.tooltip) return field.label || null;
	if (!field.tooltip) return field.label;

	return (
		<span>
			{field.label}
			{field.tooltip && (
				<Tooltip title={renderTooltipContent(field.tooltip)} placement="top">
					<InfoCircleOutlined style={{ marginLeft: 8, cursor: "pointer" }} />
				</Tooltip>
			)}
		</span>
	);
}

interface DraftSaveProps {
	formId: string | null;
	formData: Record<string, any>;
}

const DynamicSubmissionForm: React.FC = () => {
	const [form] = Form.useForm();
	const [formConfig, setFormConfig] = useState<FormConfig | null>(null);
	const [formName, setFormName] = useState("");
	const [formLogo, setFormLogo] = useState("");
	const { token } = theme.useToken();

	const { user, userOrganization } = useUserStore();
	const { draftId, setDraftId, formData, setFormData } = useFormStore();
	const { id } = useParams();
	const location = useLocation();
	const formId = (location.state?.formId as string) || id || null;
	const organization_id = user.organization.external_firebase_id;
	const navigate = useNavigate();

	const [shouldAutoSave, setShouldAutoSave] = useState(false);
	const [isEditing, setIsEditing] = useState(
		!!location.state?.submissionId || !!location.state?.draftId,
	);

	const [previewVisible, setPreviewVisible] = useState(false);
	const [previewImage, setPreviewImage] = useState("");
	const [previewTitle, setPreviewTitle] = useState("");

	const [state, dispatch] = useReducer(formReducer, initialState);
	const { fieldOptions } = state;

	const [internalDataLoaded, setInternalDataLoaded] = useState(false);

	const formKey = useMemo(() => location.pathname, [location.pathname]);

	const clearState = useCallback(() => {
		setDraftId(null);
		setFormData({});
		setShouldAutoSave(false);
		setFormConfig(null);
		setFormName("");
		setFormLogo("");
		setPreviewVisible(false);
		setPreviewImage("");
		setPreviewTitle("");
		dispatch({ type: "SET_CURRENT_STEP", payload: 0 });
		dispatch({ type: "SET_FIELD_OPTIONS", payload: {} });
		dispatch({ type: "SET_DYNAMIC_LIST_STATE", payload: {} });
		form.resetFields();
		setInternalDataLoaded(false);
	}, [form, setDraftId, setFormData]);

	useEffect(() => {
		clearState();
	}, [location.pathname, clearState]);

	useEffect(() => {
		const fetchForm = async () => {
			try {
				let parsedInputs: any;
				if (location.state?.inputs) {
					parsedInputs = location.state.inputs;
				} else if (location.state?.responseData) {
					parsedInputs = location.state.responseData;
				}

				if (parsedInputs?.sections) {
					setFormLogo(
						parsedInputs?.logo || userOrganization?.config?.logo_url || "",
					);
					setFormName(parsedInputs?.title || "Form");

					const { initialValues, dynamicCounts } =
						buildInitialValuesFromParsedInputs(parsedInputs);
					setFormData(initialValues);
					if (Object.keys(dynamicCounts).length > 0) {
						dispatch({
							type: "SET_DYNAMIC_LIST_STATE",
							payload: dynamicCounts,
						});
					}

					const directConfig: FormConfig = {
						config: { sections: parsedInputs.sections },
					};
					preprocessSections(directConfig);
					prepareComputedFields(directConfig);
					setFormConfig(directConfig);
					setShouldAutoSave(
						userOrganization?.config?.auto_save_drafts === true,
					);
				} else {
					if (!formId) return;
					await getFormData(
						organization_id,
						Number.parseInt(formId),
						setFormName,
						setFormLogo,
						(config: FormConfig | null) => {
							if (config) {
								preprocessSections(config);
								prepareComputedFields(config);
								setFormConfig(config);
								setShouldAutoSave(
									userOrganization?.config?.auto_save_drafts === true,
								);
							} else {
								setFormConfig({ config: { sections: [] } });
							}
						},
					);
				}

				if (location.state?.submissionId || location.state?.draftId)
					setDraftId(location.state.submissionId || location.state.draftId);
				if (location.state?.submissionId || location.state?.draftId)
					setIsEditing(true);

				setInternalDataLoaded(true);
			} catch {
				message.error("Error initializing form.");
			}
		};

		fetchForm();
	}, [
		organization_id,
		formId,
		userOrganization,
		location.state,
		setDraftId,
		setFormData,
	]);

	// On initial load or updates, sync form fields with formData once
	useEffect(() => {
		if (internalDataLoaded && formData && Object.keys(formData).length > 0) {
			// Just set fields from formData initially
			form.setFieldsValue(formData);
		}
	}, [formData, internalDataLoaded, form]);

	// Compute fields in a separate useEffect whenever formData changes
	// to prevent infinite loops in onValuesChange
	useEffect(() => {
		if (!formConfig || !internalDataLoaded) return;
		const updatedData = { ...formData };
		computeAllFields(updatedData, formConfig);
		// If computations changed some values, update formData and form fields
		if (JSON.stringify(updatedData) !== JSON.stringify(formData)) {
			setFormData(updatedData);
			form.setFieldsValue(updatedData);
		}
		// Only runs when formData changes
	}, [formData, formConfig, internalDataLoaded, form, setFormData]);

	const fetchFieldData = async (
		field: FormField,
		changedValues: Record<string, any> = {},
	) => {
		if (!field.name) return;
		dispatch({ type: "SET_LOADING_FIELD", payload: { [field.name]: true } });

		try {
			let data: FieldOption[] = [];

			if (field.dataSource?.type === "api" && field.dataSource.endpoint) {
				let params: Record<string, any> = {};
				if (field.dataSource.dependencies) {
					params = field.dataSource.dependencies.reduce((acc, dep) => {
						acc[dep] = form.getFieldValue(dep) || changedValues[dep];
						return acc;
					}, {});
				}

				const response = await axios.get(field.dataSource.endpoint, { params });
				data = response.data;
			} else if (field.dataSource?.type === "static") {
				data = field.dataSource.options || [];
			} else if (
				field.dataSource?.type === "function" &&
				field.dataSource.fetchFunction
			) {
				data = await field.dataSource.fetchFunction(form.getFieldsValue());
			}

			dispatch({ type: "SET_FIELD_OPTIONS", payload: { [field.name]: data } });
		} catch {
			dispatch({ type: "SET_FIELD_OPTIONS", payload: { [field.name]: [] } });
		} finally {
			dispatch({ type: "SET_LOADING_FIELD", payload: { [field.name]: false } });
		}
	};

	useEffect(() => {
		if (formConfig) {
			const allFields = getAllFields(formConfig.config.sections);
			allFields.forEach((field) => {
				if (
					field.dataSource &&
					field.dataSource.type === "api" &&
					!field.dataSource.dependencies
				) {
					fetchFieldData(field);
				}
			});
		}
	}, [formConfig]);

	const saveProgressDebounced = useCallback(
		debounce(async (props: DraftSaveProps) => {
			const currentDraftId = useFormStore.getState().draftId;
			const { formData, formId } = props;
			const dataToSave = buildSubmissionData(formConfig, formData);
			const locationId =
				formData.location_id || formData.location || location.state?.locationId;

			if (
				!dataToSave ||
				!formId ||
				!formData ||
				!formConfig ||
				!shouldAutoSave ||
				!locationId
			) {
				return;
			}

			try {
				if (!currentDraftId) {
					const newDraftId = await createDraft({
						location_id: locationId,
						form_id: Number.parseInt(formId),
						data: { ...dataToSave },
					});
					setDraftId(newDraftId);
					message.success("Draft saved successfully.");
				} else {
					await updateDraft(currentDraftId, { ...dataToSave });
					message.success("Draft updated successfully.");
				}
			} catch {
				message.error("Failed to save draft.");
			}
		}, 4500),
		[
			formConfig,
			formId,
			setDraftId,
			shouldAutoSave,
			location.state?.locationId,
		],
	);

	const attemptAutoSave = (mergedData: Record<string, any>) => {
		if (shouldAutoSave && formId !== null && formConfig) {
			saveProgressDebounced({
				formId,
				formData: mergedData,
			});
		}
	};

	const getBase64 = (file: Blob): Promise<string | ArrayBuffer | null> => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	};

	const handlePreview = async (file: UploadFile) => {
		let url = file.url || file.response?.url;

		if (!url && !file.preview && file.originFileObj) {
			file.preview = (await getBase64(file.originFileObj)) as string;
			url = file.preview;
		}

		if (url) {
			setPreviewImage(url);
			setPreviewVisible(true);
			setPreviewTitle(file.name || url.substring(url.lastIndexOf("/") + 1));
		}
	};

	const normFile = (e: any): UploadFile[] => {
		if (Array.isArray(e)) return e;
		return e?.fileList || [];
	};

	function evaluateCondition(field: FormField, form: FormInstance): boolean {
		if (!field.conditional) return true;
		const { dependsOn, operator = "equals", value, values } = field.conditional;
		const allValues = form.getFieldsValue(true);

		let actualPath = dependsOn;
		if (!dependsOn.includes(".") && field.name && field.name.includes(".")) {
			const parts = field.name.split(".");
			parts.pop();
			const prefix = parts.join(".");
			actualPath = `${prefix}.${dependsOn}`;
		}

		const dependentValue = allValues[actualPath];

		switch (operator) {
			case "equals":
				return dependentValue === value;
			case "notEquals":
				return dependentValue !== value;
			case "in":
				return Array.isArray(values) && values.includes(dependentValue);
			case "notIn":
				return Array.isArray(values) && !values.includes(dependentValue);
			case "greaterThan":
				return typeof dependentValue === "number" && dependentValue > value;
			case "lessThan":
				return typeof dependentValue === "number" && dependentValue < value;
			case "contains":
				return Array.isArray(dependentValue) && dependentValue.includes(value);
			default:
				return true;
		}
	}

	function renderField(field: FormField, form: FormInstance): ReactNode {
		const fieldName = field.name || field.responseTag || field.key;
		if (!evaluateCondition(field, form)) return null;

		if (field.type === "group" && field.fields) {
			return (
				<Fragment key={fieldName}>
					{field.fields.map((subField, idx) => (
						<Fragment key={`${fieldName}-${idx}`}>
							{renderField(subField, form)}
						</Fragment>
					))}
				</Fragment>
			);
		}

		if (field.type === "dynamicList" && field.fields) {
			const allValues = form.getFieldsValue(true);
			const count = getDynamicListCount(allValues, field);

			return (
				<Fragment key={fieldName}>
					{Array.from({ length: count }).map((_, i) => (
						<div key={`${fieldName}-${i}`} className="mb-4">
							{field.label && (
								<Tag className="my-2" color="purple">
									{field.label}
									{field.tooltip && (
										<Tooltip
											title={renderTooltipContent(field.tooltip)}
											placement="top"
										>
											<InfoCircleOutlined
												style={{ marginLeft: 8, cursor: "pointer" }}
											/>
										</Tooltip>
									)}{" "}
									{i + 1}
								</Tag>
							)}
							{field?.fields?.map((subField) => {
								const subBaseName = (
									subField.name ||
									subField.responseTag ||
									subField.key ||
									""
								)
									.split(".")
									.pop();
								const indexedSubField: FormField = {
									...subField,
									name: `${field.name}.${i}.${subBaseName}`,
								};
								return (
									<Fragment key={`${fieldName}-${i}-${indexedSubField.name}`}>
										{renderField(indexedSubField, form)}
									</Fragment>
								);
							})}
						</div>
					))}
				</Fragment>
			);
		}

		return renderAtomicField(
			field,
			form,
			fieldOptions,
			handlePreview,
			normFile,
		);
	}

	function renderAtomicField(
		field: FormField,
		form: FormInstance,
		fieldOptions: Record<string, FieldOption[]>,
		handlePreview: (file: UploadFile) => Promise<void>,
		normFile: (e: any) => UploadFile[],
	): ReactNode {
		const fieldName = field.name || field.responseTag || field.key;
		if (!fieldName) return null;

		const rules = buildValidationRules(field);
		let formatFn: ((value: string) => string) | undefined;
		if (field.format?.formatFn) {
			try {
				formatFn = eval(`(${field.format.formatFn})`);
			} catch {
				formatFn = undefined;
			}
		}

		const inputSuffix = field.format?.inputSuffix;
		const commonProps = {
			name: fieldName,
			label: getFieldLabel(field),
			rules,
			hidden: field.hide,
			className: "w-100",
		} as const;

		switch (field.type) {
			case "text":
			case "textarea":
			case "number": {
				const Component =
					field.type === "text"
						? Input
						: field.type === "number"
							? InputNumber
							: Input.TextArea;

				const handleChange = (e: any) => {
					if (!formatFn) return;
					const value = field.type === "number" ? e : e?.target?.value;
					if (typeof value === "string") {
						const formatted = formatFn(value);
						// Just update the field value in the form. No full recompute here.
						form.setFieldsValue({ [fieldName]: formatted });
					}
				};

				return (
					<Form.Item {...commonProps} key={fieldName}>
						<Component
							className="w-100"
							disabled={field.disabled}
							placeholder={field.placeholder}
							suffix={inputSuffix}
							onChange={formatFn ? handleChange : undefined}
							style={{ width: "100%" }}
						/>
					</Form.Item>
				);
			}
			case "select": {
				const opts = fieldOptions[field.name!] || field.options || [];
				return (
					<Form.Item {...commonProps} key={fieldName}>
						<Select
							suffixIcon={inputSuffix}
							className="w-100"
							disabled={field.disabled}
							placeholder={field.placeholder}
						>
							{opts.map((option) => (
								<Select.Option key={option.value} value={option.value}>
									{option.label}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				);
			}
			case "date":
				return (
					<Form.Item {...commonProps} key={fieldName}>
						<DatePicker
							suffixIcon={inputSuffix}
							className="w-100"
							format="MM/DD/YYYY"
							disabled={field.disabled}
							placeholder={field.placeholder}
							style={{ width: "100%" }}
						/>
					</Form.Item>
				);
			case "radio": {
				const radioOpts = fieldOptions[field.name!] || field.options || [];
				return (
					<Form.Item {...commonProps} key={fieldName}>
						<Radio.Group className="w-100" disabled={field.disabled}>
							{radioOpts.map((option) => (
								<Radio key={option.value} value={option.value}>
									{option.label}
								</Radio>
							))}
						</Radio.Group>
					</Form.Item>
				);
			}
			case "checkbox":
				return (
					<Form.Item {...commonProps} key={fieldName} valuePropName="checked">
						<Input
							type="checkbox"
							disabled={field.disabled}
							suffix={inputSuffix}
						/>
					</Form.Item>
				);
			case "location":
				return (
					<Form.Item {...commonProps} key={fieldName}>
						<DynamicLocationPicker
							value={form.getFieldValue(fieldName)}
							onChange={(value) => form.setFieldsValue({ [fieldName]: value })}
							placeholder={field.placeholder}
							disabled={field.disabled}
						/>
					</Form.Item>
				);
			case "image":
			case "file":
				return (
					<Form.Item
						{...commonProps}
						key={fieldName}
						valuePropName="fileList"
						getValueFromEvent={normFile}
					>
						<Dragger
							className="w-100"
							name={field.name}
							listType="picture-card"
							multiple={field?.draggerProps?.multiple || false}
							maxCount={field?.draggerProps?.maxCount || 1}
							accept={field?.draggerProps?.accept || "*"}
							onPreview={(file) => handlePreview(file)}
							customRequest={async ({ file, onSuccess, onError }) => {
								const fileName =
									file?.name || `file-${new Date().toISOString()}`;
								try {
									const dataUrl = (await getBase64(file as Blob)) as string;
									const response = (await uploadFieldCaptureFromDataUrl(
										dataUrl,
									)) as string | { url: string };

									const fileUrl =
										typeof response === "string" ? response : response.url;
									(file as UploadFile).status = "done";
									(file as UploadFile).url = fileUrl;

									onSuccess?.({ url: fileUrl }, file);
									message.success(`${fileName} uploaded successfully`);
								} catch (err: any) {
									message.error(`${fileName} upload failed.`);
									onError?.(err);
								}
							}}
							disabled={
								(form.getFieldValue(fieldName)?.length || 0) >=
								(field?.draggerProps?.maxCount || 1)
							}
						>
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>
							<p className="ant-upload-text">Click or drag file to upload</p>
							{field?.draggerProps?.multiple && (
								<span className="ant-upload-hint">{`Multiple files supported. (${field?.draggerProps?.maxCount} max)`}</span>
							)}
						</Dragger>
					</Form.Item>
				);
			default:
				return null;
		}
	}

	const stepsConfig =
		formConfig?.config?.sections?.map((section: FormSection) => ({
			title: section.title,
			fieldNames: getFieldNames(section.fields || section.questions),
			content: (
				<Row gutter={[16, 16]}>
					{(section.fields || section.questions)?.map((field, idx) => {
						const fieldName =
							field.name || field.responseTag || field.key || `field-${idx}`;
						return (
							<Col
								xs={24}
								sm={24}
								md={24}
								lg={24}
								xl={24}
								key={`field-${fieldName}`}
							>
								{renderField(field, form)}
							</Col>
						);
					})}
				</Row>
			),
		})) || [];

	const handleStepChange = async (currentIndex: number) => {
		if (currentIndex < 0 || currentIndex >= stepsConfig.length) return;
		try {
			if (currentIndex > state.currentStep) {
				await form.validateFields(stepsConfig[state.currentStep].fieldNames);
			}

			const updatedData = { ...formData };
			// Recompute fields and update dynamic lists before step change
			computeAllFields(updatedData, formConfig);
			updateAllDynamicListCounts(updatedData);
			setFormData(updatedData);
			form.setFieldsValue(updatedData);

			dispatch({ type: "SET_CURRENT_STEP", payload: currentIndex });
		} catch {
			message.error("Please complete the required fields.");
		}
	};

	const validateCurrentStep = async () => {
		try {
			await form.validateFields(stepsConfig[state.currentStep].fieldNames);
			return true;
		} catch {
			return false;
		}
	};

	const updateAllDynamicListCounts = (allValues: Record<string, any>) => {
		if (!formConfig) return;
		const newDynamicListState: Record<string, number> = {};
		const allFields = getAllFields(formConfig.config.sections);
		allFields.forEach((field) => {
			if (field.type === "dynamicList" && field.name) {
				const count = getDynamicListCount(allValues, field);
				newDynamicListState[field.name] = count;
			}
		});
		if (Object.keys(newDynamicListState).length > 0) {
			dispatch({
				type: "SET_DYNAMIC_LIST_STATE",
				payload: newDynamicListState,
			});
		}
	};

	const onFormValuesChange = (
		changedValues: Record<string, any>,
		allValues: Record<string, any>,
	) => {
		// Just merge with formData here, no immediate computations or setFieldsValue
		const mergedData = { ...formData, ...allValues };
		setFormData(mergedData); // formData is source of truth
		attemptAutoSave(mergedData);

		// If any field has dependencies for data fetching, do it here
		const changedFields = Object.keys(changedValues);
		const allFields = getAllFields(formConfig?.config?.sections || []);
		allFields.forEach((field) => {
			if (field.dataSource?.dependencies) {
				const dependencies = field.dataSource.dependencies;
				if (dependencies.some((dep) => changedFields.includes(dep))) {
					fetchFieldData(field, changedValues);
				}
			}
		});
	};

	const onFinish = async () => {
		try {
			await form.validateFields();
		} catch {
			message.error("Please ensure all fields are filled out and valid.");
			return;
		}

		try {
			// formData is the source of truth and already computed
			const finalValues = { ...formData };
			const dataToSubmit = buildSubmissionData(formConfig, finalValues);
			const locationId =
				finalValues.location_id ||
				finalValues.location ||
				location.state?.locationId;

			if (!isEditing) {
				if (!draftId) {
					const newDraftId = await createDraft({
						location_id: locationId,
						form_id: Number.parseInt(formId),
						data: { ...dataToSubmit },
					});
					setDraftId(newDraftId);
				}
				await submitSubmission(draftId!, dataToSubmit || {});
				message.success("Form submitted successfully.");
			} else {
				await updateSubmission(draftId!, dataToSubmit || {}, locationId);
				message.success("Form updated successfully.");
			}

			const locationIdState =
				finalValues.location_id ||
				finalValues.location ||
				location.state?.locationId;
			const draftIdState = draftId;
			const formIdState = formId;
			const locationState = location.state;

			clearState();
			navigate(`/locations/${locationIdState}/submissions/${draftIdState}`, {
				state: {
					submissionId: draftIdState,
					formId: formIdState,
					...locationState,
				},
			});
		} catch {
			message.error("Form submission failed.");
		}
	};

	const next = async () => {
		try {
			await form.validateFields(stepsConfig[state.currentStep].fieldNames);
			if (state.currentStep < stepsConfig.length - 1) {
				const updatedData = { ...formData };
				computeAllFields(updatedData, formConfig);
				updateAllDynamicListCounts(updatedData);
				setFormData(updatedData);
				form.setFieldsValue(updatedData);

				dispatch({ type: "SET_CURRENT_STEP", payload: state.currentStep + 1 });
			}
		} catch {
			message.error("Please ensure all fields are filled out and valid.");
		}
	};

	const prev = () => {
		if (state.currentStep > 0) {
			const updatedData = { ...formData };
			computeAllFields(updatedData, formConfig);
			updateAllDynamicListCounts(updatedData);
			setFormData(updatedData);
			form.setFieldsValue(updatedData);

			dispatch({ type: "SET_CURRENT_STEP", payload: state.currentStep - 1 });
		}
	};

	const isMultipleSteps = stepsConfig.length > 10;

	const renderNavigationButtons = () => (
		<div style={{ marginTop: "24px", textAlign: "center" }}>
			{state.currentStep > 0 && (
				<Button htmlType="button" style={{ margin: "0 8px" }} onClick={prev}>
					Previous
				</Button>
			)}
			{state.currentStep < stepsConfig.length - 1 && (
				<Button type="primary" htmlType="button" onClick={next}>
					Next
				</Button>
			)}
			{state.currentStep === stepsConfig.length - 1 && (
				<Button type="primary" htmlType="submit">
					Submit
				</Button>
			)}
		</div>
	);

	return formConfig?.config?.sections ? (
		<Row className="px-2 w-100">
			<Form
				key={formKey}
				className="w-100"
				form={form}
				layout="vertical"
				onValuesChange={onFormValuesChange}
				onFinish={onFinish}
			>
				<div
					style={{ textAlign: "center", marginBottom: "12px" }}
					className="d-flex flex-column justify-content-center align-items-center gap-4"
				>
					{formLogo && (
						<img
							src={formLogo}
							alt="Form Logo"
							className="d-flex flex-column justify-content-center align-items-center"
							style={{ maxWidth: "200px" }}
						/>
					)}
					<Title level={2}>{formName}</Title>
				</div>

				{!isMultipleSteps ? (
					<div className="steps-container d-flex flex-column align-items-center my-4 px-3">
						<Steps
							current={state.currentStep}
							size="default"
							className="steps-wrapper w-100"
							responsive
							direction="horizontal"
						>
							{stepsConfig.map((item, index) => (
								<Step
									key={index}
									className="step-item"
									onClick={async () => {
										if (
											index === state.currentStep ||
											(index > state.currentStep &&
												!(await validateCurrentStep()))
										)
											return;
										try {
											await handleStepChange(index);
										} catch {
											message.error(
												"Please ensure all fields are valid before proceeding.",
											);
										}
									}}
									icon={
										<Tooltip title={item.title}>
											<span
												className={`step-icon ${index === state.currentStep ? "active" : ""}`}
											>
												{index + 1}
											</span>
										</Tooltip>
									}
								/>
							))}
						</Steps>
						<div className="step-titles d-flex justify-content-between w-100 mt-2">
							{stepsConfig.map((_, index) => (
								<div
									key={index}
									className={`step-title text-center ${index === state.currentStep ? "active" : ""}`}
									style={{
										width: `${100 / stepsConfig.length}%`,
										whiteSpace: "nowrap",
										overflow: "hidden",
										textOverflow: "ellipsis",
									}}
								>
									<span>&nbsp;</span>
								</div>
							))}
						</div>
					</div>
				) : (
					<div className="progress-container d-flex flex-column align-items-center my-4 px-3">
						<Progress
							percent={Number.parseInt(
								(((state.currentStep + 1) / stepsConfig.length) * 100).toFixed(
									0,
								),
							)}
							status="active"
							showInfo
							className="progress-bar w-100"
							format={(percent) => `Step ${percent}% Completed`}
						/>
						<div className="step-select mt-3 d-flex align-items-center">
							<span>Navigate to: </span>
							<Select
								value={state.currentStep + 1}
								onChange={(value) => handleStepChange(value - 1)}
								style={{ marginLeft: "8px", minWidth: "120px" }}
								size="small"
								popupMatchSelectWidth={false}
								dropdownStyle={{ maxWidth: "80vw" }}
							>
								{stepsConfig.map((step, index) => (
									<Select.Option key={index} value={index + 1}>
										{`Step ${index + 1}: ${step.title}`}
									</Select.Option>
								))}
							</Select>
						</div>
					</div>
				)}

				<div className="step-title text-center mt-4">
					<Title level={5}>{stepsConfig[state.currentStep]?.title}</Title>
				</div>

				{stepsConfig[state.currentStep]?.content ? (
					<div style={{ marginTop: "24px" }}>
						{stepsConfig[state.currentStep].content}
					</div>
				) : (
					<Row className="px-2 w-100 d-flex flex-col justify-content-center align-content-center">
						<Loader />
					</Row>
				)}

				<Modal
					open={previewVisible}
					title={previewTitle}
					footer={null}
					onCancel={() => setPreviewVisible(false)}
				>
					<Image
						src={previewImage}
						alt="Preview Image"
						wrapperClassName="w-100 cursor-pointer d-flex justify-content-center align-items-center"
						preview={{
							onVisibleChange: (visible) => {
								if (visible) return setPreviewVisible(false);
								return setPreviewVisible(true);
							},
							destroyOnClose: true,
							closeIcon: (
								<span
									className="p-2 bg-white"
									style={{ borderRadius: token.borderRadiusDefault }}
								>
									<LuXCircle size={"1.5rem"} color={token.colorPrimary} />
								</span>
							),
							maskStyle: { backgroundColor: token.colorBgMask },
							toolbarRender(originalNode) {
								return (
									<div
										style={{
											color: token.colorPrimary,
											backgroundColor: token.colorWhite,
											borderRadius: token.borderRadiusDefault,
										}}
									>
										{originalNode}
									</div>
								);
							},
						}}
					/>
				</Modal>

				{renderNavigationButtons()}
			</Form>
		</Row>
	) : (
		<Row className="px-2 w-100 d-flex flex-col justify-content-center align-content-center">
			<Spin indicator={<Loader />} tip="Loading form..." />
		</Row>
	);
};

/** Helper Functions **/

function prepareComputedFields(formConfig: FormConfig) {
	const allFields = getAllFields(formConfig.config.sections);
	allFields.forEach((field) => {
		if (field.computed && typeof field.computed.calculate === "string") {
			field.computed.calculate = new Function(
				"values",
				`return ${field.computed.calculate};`,
			);
		}
	});
}

function getAllFields(sections: FormSection[]): FormField[] {
	let all: FormField[] = [];
	sections.forEach((section) => {
		const fields = section.fields || section.questions || [];
		all = all.concat(getAllFieldsFromArray(fields));
	});
	return all;
}

function getAllFieldsFromArray(fields: FormField[]): FormField[] {
	let all: FormField[] = [];
	fields.forEach((field) => {
		all.push(field);
		if (field.fields && field.fields.length > 0) {
			all = all.concat(getAllFieldsFromArray(field.fields));
		}
	});
	return all;
}

function buildValidationRules(field: FormField) {
	const rules: any[] = [];
	if (!field.validation) return rules;

	const validations = Array.isArray(field.validation)
		? field.validation
		: [field.validation];

	for (const validationObj of validations) {
		const { pattern, minLength, maxLength, min, max, message, required } =
			validationObj;

		if (required) {
			rules.push({
				required: true,
				message: message || `${field.label || "Field"} is required`,
			});
		}

		if (pattern) {
			rules.push({
				pattern: new RegExp(pattern),
				message: message || "Invalid format",
			});
		}

		if (typeof minLength === "number") {
			rules.push({
				min: minLength,
				message:
					message ||
					`${field.label || "Field"} must be at least ${minLength} characters`,
			});
		}

		if (typeof maxLength === "number") {
			rules.push({
				max: maxLength,
				message:
					message ||
					`${field.label || "Field"} must be at most ${maxLength} characters`,
			});
		}

		if (typeof min === "number") {
			rules.push({
				type: "number",
				min,
				message: message || `${field.label || "Field"} must be ≥ ${min}`,
			});
		}

		if (typeof max === "number") {
			rules.push({
				type: "number",
				max,
				message: message || `${field.label || "Field"} must be ≤ ${max}`,
			});
		}
	}

	return rules;
}

function computeAllFields(
	allValues: Record<string, any>,
	formConfig: FormConfig | null,
) {
	if (!formConfig) return;
	const allFields = getAllFields(formConfig.config.sections);
	for (const field of allFields) {
		if (field.computed && typeof field.computed.calculate === "function") {
			const { calculate } = field.computed;
			const newValue = calculate(allValues);
			if (field.name && allValues[field.name] !== newValue) {
				allValues[field.name] = newValue;
			}
		}
	}
}

function buildInitialValuesFromParsedInputs(parsedInputs: any) {
	const initialValues: Record<string, any> = {};
	const dynamicCounts: Record<string, number> = {};

	function processFields(fields: FormField[], prefix = "") {
		for (const field of fields) {
			const fieldName = field.responseTag || field.name || field.key;
			if (!fieldName) continue;
			const fullName = prefix ? `${prefix}.${fieldName}` : fieldName;

			if (field.type === "image" && Array.isArray(field.value)) {
				initialValues[fullName] = field.value.map(
					(url: string, index: number) => ({
						uid: `-${index}`,
						name: `image-${index}.jpg`,
						status: "done",
						url,
					}),
				);
			} else if (field.type === "date" && field.value) {
				initialValues[fullName] = dayjs(new Date(field.value));
			} else if (field.type === "dynamicList" && Array.isArray(field.value)) {
				if (field.countField) {
					const countFullPath = prefix
						? `${prefix}.${field.countField}`
						: field.countField;
					initialValues[countFullPath] = field.value.length;
					dynamicCounts[fullName] = field.value.length;
				}
				field.value.forEach((item: Record<string, any>, i: number) => {
					if (!field.fields) return;
					for (const subField of field.fields) {
						const subFieldName =
							subField.name || subField.responseTag || subField.key;
						if (!subFieldName) return;
						const sfFullName = `${fullName}.${i}.${subFieldName}`;
						const val = item[subFieldName];

						if (subField.type === "image" && Array.isArray(val)) {
							initialValues[sfFullName] = val.map((u: string, idx: number) => ({
								uid: `-${idx}`,
								name: `image-${idx}.jpg`,
								status: "done",
								url: u,
							}));
						} else if (subField.type === "date" && val) {
							initialValues[sfFullName] = dayjs(new Date(val));
						} else {
							initialValues[sfFullName] = val ?? "";
						}
					}
				});
			} else if (field.fields) {
				processFields(field.fields, fullName);
			} else {
				initialValues[fullName] = field.value;
			}
		}
	}

	for (const section of parsedInputs.sections) {
		const fields = section.fields || section.questions;
		if (fields) processFields(fields);
	}

	return { initialValues, dynamicCounts };
}

function getFieldNames(fields?: FormField[]): string[] {
	if (!fields) return [];
	let names: string[] = [];
	for (const f of fields) {
		if (f.type === "group" || f.type === "dynamicList") {
			if (f.fields) names = names.concat(getFieldNames(f.fields));
		} else {
			const fieldName = f.name || f.responseTag || f.key;
			if (fieldName) names.push(fieldName);
		}
	}
	return names;
}

function buildSubmissionData(
	formConfig: FormConfig | null,
	formData: Record<string, any>,
): { title: string; sections: any[] } | null {
	if (!formConfig) return null;

	function processValue(field: FormField, val: any): any {
		if (val === undefined) return val;
		if (
			(field.type === "image" || field.type === "file") &&
			Array.isArray(val)
		) {
			val = val.map((file: any) => file.url || file.response?.url);
		}
		if (field.type === "date" && val && dayjs.isDayjs(val)) {
			val = val.toISOString();
		}
		if (field.type === "location" && val && typeof val === "object") {
			val = val.id || val;
		}
		return val;
	}

	function reconstructDynamicList(
		field: FormField,
		formData: Record<string, any>,
		prefix: string,
	): any[] {
		if (!field.fields || !field.countField) return [];
		const countPath = field.name || field.responseTag || field.key || "";
		const fullCountPath = prefix ? `${prefix}.${countPath}` : countPath;
		const count = getDynamicListCount(formData, {
			...field,
			name: fullCountPath,
		});
		const items: any[] = [];
		for (let i = 0; i < count; i++) {
			const itemObj: Record<string, any> = {};
			for (const subField of field.fields) {
				const subFieldPath =
					subField.name || subField.responseTag || subField.key;
				if (!subFieldPath) continue;
				const fullPath = prefix
					? `${prefix}.${countPath}.${i}.${subFieldPath}`
					: `${countPath}.${i}.${subFieldPath}`;
				let val = formData[fullPath];
				if (subField.type === "dynamicList") {
					val = reconstructDynamicList(
						subField,
						formData,
						`${prefix ? prefix + "." : ""}${countPath}.${i}`,
					);
				} else if (subField.fields && subField.fields.length > 0) {
					val = processSectionFields(
						subField.fields,
						formData,
						`${prefix ? prefix + "." : ""}${countPath}.${i}`,
					);
				} else {
					val = processValue(subField, val);
				}
				itemObj[subFieldPath] = val;
			}
			items.push(itemObj);
		}
		return items;
	}

	function processSectionFields(
		fields: FormField[],
		formData: Record<string, any>,
		prefix = "",
	) {
		const result = [];
		for (const field of fields) {
			const fieldName = field.name || field.responseTag || field.key;
			if (!fieldName) continue;
			const fullName = prefix ? `${prefix}.${fieldName}` : fieldName;

			let val = formData[fullName];
			if (field.type === "dynamicList") {
				val = reconstructDynamicList(field, formData, prefix);
			} else if (field.fields && field.fields.length > 0) {
				val = processSectionFields(field.fields, formData, fullName);
			} else {
				val = processValue(field, val);
			}
			const questionObj = { ...field, value: val };
			result.push(questionObj);
		}
		return result;
	}

	const submissionData: any[] = [];
	formConfig?.config?.sections?.forEach((section: FormSection) => {
		const fields = section.fields || section.questions;
		const sectionData = {
			title: section.title,
			questions: fields ? processSectionFields(fields, formData) : [],
		};
		submissionData.push(sectionData);
	});

	return {
		title: formConfig?.config?.sections?.[0]?.title || "",
		sections: submissionData,
	};
}

function preprocessSections(formConfig: FormConfig) {
	formConfig.config.sections.forEach((section) => {
		if (section.fields) {
			section.fields = preprocessFields(section.fields);
		} else if (section.questions) {
			section.questions = preprocessFields(section.questions);
		}
	});
}

function preprocessFields(fields: FormField[]): FormField[] {
	return fields.map((field) => {
		const clone = { ...field };
		if (clone.fields && clone.fields.length > 0) {
			clone.fields = preprocessFields(clone.fields);
		}
		return clone;
	});
}

export { DynamicSubmissionForm };
