import { DFPEmpty } from "@/components/Empty/dfp-empty";
import { Loader } from "@/components/layouts/SignedIn/Loader/Loader";
import {
	calculateBestFitToBudgetAssets,
	calculateChartWidth,
	exportToCSV,
	getChartOptions,
	handleReset,
	handleStrategyChange,
	toggleBubbleChart,
	toggleYAxis,
} from "@/services/predictive-spend-service";
import { screenshot } from "@/utils/screenshot";
import { Tooltip as AntTooltip, Button, Select, message } from "antd";
import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	Title,
	Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import dragData from "chartjs-plugin-dragdata";
import { useEffect, useReducer, useRef } from "react";
import { Bar } from "react-chartjs-2";
import { AiOutlineDotChart } from "react-icons/ai";
import { BsBoxes, BsCurrencyDollar, BsFillCameraFill } from "react-icons/bs";
import { IoMdCheckmark } from "react-icons/io";
import { MdDownload, MdLegendToggle } from "react-icons/md";
import { Card, CardBody, CardFooter, CardHeader, Label } from "reactstrap";
import { BudgetDifference } from "./BudgetDifference";
import { BudgetNotes } from "./BudgetNotes";
import { CustomLegend } from "./CustomLegend";
import { LineDatasetLegendItems } from "./LineDatasetLegendItems";
import { PredSpendBubbleChart } from "./PredSpendBubbleChart";
import { PredSpendFilter } from "./PredSpendFilter";
import { YearPieChart } from "./YearPieChart";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
);
ChartJS.register(dragData);

// Initial state for the reducer
const initialChartState = {
	data: {
		labels: [],
		datasets: [],
	},
	strategy: {
		label: "End of Life Replacement",
		value: "End of Life Replacement",
		description:
			"This strategy showcases your assets replacement costs at the end of their useful life.",
	},
	isCumulative: false,
	selectedYear: null,
	isScreenshotted: false,
	budgetShortage: null,
	strategyAssets: null,
	leftover: null,
	reactivePremiumData: null,
	assetTypeColors: {},
	showExportButton: true,
	legendSelectedAssetTypes: [],
	isYAxisReplCost: true,
	maxDataValue: 0,
	showLegend: true,
	width: null,
	bubbleChart: false,
	startDate: null,
	endDate: null,
};

// Reducer function
const chartReducer = (state, action) => {
	switch (action.type) {
		case "SET_DATA":
			return { ...state, data: action.payload };
		case "SET_STRATEGY":
			return { ...state, strategy: action.payload };
		case "SET_IS_CUMULATIVE":
			return { ...state, isCumulative: action.payload };
		case "SET_SELECTED_YEAR":
			return { ...state, selectedYear: action.payload };
		case "SET_IS_SCREENSHOTTED":
			return { ...state, isScreenshotted: action.payload };
		case "SET_BUDGET_SHORTAGE":
			return { ...state, budgetShortage: action.payload };
		case "SET_STRATEGY_ASSETS":
			return { ...state, strategyAssets: action.payload };
		case "SET_LEFTOVER":
			return { ...state, leftover: action.payload };
		case "SET_REACTIVE_PREMIUM_DATA":
			return { ...state, reactivePremiumData: action.payload };
		case "SET_ASSET_TYPE_COLORS":
			return { ...state, assetTypeColors: action.payload };
		case "SET_SHOW_EXPORT_BUTTON":
			return { ...state, showExportButton: action.payload };
		case "SET_LEGEND_SELECTED_ASSET_TYPES":
			return { ...state, legendSelectedAssetTypes: action.payload };
		case "SET_IS_Y_AXIS_REPL_COST":
			return { ...state, isYAxisReplCost: action.payload };
		case "SET_MAX_DATA_VALUE":
			return { ...state, maxDataValue: action.payload };
		case "SET_SHOW_LEGEND":
			return { ...state, showLegend: action.payload };
		case "SET_WIDTH":
			return { ...state, width: action.payload };
		case "SET_BUBBLE_CHART":
			return { ...state, bubbleChart: action.payload };
		case "SET_START_DATE":
			return { ...state, startDate: action.payload };
		case "SET_END_DATE":
			return { ...state, endDate: action.payload };
		default:
			return state;
	}
};

const PredSpendChart = ({ state, dispatch: parentDispatch }) => {
	const {
		chartLoading,
		chartAssets: assets,
		originalAssets,
		budget,
		existingAssetTypes,
		numAssetTypes,
		startDate,
		endDate,
	} = state;

	const [chartState, dispatch] = useReducer(chartReducer, initialChartState);
	const screenshotRef = useRef(null);
	const cameraIconRef = useRef(null);
	const chartRef = useRef(null);
	const strategyRef = useRef(null);
	const budgetRef = useRef(null);
	const strategyAssetsRef = useRef(null);
	const strategyOptions = [
		{
			label: "End of Life Replacement",
			value: "End of Life Replacement",
			description:
				"This strategy showcases your assets replacement costs at the end of their useful life.",
		},
		{
			label: "Do Nothing",
			value: "Cumulative Do Nothing",
			description:
				"This strategy showcases your year-over-year replacement costs if you do nothing with your assets.",
			isDisabled: chartState.bubbleChart && true,
		},
		{
			label: "Best Fit to Budget",
			value: "Best Fit to Budget",
			isDisabled:
				(budget.every((val) => !val) || chartState.bubbleChart) && true,
			disabledTooltip: "Enter a budget to enable this strategy.",
			description:
				"This strategy showcases your year-over-year replacement costs best fitted to your budget.",
		},
		{
			label: "Exact Fit to Budget",
			value: "Exact Fit to Budget",
			isDisabled: budget.every((val) => !val) || chartState.bubbleChart,
			disabledTooltip: "Enter a budget to enable this strategy.",
			description:
				"This strategy showcases your year-over-year replacement costs fitted exactly to your budget.",
		},
		{
			label: "Work Order Spend",
			value: "Work Order Spend",
			isDisabled: true,
			description:
				"This strategy showcases your year-over-year replacement costs fitted exactly to your budget.",
		},
	];
	const plugins = [ChartDataLabels];
	const options = getChartOptions(
		state,
		chartState,
		strategyAssetsRef,
		originalAssets,
		strategyRef,
		budgetRef,
		parentDispatch,
		dispatch,
	);

	// initialize legend selected asset types
	useEffect(() => {
		if (existingAssetTypes.length > 0) {
			dispatch({
				type: "SET_LEGEND_SELECTED_ASSET_TYPES",
				payload: existingAssetTypes,
			});
		}
	}, [existingAssetTypes]);

	// Update the ref whenever the state updates
	useEffect(() => {
		strategyRef.current = chartState.strategy;
	}, [chartState.strategy]);

	useEffect(() => {
		budgetRef.current = budget;
	}, [budget]);

	useEffect(() => {
		if (assets.length > 0 && chartState.strategy) {
			handleStrategyChange(state, chartState, dispatch, strategyAssetsRef);
		}
	}, [chartState.strategy, assets, budget, chartState.isYAxisReplCost]);

	// use effect to change strategy back to default when budget is invalid
	useEffect(() => {
		if (
			budget.includes(null) ||
			(budget.includes(0) && chartState.strategy.value === "Best Fit to Budget")
		) {
			dispatch({
				type: "SET_STRATEGY",
				payload: strategyOptions[0],
			});
		}
	}, [budget]);

	// use effect to redistribute assets when budget changes
	useEffect(() => {
		if (chartState.strategy.value === "Best Fit to Budget") {
			dispatch({
				type: "SET_STRATEGY_ASSETS",
				payload: calculateBestFitToBudgetAssets(assets, budget),
			});
		}
	}, [budget]);

	useEffect(() => {
		// Only reset startDate and endDate when they differ
		if (chartState.startDate !== startDate || chartState.endDate !== endDate) {
			if (chartState.startDate && chartState.endDate) {
				dispatch({ type: "SET_START_DATE", payload: startDate });
				dispatch({ type: "SET_END_DATE", payload: endDate });
			}
		}
	}, [chartState.startDate, chartState.endDate, startDate, endDate]);

	return (
		<div ref={screenshotRef}>
			<Card
				className="predictive-spend-card px-2 py-2"
				style={{ overflow: "visible" }}
			>
				<CardHeader className="d-flex justify-content-between align-items-center position-relative p-1 pred-spend-navbar">
					<div className="d-flex align-items-center pred-spend-navbar-left">
						{/* Left section */}
						{chartState.selectedYear ? (
							<button
								onClick={() => {
									dispatch({ type: "SET_SELECTED_YEAR", payload: null });
									dispatch({ type: "SET_SHOW_EXPORT_BUTTON", payload: true });
								}}
								className="pred-spend-back-btn"
							>
								<i className="bi bi-arrow-left me-2 fw-bold"></i>
								<strong>{chartState.selectedYear}</strong>
							</button>
						) : (
							<>
								<PredSpendFilter
									state={state}
									parentDispatch={parentDispatch}
									chartState={chartState}
									dispatch={dispatch}
								/>
								<AntTooltip title="Visualize data in a bubble chart.">
									<div className="pred-spend-navbar-item">
										<AiOutlineDotChart
											style={{
												color: chartState.bubbleChart ? "black" : "gray",
											}}
											className="pred-spend-navbar-icon"
											onClick={() => toggleBubbleChart(chartState, dispatch)}
										/>
									</div>
								</AntTooltip>
								<AntTooltip title="Show asset count or replacement cost on the y-axis.">
									<div
										className="d-flex justify-content-center align-items-center p-0 pred-spend-navbar-item"
										onClick={() => toggleYAxis(chartState, dispatch)}
									>
										{!chartState.isYAxisReplCost ? (
											<BsCurrencyDollar className="pred-spend-navbar-icon" />
										) : (
											<BsBoxes className="pred-spend-navbar-icon" />
										)}
									</div>
								</AntTooltip>
								<LineDatasetLegendItems
									budget={budget.every((item) => !!item)}
									strategy={chartState.strategy}
								/>
							</>
						)}
					</div>
					{/* Middle section */}
					<div className="d-flex justify-content-center align-items-center pred-spend-navbar-middle h-100">
						{!chartState.bubbleChart && !chartState.selectedYear && (
							<>
								<Label className="m-0 fw-bold" style={{ fontSize: "17px" }}>
									Strategy
								</Label>
								<Select
									className="basic-single mx-2 w-100"
									options={strategyOptions.map((option) => ({
										label: (
											<AntTooltip
												title={option.disabledTooltip}
												placement="top"
											>
												<span
													style={{
														color: option.isDisabled ? "#999" : "inherit",
													}}
													className="strategy-select-option"
												>
													{option.label}
												</span>
											</AntTooltip>
										),
										value: option.value,
										disabled: option.isDisabled,
									}))}
									onChange={(value) => {
										const selectedOption = strategyOptions.find(
											(option) => option.value === value,
										);
										dispatch({
											type: "SET_STRATEGY",
											payload: selectedOption,
										});
									}}
									value={chartState.strategy?.value}
								/>
								{!chartState.selectedYear && (
									<Button
										type="primary"
										danger
										onClick={() =>
											handleReset(
												state,
												chartState,
												dispatch,
												parentDispatch,
												chartRef,
											)
										}
									>
										Reset
									</Button>
								)}
							</>
						)}
					</div>
					{/* Right section */}
					<div className="pred-spend-navbar-right d-flex align-items-center justify-content-end">
						<div ref={cameraIconRef}>
							{chartState.showExportButton && (
								<div className="pred-spend-navbar-item">
									<MdDownload
										onClick={() => exportToCSV(state, chartState, chartRef)}
										className="pred-spend-navbar-icon"
									></MdDownload>
								</div>
							)}
						</div>
						<button
							className="d-flex justify-content-center align-items-center pred-spend-navbar-item"
							disabled={chartState.isScreenshotted || chartLoading}
							onClick={() => {
								screenshot(screenshotRef, cameraIconRef);
								dispatch({ type: "SET_IS_SCREENSHOTTED", payload: true });
								message.success("Chart screenshot saved to clipboard.");
								setTimeout(() => {
									dispatch({ type: "SET_IS_SCREENSHOTTED", payload: false });
								}, 3000);
							}}
						>
							{chartState.isScreenshotted ? (
								<IoMdCheckmark className="pred-spend-navbar-icon" />
							) : (
								<BsFillCameraFill className="pred-spend-navbar-icon" />
							)}
						</button>

						{!chartState.selectedYear && !chartState.bubbleChart && (
							<button className="pred-spend-navbar-item">
								<MdLegendToggle
									onClick={() =>
										dispatch({
											type: "SET_SHOW_LEGEND",
											payload: !chartState.showLegend,
										})
									}
									className="pred-spend-navbar-icon"
								/>
							</button>
						)}
					</div>
				</CardHeader>

				<CardBody
					style={{
						paddingTop: chartState.bubbleChart ? "0px" : "12px",
						paddingBottom: "12px",
						height: "100%",
					}}
				>
					{chartState.selectedYear && assets.length > 0 ? (
						<YearPieChart
							state={state}
							chartState={chartState}
							strategyAssetsRef={strategyAssetsRef}
						/>
					) : (
						<>
							{chartLoading ? (
								<Loader />
							) : assets.length === 0 ? (
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										height: "100%",
									}}
								>
									<DFPEmpty
										style={{ height: "auto" }}
										description={
											<>
												No Assets returned.
												<br />
												Please update your filters and try again.
											</>
										}
									/>
								</div>
							) : !chartState.bubbleChart ? (
								<div
									style={{
										height: budget.every((val) => !!val)
											? "calc(100% - 24px)"
											: "100%",
									}}
									className="d-flex"
								>
									<div
										className="h-100"
										style={{
											width: calculateChartWidth(
												chartState.showLegend,
												numAssetTypes,
											),
										}}
									>
										<Bar
											options={options}
											data={chartState.data}
											plugins={plugins}
											ref={chartRef}
										/>
										<BudgetDifference state={state} chartState={chartState} />
									</div>
									<CustomLegend
										state={state}
										parentDispatch={parentDispatch}
										chartState={chartState}
										dispatch={dispatch}
										chartRef={chartRef}
										strategyRef={strategyRef}
										strategyAssetsRef={strategyAssetsRef}
									/>
								</div>
							) : (
								<PredSpendBubbleChart
									assets={assets}
									originalAssets={originalAssets}
									assetTypeColors={chartState.assetTypeColors}
									selectedYear={chartState.selectedYear}
								/>
							)}
						</>
					)}
				</CardBody>
				{!chartState.selectedYear && !chartState.bubbleChart && (
					<CardFooter style={{ border: "none", paddingTop: 0 }}>
						<BudgetNotes
							state={state}
							chartState={chartState}
							dispatch={dispatch}
						/>
					</CardFooter>
				)}
			</Card>
		</div>
	);
};

export { PredSpendChart };
