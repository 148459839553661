import { UserAddOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { Dispatch, FC } from "react";
import { State } from "../UsersTable";

interface AddUserButtonProps {
	state: State;
	dispatch: Dispatch;
	disabled: boolean;
}

const AddUserButton: FC<AddUserButtonProps> = ({
	state,
	dispatch,
	disabled,
}) => {
	const tooltipMessage = "You don't have permission to add a user.";
	const button = (
		<Button
			type="text"
			icon={<UserAddOutlined style={{ fontSize: "20px" }} />}
			onClick={() =>
				dispatch({ type: "SET_SHOW_ADD_USER_MODAL", payload: true })
			}
			disabled={disabled}
		/>
	);

	return disabled ? <Tooltip title={tooltipMessage}>{button}</Tooltip> : button;
};

export { AddUserButton };
