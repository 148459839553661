// SubmissionDetailControls.tsx
import { useFormStore, useUserStore } from "@/stores";
import { UserAccessLevel } from "@/stores/userStore";
import { FilePdfOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Modal, Tooltip, message } from "antd";
import html2pdf from "html2pdf.js";
import { useCallback, useMemo, useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { GoThumbsup } from "react-icons/go";
import { useLocation, useNavigate } from "react-router-dom";
import { DownloadCsv } from "./DownloadCsv";
import type { SubmissionDetailsState } from "./Submission";
import { approveSubmission } from "./functions/approveSubmission";

interface SubmissionDetailControlsProps {
	state: SubmissionDetailsState;
	dispatch: React.Dispatch<any>;
	toggleSubmission: () => void;
}

export const SubmissionDetailControls: React.FC<
	SubmissionDetailControlsProps
> = ({ state, dispatch, toggleSubmission }) => {
	const navigate = useNavigate();
	const { setShowDraftSelectionModal } = useFormStore();
	const { userOrganization, user } = useUserStore();
	const { role } = user;
	const { access_level } = role;
	const organization = userOrganization.external_firebase_id;
	const queryClient = useQueryClient();
	const location = useLocation();

	const [isApprovalModalOpen, setIsApprovalModalVisible] = useState(false);

	const getNoEditMessage = useCallback(() => {
		if (state.responseData?.is_deleted) {
			return "This submission has been deleted.";
		}
		if (!state.responseData?.can_edit) {
			if (access_level >= UserAccessLevel.ADMIN) {
				return "You may only make changes to submissions within the first 9 weeks after submission.";
			}
			return "You may only make changes to submissions if you are the creator of the record and are within the first 72 hours after submission.";
		}
		return "";
	}, [
		state.responseData?.is_deleted,
		state.responseData?.can_edit,
		access_level,
	]);

	const noEditMessage = useMemo(() => getNoEditMessage(), [getNoEditMessage]);

	// Download PDF handler
	// Download PDF handler
	const downloadPdf = useCallback(async () => {
		dispatch({ type: "SET_STATE", payload: { pdfLoading: true } });
		try {
			const element = document.getElementById("submission-content");
			if (element) {
				// Wait for all images within the element to load
				const imageElements = element.getElementsByTagName("img");
				const imagePromises = Array.from(imageElements).map((img) => {
					return new Promise<void>((resolve, reject) => {
						if (img.complete) {
							resolve();
						} else {
							img.onload = () => resolve();
							img.onerror = () => reject();
						}
					});
				});

				Promise.all(imagePromises)
					.then(() => {
						const opt = {
							margin: [0.75, 0.25, 0.75, 0.25], // Top, right, bottom, left
							filename: `${state.formName} - ${state.address}.pdf`,
							image: { type: "jpeg", quality: 1 },
							html2canvas: {
								scale: 1,
								useCORS: true, // Enable cross-origin images
								ignoreElements: (element: HTMLElement) => {
									const className = element.getAttribute("class");
									if (className?.includes("submission-edit-log")) {
										return true;
									}
									if (className?.includes("ant-typography-copy")) {
										return true;
									}
									return false;
								},
							},
							jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
							pagebreak: { mode: ["avoid-all", "css"] },
						};

						html2pdf()
							.from(element)
							.set(opt)
							.save()
							.then(() => {
								dispatch({ type: "SET_STATE", payload: { pdfLoading: false } });
								message.success("PDF downloaded successfully");
							});
					})
					.catch((error) => {
						console.error("Error loading images:", error);
						message.error("Failed to load images for PDF.");
						dispatch({ type: "SET_STATE", payload: { pdfLoading: false } });
					});
			}
		} catch (error) {
			console.error("Error generating PDF:", error);
			message.error("Failed to generate PDF.");
			dispatch({ type: "SET_STATE", payload: { pdfLoading: false } });
		}
	}, [dispatch, state.formName, state.address]);

	// Edit submission handler
	const editSubmission = useCallback(async () => {
		setShowDraftSelectionModal(false);
		navigate(`/forms/${state.formId}`, {
			state: {
				...location.state,
				isEditing: true,
				responseData: state.responseData.raw_input,
				generativeAiInputs:
					state.responseData?.submission_asset_ai_data_map ||
					state.responseData?.asset?.asset_ai_data_map ||
					{},
				submissionId: state.submissionId,
				savedLocation: state.responseData.location,
				chosenAssetType: state.responseData.asset.organization_asset_type,
				userLocation: state.userLocation,
				airportCode: organization === "SSPAMERICA" ? state.airportCode : null,
				path: state.path,
				isQA: state.isQA,
				assetRefPath: state.responseData.assetRef
					? state.responseData.assetRef.path
					: null,
				submissionsPageNum: state.submissionsPageNum,
			},
		});
	}, [
		setShowDraftSelectionModal,
		navigate,
		state.formId,
		state.responseData,
		state.submissionId,
		state.userLocation,
		organization,
		state.airportCode,
		state.path,
		state.isQA,
		state.submissionsPageNum,
	]);

	const handleApprove = async () => {
		setIsApprovalModalVisible(false);
		await approveSubmission(
			state.submissionId,
			(loading) =>
				dispatch({ type: "SET_STATE", payload: { isLoading: loading } }),
			queryClient,
			toggleSubmission,
		);
	};

	return (
		<div className="d-flex align-content-start">
			<div className="d-flex flex-row gap-2">
				{state?.responseData?.is_qa_flagged ? (
					<>
						<Tooltip title={noEditMessage}>
							<Button
								type="primary"
								disabled={
									state?.responseData?.is_deleted ||
									!state.responseData?.can_edit
								}
								onClick={() => setIsApprovalModalVisible(true)}
								icon={<GoThumbsup />}
							>
								Approve
							</Button>
						</Tooltip>
						<Modal
							title="Approve This Submission?"
							open={isApprovalModalOpen}
							onOk={handleApprove}
							onCancel={() => setIsApprovalModalVisible(false)}
						>
							<p>
								Are you sure you want to approve this submission? Clicking
								"Confirm" will remove it from QA and add it to the submissions
								collection.
							</p>
						</Modal>
					</>
				) : null}
				<DownloadCsv form={state.responseData} />
				<Button
					onClick={downloadPdf}
					loading={state.pdfLoading}
					icon={<FilePdfOutlined />}
				>
					PDF
				</Button>
				<Tooltip title={noEditMessage}>
					<Button
						type="primary"
						disabled={
							state.responseData?.is_deleted || !state.responseData?.can_edit
						}
						onClick={editSubmission}
						icon={<AiOutlineEdit />}
						id="editBtn"
					>
						Edit
					</Button>
				</Tooltip>
			</div>
		</div>
	);
};
