import { ADDRESS_VALIDATION_API_URL } from "@constants/api";
import {
	addLocation,
	getLocationGeoLocation,
} from "@services/location-service";
import { Form, Input, message } from "antd";
import { MutableRefObject, useEffect, useRef, useState } from "react";

interface AddSingleLocationProps {
	formRef: MutableRefObject<any>;
	modalOpen: boolean;
	toggleModal: () => void;
}

const AddSingleLocation = ({
	formRef,
	modalOpen,
	toggleModal,
}: AddSingleLocationProps) => {
	const autocompleteInput = useRef(null);
	const [autocomplete, setAutocomplete] = useState(null);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [inputs] = useState([
		{ label: "Location Name", responseTag: "name", required: false },
		{ label: "Address 1", responseTag: "address1", required: true },
		{
			label: "Address 2",
			responseTag: "address2",
			id: "address2",
			required: false,
		},
		{ label: "State", responseTag: "state", id: "state", required: true },
		{ label: "City", responseTag: "city", id: "city", required: true },
		{ label: "Zip Code", responseTag: "zip", id: "zip", required: true },
	]);
	const [error, setError] = useState("");
	const [form] = Form.useForm();

	useEffect(() => {
		if (formRef) {
			formRef.current = form;
		}
	}, [formRef, form]);

	useEffect(() => {
		if (modalOpen && autocompleteInput.current && !autocomplete) {
			const newAutocomplete = new window.google.maps.places.Autocomplete(
				autocompleteInput.current.input,
				{
					types: ["address"],
					componentRestrictions: { country: "us" },
					fields: ["address_components", "geometry"],
				},
			);

			newAutocomplete.addListener("place_changed", () => {
				const place = newAutocomplete.getPlace();

				if (!place.geometry) {
					message.error(`No details available for input: '${place.name}'`);
					return;
				}
				const addressObj = {};
				let address1 = "";
				let zip = "";
				for (const component of place.address_components) {
					const componentType = component.types[0];
					switch (componentType) {
						case "street_number":
							address1 = `${component.long_name} ${address1}`;
							break;
						case "subpremise":
							addressObj.address2 = `${component.long_name}`;
							break;
						case "route":
							address1 += component.long_name;
							addressObj.address1 = address1;
							break;
						case "postal_code":
							zip = `${component.long_name}${zip}`;
							addressObj.zip = zip;
							break;
						case "locality":
							addressObj.city = component.long_name;
							break;
						case "administrative_area_level_1":
							addressObj.state = component.short_name;
							break;
					}
				}
				if (form.getFieldValue("name")) {
					addressObj.name = form.getFieldValue("name");
				}
				form.setFieldsValue(addressObj);
			});

			setAutocomplete(newAutocomplete);
		} else if (!modalOpen && autocomplete) {
			window.google.maps.event.clearInstanceListeners(autocomplete);
			setAutocomplete(null);
		}
	}, [modalOpen, autocompleteInput.current, autocomplete, form]);

	const getValidationResponse = async (values) => {
		const url =
			ADDRESS_VALIDATION_API_URL +
			import.meta.env.VITE_ADDRESS_VALIDATION_API_KEY;
		let addressLines = values.address1;
		if (values.address2) addressLines += ` ${values.address2}`;

		const body = JSON.stringify({
			address: {
				regionCode: "US",
				locality: values.city,
				administrativeArea: values.state,
				postalCode: values.zip,
				addressLines: [addressLines],
			},
			previousResponseId: "",
			enableUspsCass: false,
		});

		try {
			const response = await fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: body,
			});
			if (!response.ok) {
				throw new Error(`HTTP error ${response.status}`);
			}
			return await response.json();
		} catch (error) {
			console.log(`Fetch error: ${error.message}`);
			return false;
		}
	};

	const addLocationToDbAndCloseModal = async (address) => {
		const geolocation = await getLocationGeoLocation(address);
		console.log(geolocation);
		const geoLoc = geolocation.results[0].geometry.location;
		const { lat, lng } = geoLoc;
		const body = {
			...address,
			latitude: lat,
			longitude: lng,
		};
		const response = await addLocation(body);
		if (response?.status) {
			form.resetFields();
		}
		toggleModal();
	};

	const validateAddress = async (values) => {
		const validationResponse = await getValidationResponse(values);

		if (!validationResponse) {
			setError(
				<span className="error-text fw-bold">
					Address validation failed. Please try again.
				</span>,
			);
			return false;
		}

		const { formattedAddress, missingComponentTypes } =
			validationResponse.result.address;
		const verdict = validationResponse.result.verdict;

		if (missingComponentTypes) {
			setError(
				<div className="d-flex flex-column">
					<span className="error-text fw-bold">
						Address could not be validated. Please enter additional information
						for:
					</span>
					{missingComponentTypes.map((component) => (
						<span key={component} className="error-text fw-bold">
							{convertComponentTypeToLabel(component)}
						</span>
					))}
				</div>,
			);
			return false;
		}

		if (verdict.addressComplete) {
			await addLocationToDbAndCloseModal({
				...values,
				google_address: formattedAddress,
			});
			return true;
		}

		setError(
			<span className="error-text fw-bold">
				Address could not be validated. Please check for any errors and try
				again.
			</span>,
		);
		return false;
	};

	const convertComponentTypeToLabel = (type) => {
		switch (type) {
			case "street_number":
				return "Street Number";
			case "subpremise":
				return "Address 2";
			case "route":
				return "Address 1";
			case "postal_code":
				return "Zip Code";
			case "locality":
				return "City";
			case "administrative_area_level_1":
				return "State";
			default:
				return type;
		}
	};

	const submit = async (values) => {
		setConfirmLoading(true); // Set loading to true when submission starts
		try {
			const isValid = await validateAddress(values);
			if (isValid) {
				message.success("Address validated and location added successfully.");
			} else {
				message.error("Address validation failed. Please correct the errors.");
			}
		} catch (error) {
			message.error(`${error.toString()}`);
			console.error("Submission error:", error);
		} finally {
			setConfirmLoading(false); // Always set loading to false after completion
		}
	};

	return (
		<Form form={form} layout="vertical" onFinish={submit}>
			{inputs.map((input) => (
				<Form.Item
					key={input.responseTag}
					label={input.label}
					name={input.responseTag}
					rules={[
						{
							required: input.required,
							message: `${input.label} is required`,
						},
					]}
				>
					<Input
						id={input.id}
						ref={input.responseTag === "address1" ? autocompleteInput : null}
					/>
				</Form.Item>
			))}
			{error}
		</Form>
	);
};

export { AddSingleLocation };
