import { CopyableTableCell } from "@/components/Typography/dfp-typography";
import { DB_ORG } from "@/constants/db";
import { parseDateToLocalString } from "@/utils/transforms";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Skeleton } from "antd";
import type { ColumnsType } from "antd/es/table";
import { State } from "../SubmissionsTable";

export const getOrgColumns = (
	organization: any,
	state: State | undefined,
): ColumnsType => {
	const columns: ColumnsType = [
		{
			title: "Asset ID",
			dataIndex: "asset_tag",
			key: "asset_tag",
			sorter: true,
			sortDirections: ["descend", "ascend", "descend"],
			render: (text, record) => (
				<CopyableTableCell text={text} isLoading={record.isLoading} />
			),
			width: "15%",
		},
		{
			title: "Asset Type",
			dataIndex: ["asset_type", "name"],
			key: "asset_type_name",
			sorter: true,
			sortDirections: ["descend", "ascend", "descend"],
			width: "20%",
			render: (text, record) => (
				<CopyableTableCell text={text} isLoading={record.isLoading} />
			),
			filters: state?.filterOptions?.assetTypes
				? state.filterOptions.assetTypes.map((type) => ({
						text: type.name,
						value: type.name,
					}))
				: undefined,
			filteredValue: state?.filters?.asset_type_name
				? state.filters.asset_type_name
				: undefined,
			filterSearch: true,
		},
		{
			title: "Address",
			dataIndex: "address",
			key: "address1",
			sorter: true,
			sortDirections: ["descend", "ascend", "descend"],
			width: "30%",
			render: (text, record) => (
				<CopyableTableCell text={text} isLoading={record.isLoading} />
			),
		},
		{
			title: "Submitting User",
			dataIndex: "submitting_user",
			key: "submitting_user",
			sorter: true,
			sortDirections: ["descend", "ascend", "descend"],
			render: (text, record) => (
				<CopyableTableCell text={text} isLoading={record.isLoading} />
			),
		},
		{
			title: "Submission Date",
			dataIndex: "submitted_date",
			key: "submitted_date",
			sorter: true,
			sortDirections: ["descend", "ascend", "descend"],
			render: (text, record) => (
				<CopyableTableCell
					text={parseDateToLocalString(text)}
					isLoading={record.isLoading}
				/>
			),
		},
	];
	const PCAColumns: ColumnsType = [
		{
			title: "Address",
			dataIndex: "address",
			key: "address1",
			sorter: true,
			sortDirections: ["descend", "ascend", "descend"],
			width: "30%",
			render: (text, record) => (
				<CopyableTableCell text={text} isLoading={record.isLoading} />
			),
		},
		{
			title: "Submitting User",
			dataIndex: "submitting_user",
			key: "submitting_user",
			sorter: true,
			sortDirections: ["descend", "ascend", "descend"],
			render: (text, record) => (
				<CopyableTableCell text={text} isLoading={record.isLoading} />
			),
		},
		{
			title: "Submission Date",
			dataIndex: "submitted_date",
			key: "submitted_date",
			sorter: true,
			sortDirections: ["descend", "ascend", "descend"],
			render: (text: string) =>
				!text ? (
					<Skeleton.Input style={{ width: 100 }} active />
				) : (
					<CopyableTableCell text={parseDateToLocalString(text)} />
				),
		},
	];

	const cushmanColumns: ColumnsType = [
		{
			title: "Site Name",
			dataIndex: "address",
			key: "address",
			sorter: true,
			sortDirections: ["descend", "ascend", "descend"],
			width: "30%",
			render: (text, record) => (
				<CopyableTableCell text={text} isLoading={record.isLoading} />
			),
		},
		{
			title: "Submitting User",
			dataIndex: "submitting_user",
			key: "submitting_user",
			sorter: true,
			sortDirections: ["descend", "ascend", "descend"],
			render: (text, record) => (
				<CopyableTableCell text={text} isLoading={record.isLoading} />
			),
		},
		{
			title: "Submission Date",
			dataIndex: "submitted_date",
			key: "submitted_date",
			sorter: true,
			sortDirections: ["descend", "ascend", "descend"],
			render: (text: string) =>
				!text ? (
					<Skeleton.Input style={{ width: 100 }} active />
				) : (
					<CopyableTableCell text={parseDateToLocalString(text)} />
				),
			width: "20%",
		},
		{
			title: "Approved",
			dataIndex: "is_approved",
			key: "is_approved",
			render: (text, record) => {
				return record.isLoading ? (
					<Skeleton.Input style={{ width: "500" }} active />
				) : text ? (
					<CheckOutlined className="green" />
				) : (
					<CloseOutlined className="red" />
				);
			},
			align: "center",
			width: "12%",
		},
	];
	if (state?.selectedForm === 7) {
		return PCAColumns;
	}
	switch (organization.external_firebase_id) {
		case DB_ORG.CUSHMANWAKEFIELD:
			return cushmanColumns;
		default:
			return columns;
	}
};
