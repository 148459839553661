import { DeleteOutlined, RestOutlined } from "@ant-design/icons";
import { Switch, Tooltip } from "antd";

const DeletedSwitch = ({ state, dispatch }) => {
	const { viewingDeleted, isLoading } = state;

	const onSwitchChange = (checked: boolean) => {
		dispatch({ type: "SET_VIEWING_DELETED", payload: checked });
	};

	return (
		<Tooltip title={viewingDeleted ? "Hide deleted" : "Show deleted"}>
			<Switch
				onChange={onSwitchChange}
				disabled={isLoading}
				checked={viewingDeleted}
				checkedChildren={<RestOutlined />}
				unCheckedChildren={<DeleteOutlined />}
			/>
		</Tooltip>
	);
};

export { DeletedSwitch };
