import { Loader } from "@/components/layouts/SignedIn/Loader/Loader";
import { DB_ORG } from "@/constants/db";
import { searchLocations } from "@/services/location-service";
import {
	Button,
	Col,
	Collapse,
	Form,
	Input,
	Modal,
	Row,
	Select,
	Switch,
	Tooltip,
	Typography,
} from "antd";
import { useEffect, useState } from "react";
import { IoMdSettings } from "react-icons/io";
import MonthRangeOption from "./MonthRangeDropdown";
import {
	stateLabels,
	statesCVS,
	statesCushman,
	statesFL,
	statesRC,
} from "./geographicalStates";

const { Title } = Typography;
const { Panel } = Collapse;

const PredSpendFilter = ({
	state,
	parentDispatch,
	chartState,
	chartDispatch,
}) => {
	const {
		assetTypes,
		checkedAssetTypes,
		checkedStates,
		portfolioChecked,
		checkedLocations,
		allLocations,
		organization,
		healthScoreFilterType,
		healthScoreFilterValue,
		budget,
		budgetType,
		years,
	} = state;
	const { selectedYear } = chartState;
	const [searchVal, setSearchVal] = useState("");
	const [searchedLocations, setSearchedLocations] = useState([]);
	const [searchLoading, setSearchLoading] = useState(false);
	const [timeoutId, setTimeoutId] = useState(null);

	const [stateSearchVal, setStateSearchVal] = useState("");
	const [filteredStates, setFilteredStates] = useState([]);
	const [allStates, setAllStates] = useState([]);
	const [filterByState, setFilterByState] = useState(false);

	const updateSearchVal = (e) => {
		if (timeoutId) {
			clearTimeout(timeoutId);
		}
		const newTimeoutId = setTimeout(() => {
			setSearchLoading(true);
			setSearchVal(e.target.value);
		}, 2000);
		setTimeoutId(newTimeoutId);
	};

	const updateStateSearchVal = (e) => {
		setStateSearchVal(e.target.value);
	};

	useEffect(() => {
		if (organization) {
			let statesArray = [];
			if (organization === DB_ORG.CVS) {
				statesArray = statesCVS;
			} else if (organization === DB_ORG.CUSHMANWAKEFIELD) {
				statesArray = statesCushman;
			} else if (organization === DB_ORG.RAISINGCANES) {
				statesArray = statesRC;
			} else if (organization === DB_ORG.FOODLION) {
				statesArray = statesFL;
			}

			if (statesArray.length > 0) {
				const stateLabelsArray = statesArray.sort().map((state) => ({
					value: state,
					label: stateLabels[state],
				}));
				setAllStates(stateLabelsArray);
				setFilteredStates(stateLabelsArray);
			} else {
				const fetchStates = async () => {
					const uniqueStates = Array.from(
						new Set(
							allLocations
								.filter((item) => item?.data?.state)
								.map((item) => item.data.state),
						),
					).sort();
					const stateLabelsArray = uniqueStates.map((state) => ({
						value: state,
						label: stateLabels[state],
					}));
					setAllStates(stateLabelsArray);
					setFilteredStates(stateLabelsArray);
				};
				fetchStates();
			}
		}
	}, [organization]);

	useEffect(() => {
		if (stateSearchVal === "") {
			setFilteredStates(allStates);
		} else {
			const filtered = allStates.filter((state) =>
				state.label.toLowerCase().includes(stateSearchVal.toLowerCase()),
			);
			setFilteredStates(filtered);
		}
	}, [stateSearchVal, allStates]);

	const yearsOptions = [
		{ label: "5 years", value: 5 },
		{ label: "10 years", value: 10 },
		{ label: "15 years", value: 15 },
	];

	const searchForLocation = async (e = null) => {
		if (e != null) {
			e.preventDefault();
		}
		setSearchLoading(true);

		const location_retval = await searchLocations(
			searchVal,
			organization,
			1,
			25,
			"state",
			"asc",
			{},
		);
		const minified_locations = location_retval.results.map((location) => ({
			id: location.id,
			address1: location.address1,
			address2: location.address2,
			city: location.city,
			state: location.state,
			zip: location.zip,
		}));
		setSearchedLocations(minified_locations);
		setSearchLoading(false);
	};

	useEffect(() => {
		if (searchVal.length > 0) {
			searchForLocation();
		} else {
			setSearchedLocations([]);
			setSearchLoading(false);
		}
	}, [searchVal]);

	const handleLocationChange = (location) => {
		if (portfolioChecked) {
			parentDispatch({
				type: "SET_PORTFOLIO_CHECKED",
				payload: false,
			});
		}
		if (checkedLocations.filter((loc) => loc.id === location.id).length > 0) {
			parentDispatch({
				type: "SET_CHECKED_LOCATIONS",
				payload: state.checkedLocations.filter((loc) => loc.id !== location.id),
			});
		} else {
			parentDispatch({
				type: "SET_CHECKED_LOCATIONS",
				payload: [...state.checkedLocations, location],
			});
			parentDispatch({
				type: "SET_CHECKED_ASSET_TYPES",
				payload: [],
			});
		}
	};

	const handleStateChange = (stateItem) => {
		if (portfolioChecked) {
			parentDispatch({
				type: "SET_PORTFOLIO_CHECKED",
				payload: false,
			});
		}
		if (checkedStates.some((s) => s.value === stateItem.value)) {
			parentDispatch({
				type: "SET_CHECKED_STATES",
				payload: checkedStates.filter((s) => s.value !== stateItem.value),
			});
		} else {
			parentDispatch({
				type: "SET_CHECKED_STATES",
				payload: [...checkedStates, stateItem],
			});
		}
		parentDispatch({
			type: "SET_CHECKED_LOCATIONS",
			payload: [],
		});
	};

	const handleAssetTypeChange = (assetType) => {
		if (portfolioChecked) {
			parentDispatch({
				type: "SET_PORTFOLIO_CHECKED",
				payload: false,
			});
		}

		if (checkedAssetTypes.includes(assetType)) {
			parentDispatch({
				type: "SET_CHECKED_ASSET_TYPES",
				payload: checkedAssetTypes.filter((type) => type !== assetType),
			});
		} else {
			parentDispatch({
				type: "SET_CHECKED_ASSET_TYPES",
				payload: [...checkedAssetTypes, assetType],
			});
		}

		if (checkedLocations.length > 0) {
			parentDispatch({
				type: "SET_CHECKED_LOCATIONS",
				payload: [],
			});
		}
		if (checkedStates.length > 0) {
			parentDispatch({
				type: "SET_CHECKED_STATES",
				payload: [],
			});
		}
	};

	const handlePortfolioChange = (checked) => {
		if (checked) {
			parentDispatch({
				type: "SET_CHECKED_LOCATIONS",
				payload: [],
			});
			parentDispatch({
				type: "SET_CHECKED_STATES",
				payload: [],
			});
			parentDispatch({
				type: "SET_CHECKED_ASSET_TYPES",
				payload: [],
			});
		}
		parentDispatch({
			type: "SET_PORTFOLIO_CHECKED",
			payload: !portfolioChecked,
		});
	};

	const healthScoreFilterOptions = [
		{ value: "less", label: "Less than or equal to" },
		{ value: "greater", label: "Greater than or equal to" },
	];

	const healthScoreOptions = Array.from({ length: 20 }, (_, i) => ({
		value: (i + 1) * 5,
		label: `${(i + 1) * 5}`,
	}));

	const handleBudgetChange = (e, year = null) => {
		const value = e.target.value.replace(/\D/g, "");
		if (budgetType === "static") {
			const newBudget = Array.from({ length: years }, () => Number(value));
			parentDispatch({
				type: "SET_BUDGET",
				payload: newBudget,
			});
		} else {
			let newBudget = budget
				? [...budget]
				: Array.from({ length: years }).fill(null);
			newBudget[year - new Date().getFullYear()] = Number(value);
			parentDispatch({
				type: "SET_BUDGET",
				payload: newBudget,
			});
		}
	};

	const startYear = new Date().getFullYear();
	const endYear = startYear + years;
	const yearsArr = Array.from(
		{ length: endYear - startYear },
		(_, i) => startYear + i,
	);

	function formatNumber(amount) {
		if (Number.isNaN(amount)) {
			return "0";
		}
		const integerAmount = Math.floor(Number(amount));
		const formattedAmount = integerAmount
			.toString()
			.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		return `${formattedAmount}`;
	}

	const handleYearChange = (value) => {
		parentDispatch({
			type: "SET_YEARS",
			payload: value,
		});
		if (budgetType === "static") {
			const newBudget = Array.from({ length: value }, () =>
				budget[0] ? budget[0] : null,
			);
			parentDispatch({
				type: "SET_BUDGET",
				payload: newBudget,
			});
		} else {
			const newBudget = Array.from({ length: value }, (_, i) =>
				budget[i] ? budget[i] : null,
			);
			parentDispatch({
				type: "SET_BUDGET",
				payload: newBudget,
			});
		}
	};

	const [modal, setModal] = useState(false);

	const toggleModal = () => {
		setModal(!modal);
	};

	return (
		<>
			{!selectedYear && (
				<Tooltip title="Settings">
					<div className="pred-spend-navbar-item">
						<IoMdSettings
							className="pred-spend-navbar-icon"
							onClick={toggleModal}
						/>
					</div>
				</Tooltip>
			)}

			<MonthRangeOption
				onRangeChange={(dates) => {
					parentDispatch({
						type: "SET_START_DATE",
						payload: dates[0],
					});
					parentDispatch({
						type: "SET_END_DATE",
						payload: dates[1],
					});
				}}
			/>

			<Modal
				open={modal}
				onCancel={toggleModal}
				title="Settings"
				footer={null}
				className="predictive-spend-filter-modal"
				width="600px"
			>
				<Form layout="vertical">
					<SwitchLabelPair
						label="Portfolio - All In-scope Equipment"
						checked={portfolioChecked}
						onChange={handlePortfolioChange}
						disabled={portfolioChecked}
					/>
					{/* <SwitchLabelPair
						label="Filter by State"
						checked={filterByState}
						onChange={() => setFilterByState(!filterByState)}
					/> */}

					<Form.Item label="Location - All In-scope Equipment">
						<Input.Search
							placeholder="Search For Location"
							disabled={searchLoading}
							onChange={updateSearchVal}
							style={{ width: "100%" }}
						/>
					</Form.Item>
					{searchLoading && <Loader />}
					<SearchedLocations
						locations={searchedLocations}
						handleLocationChange={handleLocationChange}
						checkedLocations={checkedLocations}
					/>

					{/* {filterByState && (
						<>
							<Title level={5} style={{ marginTop: 16 }}>
								State - All In-scope Equipment
							</Title>
							<Form.Item>
								<Input.Search
									placeholder="Search For State"
									value={stateSearchVal}
									onChange={updateStateSearchVal}
									style={{ width: "100%" }}
								/>
							</Form.Item>
							{filteredStates.map((stateItem) => (
								<StateOption
									key={stateItem.value}
									label={stateItem.label}
									isChecked={checkedStates.some(
										(s) => s.value === stateItem.value,
									)}
									onSwitchChange={() => handleStateChange(stateItem)}
									checkedStates={checkedStates}
								/>
							))}
						</>
					)} */}
					<Collapse>
						<Panel header="Equipment Type" key="equipmentType">
							<div
								style={{ maxHeight: "200px", overflowY: "scroll" }}
								className="pill-scrollbar"
							>
								{assetTypes.map((assetType) => (
									<AssetTypeOption
										key={assetType.id}
										label={assetType.name}
										isChecked={checkedAssetTypes.includes(assetType.id)}
										onSwitchChange={() => handleAssetTypeChange(assetType.id)}
										checkedAssetTypes={checkedAssetTypes}
									/>
								))}
							</div>
						</Panel>
					</Collapse>
					<div style={{ display: "flex", gap: "16px", marginTop: "16px" }}>
						<Form.Item
							label="Filter Type"
							style={{ flex: 1, fontWeight: "bold" }}
						>
							<Select
								options={healthScoreFilterOptions}
								onChange={(value) =>
									parentDispatch({
										type: "SET_HEALTH_SCORE_FILTER_TYPE",
										payload: value,
									})
								}
								value={healthScoreFilterType}
								placeholder="Select Filter Type"
							/>
						</Form.Item>
						<Form.Item
							label="Filter by Value"
							style={{ flex: 1, fontWeight: "bold" }}
						>
							<Select
								options={healthScoreOptions}
								onChange={(value) =>
									parentDispatch({
										type: "SET_HEALTH_SCORE_FILTER_VALUE",
										payload: value,
									})
								}
								value={healthScoreFilterValue}
								disabled={!healthScoreFilterType}
								placeholder="Select Health Score Value"
							/>
						</Form.Item>
					</div>
					{healthScoreFilterType && healthScoreFilterValue && (
						<Button
							onClick={() => {
								parentDispatch({
									type: "SET_HEALTH_SCORE_FILTER_TYPE",
									payload: null,
								});
								parentDispatch({
									type: "SET_HEALTH_SCORE_FILTER_VALUE",
									payload: null,
								});
							}}
						>
							Clear
						</Button>
					)}

					<Form.Item label="Budget Type">
						<Select
							options={[
								{ value: "static", label: "Static Budget" },
								{ value: "varied", label: "Varied Budget" },
							]}
							onChange={(value) => {
								console.log(value);
								parentDispatch({
									type: "SET_BUDGET",
									payload: Array.from({ length: years }).fill(null),
								});
								parentDispatch({
									type: "SET_BUDGET_TYPE",
									payload: value,
								});
							}}
							value={budgetType}
							placeholder="Select Budget Type"
						/>
					</Form.Item>
					{budgetType === "static" ? (
						<>
							<Form.Item label="Annual Budget">
								<Input
									addonBefore="$"
									placeholder="Enter Budget"
									value={budget[0] ? formatNumber(budget[0]) : ""}
									onChange={handleBudgetChange}
									type="text"
								/>
							</Form.Item>
						</>
					) : (
						// Group yearsArr into pairs
						yearsArr
							.reduce((result, year, index) => {
								if (index % 2 === 0) {
									result.push([year]);
								} else {
									result[result.length - 1].push(year);
								}
								return result;
							}, [])
							.map((pair, rowIndex) => (
								<Row gutter={16} key={rowIndex}>
									{pair.map((year) => (
										<Col span={12} key={year}>
											<Form.Item label={`${year}`}>
												<Input
													addonBefore="$"
													placeholder="Enter Budget"
													value={
														budget[year - startYear]
															? formatNumber(budget[year - startYear])
															: ""
													}
													onChange={(e) => handleBudgetChange(e, year)}
													type="text"
													status={!budget[year - startYear] ? "error" : ""}
												/>
											</Form.Item>
										</Col>
									))}
								</Row>
							))
					)}

					<Form.Item label="Years">
						<Select
							options={yearsOptions}
							onChange={(value) => handleYearChange(value)}
							value={years}
							placeholder="Select Years"
						/>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

const truncateLabel = (label, maxLength = 20) => {
	if (label.length > maxLength) {
		return `${label.substring(0, maxLength)}...`;
	}
	return label;
};

const SwitchLabelPair = ({ label, checked, onChange, disabled }) => (
	<div
		className="d-flex justify-content-between"
		style={{ marginBottom: 8, fontSize: "14px" }}
	>
		<span>{label}</span>
		<Switch checked={checked} onChange={onChange} disabled={disabled} />
	</div>
);

const LocationOption = ({
	label,
	isChecked,
	onSwitchChange,
	checkedLocations,
}) => {
	return (
		<div
			className="d-flex justify-content-between"
			style={{ marginBottom: 8, fontSize: "14px" }}
		>
			<span>{truncateLabel(label, 30)}</span>
			<Switch
				checked={isChecked}
				onChange={onSwitchChange}
				disabled={checkedLocations.length === 1 && isChecked}
			/>
		</div>
	);
};

const StateOption = ({ label, isChecked, onSwitchChange, checkedStates }) => {
	return (
		<div
			className="d-flex justify-content-between"
			style={{ marginBottom: 8, fontSize: "14px" }}
		>
			<span>{truncateLabel(label, 30)}</span>
			<Switch
				checked={isChecked}
				onChange={onSwitchChange}
				disabled={checkedStates.length === 1 && isChecked}
			/>
		</div>
	);
};

const SearchedLocations = ({
	locations,
	handleLocationChange,
	checkedLocations,
}) => {
	// Group locations by state
	const groupedLocations = locations.reduce((acc, location) => {
		const { state } = location;
		if (!acc[state]) acc[state] = [];
		acc[state].push(location);
		return acc;
	}, {});

	return (
		locations.length > 0 && (
			<Collapse defaultActiveKey={["1"]} className="mb-3">
				<Panel header="Locations" key="1">
					<div
						style={{ maxHeight: "200px", overflowY: "scroll" }}
						className="pill-scrollbar"
					>
						{Object.keys(groupedLocations).map((stateAbbreviation) => (
							<div key={stateAbbreviation}>
								{/* State Label */}
								<h5 className="fw-bold">
									{stateLabels[stateAbbreviation] || stateAbbreviation}
								</h5>
								{/* Locations under this State */}
								{groupedLocations[stateAbbreviation].map((location) => {
									let label = "";
									if (location.address1) label += `${location.address1}, `;
									if (location.city) label += `${location.city}, `;
									if (location.state) label += `${location.state}`;
									if (location.zip) label += `${location.zip}`;

									return (
										<div key={location.id}>
											<LocationOption
												label={label}
												isChecked={checkedLocations.some(
													(loc) => loc.id === location.id,
												)}
												onSwitchChange={() => handleLocationChange(location)}
												checkedLocations={checkedLocations}
											/>
										</div>
									);
								})}
							</div>
						))}
					</div>
				</Panel>
			</Collapse>
		)
	);
};

const AssetTypeOption = ({
	label,
	isChecked,
	onSwitchChange,
	checkedAssetTypes,
}) => {
	return (
		<div
			className="d-flex justify-content-between"
			style={{ marginBottom: 8, fontSize: "14px" }}
		>
			<span>{truncateLabel(label, 25)}</span>
			<Switch
				checked={isChecked}
				onChange={onSwitchChange}
				disabled={checkedAssetTypes.length === 1 && isChecked}
			/>
		</div>
	);
};

export { PredSpendFilter };
