import { DFPEmpty } from "@/components/Empty/dfp-empty";
import { DFPTable } from "@/components/Tables/dfp-table";
import {
	type BaseTableAction,
	type BaseTableState,
	baseInitialState,
	baseTableReducer,
} from "@/components/Tables/sharedTableState";
import { selfOrganizationAssetTypes } from "@/services/organization-service";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Space } from "antd";
import type { ColumnsType } from "antd/es/table";
import type { FC, ReactNode } from "react";
import { useEffect, useReducer } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import type {
	ASCENDING,
	DESCENDING,
} from "../AssetsTable/children/AssetsTable";
import { ColumnConfigDropdown } from "./ColumnConfigDropdown";
import { draftColumns } from "./Columns/DraftsColumns";
import { getOrgColumns } from "./Columns/SubmissionsColumns";
import { DeleteButton } from "./DeleteButton";
import { DeleteModal } from "./DeleteModal";
import { DeletedSwitch } from "./DeletedSwitch";
import { FormSelect } from "./FormSelect";
import { QASubmissionDraftSelect } from "./QASubmissionDraftSelect";
import { fetchData } from "./childFunctions/fetchData";
import { getTableProps } from "./childFunctions/getTableProps";
import { initializeColumnsShown } from "./childFunctions/initializeColumnsShown";
import { initializeFormsAndColumns } from "./childFunctions/initializeFormsAndColumns";
import { ExportModal } from "./children/ExportModal";

const { Search } = Input;

export interface Submission {
	id: string | number;
	formIcon: ReactNode;
	asset_tag: string;
	asset_type: string;
	address: string;
	submitting_user: string;
	submitted_date: string;
	isLoading?: boolean;
}

export interface Draft {
	id: string | number;
	address: string;
	submitting_user: string;
	submitted_date: string;
	isLoading?: boolean;
}

interface SubmissionsTableProps {
	organization: any;
	browserLocationId: string;
}

interface FormConfig {
	// Define the properties of FormConfig here
	formName: string;
	// ... other properties as needed
}

interface MasterFormKey {
	description: string | null;
	id: number;
	handler_function_key: string | null;
	name: string;
}

interface Form {
	form_id: number;
	config: FormConfig;
	required_access_level: number;
	organization_id: number;
	collections: unknown | null;
	enabled: boolean;
	master_form_key: MasterFormKey;
}

interface FilterOptions {
	assetTypes: string[];
}

// Define action types
export type Action =
	| BaseTableAction
	| { type: "SET_VIEWING_DRAFTS"; payload: boolean }
	| { type: "SET_VIEWING_DELETED"; payload: boolean }
	| { type: "SET_FORMS_ARR"; payload: Form[] }
	| { type: "SET_SELECTED_FORM"; payload: Form }
	| { type: "SET_SELECTED_FORM_MODE"; payload: string }
	| { type: "SET_DATA_FLEET_FORMS"; payload: unknown }
	| { type: "SET_FILTERS"; payload: unknown }
	| { type: "SET_SHOW_EXPORT_MODAL"; value: boolean }
	| { type: "SET_SHOW_DELETE_MODAL"; value: boolean }
	| { type: "SET_FILTER_OPTIONS"; payload: unknown };

// Initial state
export interface State extends BaseTableState {
	viewingDrafts: boolean;
	viewingDeleted: boolean;
	formsArr: Form[];
	selectedForm: number | null;
	selectedFormMode: string;
	filters: unknown;
	filterOptions: FilterOptions | null;
	showExportModal: boolean;
	showDeleteModal: boolean;
}

const initialState: State = {
	...baseInitialState,
	tableColumns: [],
	sortBy: "end_time",
	// specific table state
	viewingDrafts: false,
	viewingDeleted: false,
	formsArr: [],
	selectedForm: null,
	selectedFormMode: "0",
	filters: {},
	filterOptions: null,
	showExportModal: false,
	showDeleteModal: false,
};

function reducer(state: State, action: Action): State {
	switch (action.type) {
		case "SET_VIEWING_DRAFTS":
			return { ...state, viewingDrafts: action.payload };
		case "SET_VIEWING_DELETED":
			return { ...state, viewingDeleted: action.payload };
		case "SET_FORMS_ARR":
			return { ...state, formsArr: action.payload };
		case "SET_SELECTED_FORM":
			return { ...state, selectedForm: action.payload };
		case "SET_SELECTED_FORM_MODE":
			return { ...state, selectedFormMode: action.payload };
		case "SET_IS_LOADING":
			return { ...state, isLoading: action.payload };
		case "SET_FILTERS":
			return { ...state, filters: action.payload };
		case "SET_FILTER_OPTIONS":
			return { ...state, filterOptions: action.payload };
		case "SET_SHOW_EXPORT_MODAL":
			return { ...state, showExportModal: action.value };
		case "SET_SHOW_DELETE_MODAL":
			return { ...state, showDeleteModal: action.value };
		default:
			return baseTableReducer(state, action);
	}
}

// Submissions Table component
const SubmissionsTable: FC<SubmissionsTableProps> = (
	props: SubmissionsTableProps,
) => {
	const { organization, browserLocationId } = props;
	const location = useLocation();

	// Try to get preserved state from location.state
	const preservedState = location?.state?.submissionsTableParams
		? location.state.submissionsTableParams
		: {};

	const [state, dispatch] = useReducer(reducer, {
		...initialState,
		pageNum: preservedState?.pageNum || initialState.pageNum,
		pageSize: preservedState?.pageSize || initialState.pageSize,
		searchVal: preservedState?.searchVal || initialState.searchVal,
		sortBy: preservedState?.sortBy || initialState.sortBy,
		sortOrder:
			(preservedState?.sortOrder as typeof ASCENDING | typeof DESCENDING) ||
			initialState.sortOrder,
		selectedFormMode:
			preservedState?.selectedFormMode || initialState.selectedFormMode,
		selectedForm: preservedState?.selectedForm || initialState.selectedForm,
		viewingDeleted:
			preservedState?.viewingDeleted || initialState.viewingDeleted,
		filters: preservedState?.filters || initialState.filters,
		filterOptions: preservedState?.filterOptions || initialState.filterOptions,
		tableColumns: getOrgColumns(organization, undefined),
		columnsShown: initializeColumnsShown(
			getOrgColumns(organization, undefined),
		),
	});

	const navigate = useNavigate();
	const {
		sortBy,
		sortOrder,
		pageNum,
		pageSize,
		searchVal,
		viewingDrafts,
		viewingDeleted,
		selectedForm,
		selectedFormMode,
		isLoading,
		filters,
		filterOptions,
		showExportModal,
		showDeleteModal,
	} = state;

	const getFilterOptions = async (dispatch) => {
		const assetTypes = await selfOrganizationAssetTypes(false);
		dispatch({ type: "SET_FILTER_OPTIONS", payload: { assetTypes } });
	};

	// 1. get filter options
	useEffect(() => {
		if (!filterOptions) {
			getFilterOptions(dispatch);
		}
	}, [organization]);

	// 2. Initialize forms and columns
	useEffect(() => {
		if (filterOptions !== null) {
			initializeFormsAndColumns(state, dispatch, organization);
		}
	}, [filterOptions]);

	// 3. Fetch data when dependencies change
	useEffect(() => {
		if (!selectedForm || !selectedFormMode) {
			return; // Wait until initial forms are set
		}
		fetchData(state, dispatch, browserLocationId).then(() => {
			// Determine currentColumns based on viewingDrafts
			const tableParams = {
				searchVal,
				selectedForm,
				selectedFormMode,
				pageNum,
				pageSize,
				sortBy,
				sortOrder,
				browserLocationId,
				filters,
				viewingDeleted,
				viewingDrafts,
			};
			let currentColumns: ColumnsType<Submission> | ColumnsType<Draft>;
			if (tableParams.viewingDrafts) {
				currentColumns = draftColumns;
			} else {
				currentColumns = getOrgColumns(organization, state, dispatch);
			}
			// Update table columns and columnsShown
			dispatch({ type: "SET_TABLE_COLUMNS", payload: currentColumns });
			const newColumnsShown = initializeColumnsShown(currentColumns);
			dispatch({ type: "SET_COLUMNS_SHOWN", payload: newColumnsShown });
		});
	}, [
		pageNum,
		pageSize,
		sortBy,
		sortOrder,
		searchVal,
		viewingDeleted,
		selectedFormMode,
		viewingDrafts,
		selectedForm,
		organization,
		browserLocationId,
		filters,
	]);

	const handleRowClick = (record: any) => {
		const isAdminView = location.pathname.includes("admin");
		const locationId = browserLocationId || "";
		if (state.viewingDrafts) {
			const responseData = record?.draft?.body || record?.draft;
			const location = record?.location;
			const userLocation = record?.draft?.userLocation;
			// navigate to form
			navigate(`/forms/${record.form_id}`, {
				state: {
					responseData,
					draftId: record.id,
					formId: record.form_id,
					locationId: location.id,
					savedLocation: location,
					chosenAssetType: responseData?.assetType,
					userLocation: userLocation,
					isQA: false,
					fromDraftsTable: true,
					filters,
					filterOptions,
				},
			});
			return;
		}
		navigate(
			`${
				isAdminView
					? `/admin/submissions/${record.id}`
					: `/locations/${locationId}/submissions/${record.id}`
			}`,
			{
				state: {
					...location.state,
					formId: selectedForm,
					submissionsTableParams: {
						pageNum,
						pageSize,
						sortBy,
						sortOrder,
						searchVal,
						viewingDeleted,
						selectedFormMode,
						viewingDrafts,
						selectedForm,
						filters,
						filterOptions,
					},
				},
			},
		);
	};

	const tableProps = getTableProps(state, dispatch, {
		handleRowClick,
	});

	const toggleExportModal = () => {
		dispatch({ type: "SET_SHOW_EXPORT_MODAL", value: !showExportModal });
	};

	const toggleDeleteModal = () => {
		dispatch({ type: "SET_SHOW_DELETE_MODAL", value: !showDeleteModal });
	};

	return (
		<div className="table-card">
			<div className="table-header d-flex justify-content-between">
				<Search
					placeholder="Search"
					allowClear
					style={{ width: 300 }}
					onSearch={(value: string) => {
						dispatch({ type: "SET_SEARCH_VAL", payload: value });
					}}
					disabled={isLoading}
					defaultValue={searchVal}
				/>
				<Space>
					<FormSelect state={state} dispatch={dispatch} />
					<QASubmissionDraftSelect state={state} dispatch={dispatch} />
					<DeletedSwitch state={state} dispatch={dispatch} />
				</Space>
				<Space>
					<ColumnConfigDropdown state={state} dispatch={dispatch} />
					<DeleteButton state={state} onClick={toggleDeleteModal} />
					<Button
						icon={<DownloadOutlined style={{ fontSize: "20px" }} />}
						type="text"
						onClick={toggleExportModal}
					/>
				</Space>
			</div>
			<Divider />
			<DFPTable<any>
				locale={{
					emptyText: state.isLoading ? null : (
						<DFPEmpty
							className="w-100 h-100"
							description="No submissions returned. Please modify your search term or update your filters and try again."
						/>
					),
				}}
				{...tableProps}
			/>
			<ExportModal
				showExport={showExportModal}
				toggle={toggleExportModal}
				browserLocationId={browserLocationId}
			/>
			<DeleteModal
				state={state}
				dispatch={dispatch}
				browserLocationId={browserLocationId}
				tableType="submissions"
				fetchData={fetchData}
			/>
		</div>
	);
};

export { SubmissionsTable };
