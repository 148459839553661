import { message } from "antd";
import type { Dispatch } from "react";
import type { Action, State } from "../SubmissionsTable";
import { searchResponses } from "../children/Search";

export async function fetchData(
	state: State,
	dispatch: Dispatch<Action>,
	browserLocationId: string,
) {
	try {
		dispatch({ type: "SET_IS_LOADING", payload: true });

		let {
			searchVal,
			selectedForm,
			selectedFormMode,
			pageNum,
			pageSize,
			sortBy,
			sortOrder,
			filters,
			viewingDeleted,
			viewingDrafts,
		} = state;

		let tableParams = {
			searchVal,
			selectedForm,
			selectedFormMode,
			pageNum,
			pageSize,
			sortBy,
			sortOrder,
			browserLocationId,
			filters,
			viewingDeleted,
			viewingDrafts,
		};

		let responses = await searchResponses(tableParams);

		if (responses?.results?.length === 0 && pageNum > 1) {
			pageNum = 1;
			tableParams.pageNum = pageNum; // Update tableParams with new pageNum
			responses = await searchResponses(tableParams); // Fetch data again
			message.info("No results found on this page, showing first page.");
		}

		dispatch({ type: "SET_PAGE_NUM", payload: pageNum });
		dispatch({
			type: "SET_DATA_SOURCE",
			payload: responses?.results || [],
		});
		dispatch({ type: "SET_COUNT", payload: responses.count || 0 });
	} catch (exception) {
		console.error(`Failed to search for submissions: ${exception}`);
		throw new Error("Failed to search for submissions");
	} finally {
		dispatch({ type: "SET_IS_LOADING", payload: false });
	}
}
