import { SendEmail } from "@/components/layouts/SignedIn/Navbar/children/SendEmail";
import {
	BankOutlined,
	LockOutlined,
	MailOutlined,
	UserOutlined,
} from "@ant-design/icons";
import logo from "@assets/img/FacilityAI_Logo.svg";
import { AuthProviders, auth } from "@assets/services/auth-service";
import { useLoginMutation } from "@hooks/authQueries";
import { useSetUser } from "@hooks/userQueries";
import { userSignup } from "@services/user-service";
import {
	Button,
	Card,
	Divider,
	Form,
	Input,
	Layout,
	Typography,
	message,
} from "antd";
import Link from "antd/lib/typography/Link";
import React, { useReducer, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const { Content, Footer } = Layout;
const { Title, Text } = Typography;

// Define the state structure
interface State {
	mode: "login" | "create" | "reset";
	email: string;
	password: string;
	name: string;
	org: string;
}

// Define all possible actions
type ModeType = "login" | "create" | "reset";

type Action =
	| { type: "SET_MODE"; payload: ModeType }
	| { type: "SET_EMAIL"; payload: string }
	| { type: "SET_PASSWORD"; payload: string }
	| { type: "SET_NAME"; payload: string }
	| { type: "SET_ORG"; payload: string };

const initialState: State = {
	mode: "login",
	email: "",
	password: "",
	name: "",
	org: "",
};

function reducer(state: State, action: Action): State {
	switch (action.type) {
		case "SET_MODE":
			return {
				...state,
				mode: action.payload,
				name: "",
				org: "",
				password: "",
				email: "",
			};
		case "SET_EMAIL":
			return { ...state, email: action.payload };
		case "SET_PASSWORD":
			return { ...state, password: action.payload };
		case "SET_NAME":
			return { ...state, name: action.payload };
		case "SET_ORG":
			return { ...state, org: action.payload };
		default:
			return state;
	}
}

export const Login: React.FC = () => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const navigate = useNavigate() as any;
	const setUser = useSetUser() as any;
	const loginMutation = useLoginMutation();

	const submitLogin = useCallback(() => {
		if (!state.email || !state.password) {
			message.warning("Please enter your email and password");
			return; // Ideally show a validation error via Form validation
		}
		try {
			loginMutation.mutate({
				email: state.email,
				password: state.password,
				navigate,
			});
			message.success("Login Successful");
		} catch (error) {
			message.error("Login Failed");
		}
	}, [loginMutation, state.email, state.password, navigate]);

	const submitCreateAccount = useCallback(async () => {
		if (!state.email || !state.password || !state.name || !state.org) {
			message.warning("Please fill out all fields");
			return; // Show validation errors as needed
		}
		try {
			const user = await userSignup(
				state.name,
				state.email,
				state.password,
				state.org,
			);
			await setUser.mutate({ user });
			navigate("/");
			message.success("Account Created");
		} catch (error) {
			message.error("Account Creation Failed");
		}
	}, [state.name, state.email, state.password, state.org, setUser, navigate]);

	const submitResetPassword = useCallback(async () => {
		if (!state.email) {
			message.warning("Please enter your email");
			return; // Show validation error
		}
		try {
			await AuthProviders.sendPasswordResetEmail(auth, state.email);
			message.success("Password Reset Email Sent");
			dispatch({ type: "SET_MODE", payload: "login" });
		} catch (error) {
			message.error("Password Reset Failed");
		}
	}, [state.email]);

	const renderLoginForm = () => (
		<>
			<Form layout="vertical" onFinish={submitLogin}>
				<Form.Item
					label="Email"
					name="email"
					rules={[{ required: true, message: "Please enter your email" }]}
				>
					<Input
						placeholder="Email"
						prefix={<MailOutlined />}
						value={state.email}
						onChange={(e) =>
							dispatch({ type: "SET_EMAIL", payload: e.target.value })
						}
					/>
				</Form.Item>
				<Form.Item
					label="Password"
					name="password"
					rules={[{ required: true, message: "Please enter your password" }]}
				>
					<Input.Password
						placeholder="Password"
						prefix={<LockOutlined />}
						value={state.password}
						onChange={(e) =>
							dispatch({ type: "SET_PASSWORD", payload: e.target.value })
						}
					/>
				</Form.Item>
				<Form.Item style={{ marginTop: "4vh" }}>
					<Button type="primary" htmlType="submit" block>
						Log In
					</Button>
				</Form.Item>
			</Form>
			<Divider>OR</Divider>
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<Button
					type="default"
					onClick={() => dispatch({ type: "SET_MODE", payload: "create" })}
				>
					Create Account
				</Button>
				<Button
					type="link"
					onClick={() => dispatch({ type: "SET_MODE", payload: "reset" })}
				>
					Reset Password
				</Button>
			</div>
		</>
	);

	const renderCreateForm = () => (
		<>
			<Form layout="vertical" onFinish={submitCreateAccount}>
				<Form.Item
					label="Full Name"
					name="name"
					rules={[{ required: true, message: "Please enter your full name" }]}
				>
					<Input
						placeholder="Full Name"
						prefix={<UserOutlined />}
						value={state.name}
						onChange={(e) =>
							dispatch({ type: "SET_NAME", payload: e.target.value })
						}
					/>
				</Form.Item>
				<Form.Item
					label="Organization"
					name="org"
					rules={[
						{ required: true, message: "Please enter your organization name" },
					]}
				>
					<Input
						placeholder="Organization"
						prefix={<BankOutlined />}
						value={state.org}
						onChange={(e) =>
							dispatch({ type: "SET_ORG", payload: e.target.value })
						}
					/>
				</Form.Item>
				<Form.Item
					label="Email"
					name="email"
					rules={[
						{
							required: true,
							type: "email",
							message: "Please enter a valid email",
						},
					]}
				>
					<Input
						placeholder="Email"
						prefix={<MailOutlined />}
						value={state.email}
						onChange={(e) =>
							dispatch({ type: "SET_EMAIL", payload: e.target.value })
						}
					/>
				</Form.Item>
				<Form.Item
					label="Password"
					name="password"
					rules={[{ required: true, message: "Please enter a password" }]}
				>
					<Input.Password
						placeholder="Password"
						prefix={<LockOutlined />}
						value={state.password}
						onChange={(e) =>
							dispatch({ type: "SET_PASSWORD", payload: e.target.value })
						}
					/>
				</Form.Item>
				<Form.Item style={{ marginTop: "4vh" }}>
					<Button type="primary" htmlType="submit" block>
						Submit
					</Button>
				</Form.Item>
			</Form>
			<Divider>OR</Divider>
			<Button
				type="default"
				block
				onClick={() => dispatch({ type: "SET_MODE", payload: "login" })}
			>
				Go Back
			</Button>
		</>
	);

	const renderResetForm = () => (
		<>
			<Text
				style={{
					display: "block",
					marginBottom: "2vh",
					textAlign: "center",
					fontSize: "1.5vh",
				}}
			>
				Enter your email address and we'll send you a link to reset your
				password.
			</Text>
			<Form layout="vertical" onFinish={submitResetPassword}>
				<Form.Item
					label="Email"
					name="resetEmail"
					rules={[
						{
							required: true,
							type: "email",
							message: "Please enter a valid email",
						},
					]}
				>
					<Input
						placeholder="Email"
						prefix={<MailOutlined />}
						value={state.email}
						onChange={(e) =>
							dispatch({ type: "SET_EMAIL", payload: e.target.value })
						}
					/>
				</Form.Item>
				<Form.Item style={{ marginTop: "4vh" }}>
					<Button type="primary" htmlType="submit" block>
						Submit
					</Button>
				</Form.Item>
			</Form>
			<Divider>OR</Divider>
			<Button
				type="default"
				block
				onClick={() => dispatch({ type: "SET_MODE", payload: "login" })}
			>
				Go Back
			</Button>
		</>
	);

	return (
		<Layout style={{ minHeight: "100dvh" }}>
			<Content
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					padding: "5vh 2vw",
				}}
			>
				<Card style={{ width: "90vw", maxWidth: "50rem", padding: "4vh 2vw" }}>
					<div style={{ textAlign: "center", marginBottom: "4vh" }}>
						<img
							src={logo}
							alt="Facility AI Logo"
							style={{ maxHeight: "20vh", width: "auto" }}
						/>
					</div>
					{state.mode === "login" && renderLoginForm()}
					{state.mode === "create" && renderCreateForm()}
					{state.mode === "reset" && renderResetForm()}
				</Card>
			</Content>
			<Footer
				style={{ textAlign: "center", fontSize: "1.5vh", padding: "2vh 0" }}
			>
				<div style={{ marginBottom: "0.25rem" }}>
					Powered by Facility AI
					<br />
					&copy; {new Date().getFullYear()}{" "}
					<Link target="_blank" href="https://datafleet.com">
						Data Fleet LLC
					</Link>
					<SendEmail />
				</div>
			</Footer>
		</Layout>
	);
};
