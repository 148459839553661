// FacilityAI/src/components/layouts/SignedIn/views/Admin/ManageOCRTab/ManageOCRTabRefactor.tsx

import { DownloadOutlined } from "@ant-design/icons";
import {
	Button,
	Card,
	Divider,
	Skeleton,
	Space,
	Switch,
	Tooltip,
	Typography,
	message,
} from "antd";
import { KeyboardEvent, MouseEvent, useEffect, useReducer } from "react";
import "@/components/layouts/SignedIn/views/Admin/ManageOCRTab/ManagerOCRTab.scss";
import { auth } from "@/assets/services/auth-service";
import { DFPEmpty } from "@/components/Empty/dfp-empty";
import { DFPTable } from "@/components/Tables/dfp-table";
import {
	BaseTableAction,
	BaseTableState,
	baseInitialState,
	baseTableReducer,
} from "@/components/Tables/sharedTableState";
import { CopyableTableCell } from "@/components/Typography/dfp-typography";
import { deleteTemplate } from "@/services/ocr-service";
import Search from "antd/es/input/Search";
import type { ColumnType } from "antd/es/table";
import { CSVLink } from "react-csv";
import { useLocation } from "react-router-dom";
import { ColumnConfigDropdown } from "../SubmissionsTable/ColumnConfigDropdown";
import { DeleteButton } from "../SubmissionsTable/DeleteButton";
import { DeleteModal } from "../SubmissionsTable/DeleteModal";
import { getTableProps } from "../SubmissionsTable/childFunctions/getTableProps";
import { ViewTemplateModal } from "./view-ocr-template-modal";

interface OCRTabState extends BaseTableState {
	templateData: Template[] | null;
	isError: boolean;
	viewerSettings: ViewerSettings;
	searchVal: string;
	columnsShown: Record<string, boolean>;
	total: number;
	showDeleteModal: boolean;
}

interface ViewerSettings {
	templateName: string | null;
	isOpen: boolean;
}

interface Template {
	name: string;
	manufacturer?: string;
	date_added: string | null;
	hits: number;
	last_hit: string | null;
	average_accuracy: number;
	enabled: boolean;
	template_id: string;
	image_name: string;
	isLoading?: boolean;
}

type Action =
	| BaseTableAction
	| { type: "SET_TEMPLATES"; payload: Template[] }
	| { type: "SET_DATA_SOURCE"; payload: Template[] }
	| { type: "SET_ERROR"; payload: boolean }
	| { type: "OPEN_VIEWER"; payload: string }
	| { type: "CLOSE_VIEWER" }
	| { type: "TOGGLE_ENABLED"; payload: string }
	| {
			type: "SET_SORT";
			payload: { sortBy: string; sortOrder: "asc" | "desc" | undefined };
	  }
	| { type: "SET_COLUMNS_SHOWN"; payload: Record<string, boolean> }
	| { type: "SET_TOTAL"; payload: number }
	| { type: "SET_SHOW_DELETE_MODAL"; payload: boolean };

const initialState: OCRTabState = {
	...baseInitialState,
	templateData: null,
	isError: false,
	viewerSettings: {
		templateName: null,
		isOpen: false,
	},
	columnsShown: {
		name: true,
		manufacturer: true,
		date_added: true,
		hits: true,
		last_hit: true,
		average_accuracy: true,
		enabled: true,
	},
	total: 0,
	tableColumns: [
		{
			title: <Typography.Text strong>Template</Typography.Text>,
			dataIndex: "name",
			key: "name",
			render: (text, record) => (
				<CopyableTableCell text={text} isLoading={record.isLoading} />
			),
			width: "20%",
		},
		{
			title: <Typography.Text strong>Manufacturer</Typography.Text>,
			dataIndex: "manufacturer",
			key: "manufacturer",
			render: (text, record) => (
				<CopyableTableCell text={text} isLoading={record.isLoading} />
			),
			width: "20%",
		},
		{
			title: <Typography.Text strong>Date Added</Typography.Text>,
			dataIndex: "date_added",
			key: "date_added",
			render: (date: string | null, record) => {
				return <Skeleton.Input style={{ width: 100 }} active />;
			},
			width: "15%",
		},
		{
			title: <Typography.Text strong>Hits</Typography.Text>,
			dataIndex: "hits",
			key: "hits",
			render: (hits: number, record) => (
				<CopyableTableCell text={String(hits)} isLoading={record.isLoading} />
			),
			width: "10%",
		},
		{
			title: <Typography.Text strong>Last Hit</Typography.Text>,
			dataIndex: "last_hit",
			key: "last_hit",
			render: (date: string | null, record) => (
				<Skeleton.Input style={{ width: 100 }} active />
			),
			width: "10%",
		},
		{
			title: <Typography.Text strong>Accuracy</Typography.Text>,
			dataIndex: "average_accuracy",
			key: "average_accuracy",
			render: (accuracy: number, record) => (
				<CopyableTableCell
					text={`${Math.round(accuracy * 100)}%`}
					isLoading={record.isLoading}
				/>
			),
			width: "15%",
		},
		{
			title: <Typography.Text strong>Enabled</Typography.Text>,
			dataIndex: "enabled",
			key: "enabled",
			render: (enabled: boolean, record: Template) => {
				if (record.isLoading) {
					return <Skeleton.Input style={{ width: 100 }} active />;
				}
			},
			width: "15%",
		},
	],
	showDeleteModal: false,
};

function reducer(state: OCRTabState, action: Action): OCRTabState {
	switch (action.type) {
		case "SET_TEMPLATES":
			return {
				...state,
				templateData: action.payload,
				isError: false,
			};
		case "SET_ERROR":
			return { ...state, isError: action.payload };
		case "OPEN_VIEWER":
			return {
				...state,
				viewerSettings: { templateName: action.payload, isOpen: true },
			};
		case "CLOSE_VIEWER":
			return {
				...state,
				viewerSettings: { templateName: null, isOpen: false },
			};
		case "TOGGLE_ENABLED":
			const updatedTemplates = state.templateData?.map((template) =>
				template.template_id === action.payload
					? { ...template, enabled: !template.enabled }
					: template,
			);
			return { ...state, templateData: updatedTemplates };
		case "SET_SORT":
			return {
				...state,
				sortBy: action.payload.sortBy,
				sortOrder: action.payload.sortOrder,
			};
		case "SET_SHOW_DELETE_MODAL":
			return { ...state, showDeleteModal: action.payload };
		default:
			return baseTableReducer(state, action);
	}
}

const ManageOCRTab: React.FC = () => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const location = useLocation();

	useEffect(() => {
		const preservedState =
			location.state ||
			JSON.parse(sessionStorage.getItem("manageOCRTabState") || "{}");
		if (preservedState.searchVal)
			dispatch({ type: "SET_SEARCH_VAL", payload: preservedState.searchVal });
		if (preservedState.columnsShown)
			dispatch({
				type: "SET_COLUMNS_SHOWN",
				payload: preservedState.columnsShown,
			});
	}, [location.state]);

	const {
		sortBy,
		sortOrder,
		columnsShown,
		templateData,
		viewerSettings,
		tableColumns,
	} = state;

	useEffect(() => {
		if (!templateData) return;
		const toggleSwitch = async (
			e: MouseEvent | KeyboardEvent,
			templateId: string,
		) => {
			e?.stopPropagation();
			console.log(state.templateData);
			const template = state.templateData?.find(
				(t) => t.template_id === templateId,
			);
			console.log(template);

			try {
				const token = await auth?.currentUser?.getIdToken();
				const response = await fetch(
					`${
						import.meta.env.VITE_ALFRED_SERVICE_URL
					}/FacilityVision/OCR/templates/enable/${template.template_id}`,
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
						body: JSON.stringify({ isEnabled: !template.enabled }),
						method: "POST",
					},
				);

				if (response.status !== 200)
					throw new Error("Failed to toggle template");
				dispatch({ type: "TOGGLE_ENABLED", payload: template.template_id });
				message.success(
					`Template "${template.name}" has been ${
						!template.enabled ? "enabled" : "disabled"
					}.`,
				);
			} catch (error) {
				message.error("Failed to toggle the template. Please try again later.");
				console.error("Error toggling template:", error);
			}
		};

		// Define the columns after toggleSwitch is defined
		const tableColumns: ColumnType<Template>[] = [
			{
				title: <Typography.Text strong>Template</Typography.Text>,
				dataIndex: "name",
				key: "name",
				sorter: true,
				sortOrder:
					sortBy === "name" && sortOrder
						? sortOrder === "asc"
							? "ascend"
							: "descend"
						: undefined,
				sortDirections: ["descend", "ascend", "descend"],
				render: (text, record) => (
					<CopyableTableCell text={text} isLoading={record.isLoading} />
				),
				width: "20%",
			},
			{
				title: <Typography.Text strong>Manufacturer</Typography.Text>,
				dataIndex: "manufacturer",
				key: "manufacturer",
				sorter: true,
				sortOrder:
					sortBy === "manufacturer" && sortOrder
						? sortOrder === "asc"
							? "ascend"
							: "descend"
						: undefined,
				sortDirections: ["descend", "ascend", "descend"],
				render: (text, record) => (
					<CopyableTableCell text={text} isLoading={record.isLoading} />
				),
				width: "20%",
			},
			{
				title: <Typography.Text strong>Date Added</Typography.Text>,
				dataIndex: "date_added",
				key: "date_added",
				sorter: true,
				sortOrder:
					sortBy === "date_added" && sortOrder
						? sortOrder === "asc"
							? "ascend"
							: "descend"
						: undefined,
				sortDirections: ["descend", "ascend", "descend"],
				render: (date: string | null, record) => {
					if (record.isLoading) {
						return <Skeleton.Input style={{ width: 100 }} active />;
					}
					return date ? (
						<CopyableTableCell
							text={new Date(date).toLocaleDateString()}
							isLoading={record.isLoading}
						/>
					) : (
						"No Date"
					);
				},
				width: "15%",
			},
			{
				title: <Typography.Text strong>Hits</Typography.Text>,
				dataIndex: "hits",
				key: "hits",
				sorter: true,
				sortOrder:
					sortBy === "hits" && sortOrder
						? sortOrder === "asc"
							? "ascend"
							: "descend"
						: undefined,
				sortDirections: ["descend", "ascend", "descend"],
				render: (hits: number, record) => (
					<CopyableTableCell text={String(hits)} isLoading={record.isLoading} />
				),
				width: "10%",
			},
			{
				title: <Typography.Text strong>Last Hit</Typography.Text>,
				dataIndex: "last_hit",
				key: "last_hit",
				sorter: true,
				sortOrder:
					sortBy === "last_hit" && sortOrder
						? sortOrder === "asc"
							? "ascend"
							: "descend"
						: undefined,
				sortDirections: ["descend", "ascend", "descend"],
				render: (date: string | null, record) => {
					if (record.isLoading) {
						return <Skeleton.Input style={{ width: 100 }} active />;
					}
					return date ? (
						<CopyableTableCell
							text={new Date(date).toLocaleDateString()}
							isLoading={record.isLoading}
						/>
					) : (
						"No Date"
					);
				},
				width: "10%",
			},
			{
				title: <Typography.Text strong>Accuracy</Typography.Text>,
				dataIndex: "average_accuracy",
				key: "average_accuracy",
				sorter: true,
				sortOrder:
					sortBy === "average_accuracy" && sortOrder
						? sortOrder === "asc"
							? "ascend"
							: "descend"
						: undefined,
				sortDirections: ["descend", "ascend", "descend"],
				render: (accuracy: number, record) => (
					<CopyableTableCell
						text={`${Math.round(accuracy * 100)}%`}
						isLoading={record.isLoading}
					/>
				),
				width: "15%",
			},
			{
				title: <Typography.Text strong>Enabled</Typography.Text>,
				dataIndex: "enabled",
				key: "enabled",
				sorter: true,
				sortOrder:
					sortBy === "enabled" && sortOrder
						? sortOrder === "asc"
							? "ascend"
							: "descend"
						: undefined,
				sortDirections: ["descend", "ascend", "descend"],
				render: (enabled: boolean, record: Template) => {
					if (record.isLoading) {
						return <Skeleton.Input style={{ width: 100 }} active />;
					}
					return (
						<Switch
							checked={enabled}
							onChange={(_, e) => {
								e?.stopPropagation();
								toggleSwitch(e, record.template_id);
							}}
						/>
					);
				},
				width: "15%",
			},
		];
		dispatch({ type: "SET_TABLE_COLUMNS", payload: tableColumns });
	}, [templateData]);

	// Fetch templates from the API
	useEffect(() => {
		const fetchTemplates = async () => {
			try {
				dispatch({ type: "SET_IS_LOADING", payload: true });
				const token = await auth?.currentUser?.getIdToken();
				const response = await fetch(
					`${
						import.meta.env.VITE_ALFRED_SERVICE_URL
					}/FacilityVision/OCR/templates`,
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
						method: "GET",
					},
				);
				if (response.status !== 200)
					throw new Error("Endpoint returned non-200 status");
				const data = await response.json();

				dispatch({ type: "SET_TEMPLATES", payload: data.templates });
			} catch (error) {
				dispatch({ type: "SET_ERROR", payload: true });
				message.error("⚠️ Failed to load templates, please try again later ⚠️");
				console.error("Error fetching data:", error);
			}
		};
		fetchTemplates();
	}, []);

	// Handle filtering and sorting
	useEffect(() => {
		if (state.templateData) {
			let filteredData = state.templateData.filter(
				(template) =>
					template.name.toLowerCase().includes(state.searchVal.toLowerCase()) ||
					template.manufacturer
						?.toLowerCase()
						.includes(state.searchVal.toLowerCase()),
			);

			dispatch({ type: "SET_TOTAL", payload: filteredData.length });

			if (state.sortBy && state.sortOrder) {
				filteredData = filteredData.sort((a, b) => {
					let compareA: any = a[state.sortBy as keyof Template];
					let compareB: any = b[state.sortBy as keyof Template];

					if (typeof compareA === "string") compareA = compareA.toLowerCase();
					if (typeof compareB === "string") compareB = compareB.toLowerCase();

					if (compareA < compareB) return state.sortOrder === "asc" ? -1 : 1;
					if (compareA > compareB) return state.sortOrder === "asc" ? 1 : -1;
					return 0;
				});
			}
			console.log(filteredData);
			dispatch({ type: "SET_DATA_SOURCE", payload: filteredData });
			dispatch({ type: "SET_IS_LOADING", payload: false });
			const persistState = {
				searchVal: state.searchVal,
				columnsShown,
				total: filteredData.length,
			};
			sessionStorage.setItem("manageOCRTabState", JSON.stringify(persistState));
		}
	}, [
		state.templateData,
		state.searchVal,
		state.sortBy,
		state.sortOrder,
		columnsShown,
	]);

	const openViewer = (templateName: string) =>
		dispatch({ type: "OPEN_VIEWER", payload: templateName });
	const closeViewer = () => dispatch({ type: "CLOSE_VIEWER" });
	const handleSearch = (value: string) =>
		dispatch({ type: "SET_SEARCH_VAL", payload: value });

	const tableProps = getTableProps(state, dispatch, {
		handleRowClick: (record) => openViewer(record.image_name),
		rowKey: "template_id",
	});

	const onDelete = async () => {
		if (state?.checkedItems?.length) {
			const deletePromises = state.checkedItems.map((item) =>
				deleteTemplate(String(item))
					.then(() => ({ item, success: true }))
					.catch((error) => ({ item, success: false, error })),
			);

			const results = await Promise.all(deletePromises);
			const errors = results.filter((result) => !result.success);
			const successes = results.filter((result) => result.success);

			if (successes.length) {
				message.success(`${successes.length} items deleted successfully.`);
			}

			if (errors.length) {
				errors.forEach((error) =>
					console.error(`Error deleting item ${error.item}:`, error.error),
				);
				message.error(`${errors.length} items failed to delete.`);
			}
		} else {
			message.warning("No items selected for deletion.");
		}
	};

	const csvData = state.dataSource || [];

	return (
		<Card className="manage-ocr-tab mt-2">
			<Space direction="vertical" style={{ width: "100%" }} size="large">
				<Space style={{ display: "flex", justifyContent: "space-between" }}>
					<Space>
						<Search
							placeholder="Search"
							allowClear
							style={{ width: 300 }}
							onSearch={handleSearch}
							onChange={(e) => handleSearch(e.target.value)}
							value={state.searchVal}
							disabled={state.isLoading}
						/>
					</Space>
					<Space>
						<ColumnConfigDropdown
							state={{ tableColumns, columnsShown }}
							dispatch={dispatch}
						/>
						<DeleteButton
							disabled={!state.checkedItems?.length}
							onClick={() => {
								dispatch({ type: "SET_SHOW_DELETE_MODAL", payload: true });
							}}
						/>
						<Tooltip title="Export Templates">
							<CSVLink data={csvData} filename="templates.csv" target="_blank">
								<Button
									icon={<DownloadOutlined style={{ fontSize: "20px" }} />}
									type="text"
									disabled={state.isLoading || csvData.length === 0}
								/>
							</CSVLink>
						</Tooltip>
					</Space>
				</Space>
				{state.isError && (
					<Typography.Text type="danger">
						⚠️ Failed to load templates, please try again later ⚠️
					</Typography.Text>
				)}
				<Divider className="m-0" />
				<DFPTable
					locale={{
						emptyText: state.isLoading ? null : (
							<DFPEmpty
								className="w-100 h-100"
								description="No submissions returned. Please modify your search term or update your filters and try again."
							/>
						),
					}}
					{...tableProps}
				/>
			</Space>
			<ViewTemplateModal
				viewerSettings={viewerSettings}
				onClose={closeViewer}
			/>
			<DeleteModal
				state={state}
				dispatch={dispatch}
				tableType="templates"
				onOk={onDelete}
			/>
		</Card>
	);
};

export { ManageOCRTab };
