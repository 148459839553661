// File: OCRTemplateReviewer.tsx

import { auth } from "@/assets/services/auth-service";
import { DFPTable } from "@/components/Tables/dfp-table";
import { CopyableTableCell } from "@/components/Typography/dfp-typography";
import { DownloadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import {
	Button,
	Card,
	Divider,
	Modal,
	Skeleton,
	Space,
	Tooltip,
	Typography,
	message,
} from "antd";
import Search from "antd/es/input/Search";
import type { ColumnType, TablePaginationConfig } from "antd/es/table";
import { useEffect, useReducer, useState } from "react";
import { TestTemplateModal } from "./TestTemplateModal";
import { ViewTemplateModal } from "./ViewTemplateModal";
import "./ocr-template-reviewer.scss";
import { DFPEmpty } from "@/components/Empty/dfp-empty";
import {
	BaseTableAction,
	baseInitialState,
	baseTableReducer,
} from "@/components/Tables/sharedTableState";
import { CSVLink } from "react-csv";
import { DeleteButton } from "../SubmissionsTable/DeleteButton";
import { getTableProps } from "../SubmissionsTable/childFunctions/getTableProps";

interface Template {
	name: string;
	manufacturer: string;
	template_id: string;
	isLoading?: boolean;
}

interface ViewerSettings {
	templateName: string | null;
	isOpen: boolean;
	templateId: string | null;
}

interface TesterSettings {
	templateName: string | null;
	isOpen: boolean;
	templateId: string | null;
}

interface State {
	templateData: Template[] | null;
	displayedData: Template[] | null;
	isLoading: boolean;
	isError: boolean;
	searchVal: string;
	sortBy: string;
	sortOrder: "asc" | "desc" | undefined;
	columnsShown: Record<string, boolean>;
	total: number;
	viewerSettings: ViewerSettings;
	testerSettings: TesterSettings;
}

type Action =
	| BaseTableAction
	| { type: "SET_TEMPLATES"; payload: Template[] }
	| { type: "SET_DISPLAYED_DATA"; payload: Template[] }
	| { type: "SET_ERROR"; payload: boolean }
	| { type: "SET_SEARCH_VAL"; payload: string }
	| {
			type: "SET_SORT";
			payload: { sortBy: string; sortOrder: "asc" | "desc" | undefined };
	  }
	| { type: "SET_COLUMNS_SHOWN"; payload: Record<string, boolean> }
	| { type: "SET_TOTAL"; payload: number }
	| {
			type: "OPEN_VIEWER";
			payload: { templateName: string; templateId: string };
	  }
	| { type: "CLOSE_VIEWER" }
	| {
			type: "OPEN_TESTER";
			payload: { templateName: string; templateId: string };
	  }
	| { type: "CLOSE_TESTER" };

const initialState: State = {
	...baseInitialState,
	templateData: null,
	displayedData: null,
	isError: false,
	searchVal: "",
	sortBy: "name",
	columnsShown: {
		manufacturer: true,
		name: true,
		delete: true,
	},
	total: 0,
	viewerSettings: {
		templateName: null,
		isOpen: false,
		templateId: null,
	},
	testerSettings: {
		templateName: null,
		isOpen: false,
		templateId: null,
	},
};

function reducer(state: State, action: Action): State {
	switch (action.type) {
		case "SET_TEMPLATES":
			return {
				...state,
				templateData: action.payload,
			};
		case "SET_DISPLAYED_DATA":
			return { ...state, displayedData: action.payload };
		case "SET_ERROR":
			return { ...state, isError: action.payload };
		case "SET_SEARCH_VAL":
			return { ...state, searchVal: action.payload };
		case "SET_SORT":
			return {
				...state,
				sortBy: action.payload.sortBy,
				sortOrder: action.payload.sortOrder,
			};
		case "SET_COLUMNS_SHOWN":
			return { ...state, columnsShown: action.payload };
		case "SET_TOTAL":
			return { ...state, total: action.payload };
		case "OPEN_VIEWER":
			return {
				...state,
				viewerSettings: {
					templateName: action.payload.templateName,
					isOpen: true,
					templateId: action.payload.templateId,
				},
			};
		case "CLOSE_VIEWER":
			return {
				...state,
				viewerSettings: { templateName: null, isOpen: false, templateId: null },
			};
		case "OPEN_TESTER":
			return {
				...state,
				testerSettings: {
					templateName: action.payload.templateName,
					templateId: action.payload.templateId,
					isOpen: true,
				},
			};
		case "CLOSE_TESTER":
			return {
				...state,
				testerSettings: { templateName: null, templateId: null, isOpen: false },
			};
		default:
			return baseTableReducer(state, action);
	}
}

export const OCRTemplateReviewer: React.FC = () => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const {
		templateData,
		isLoading,
		isError,
		searchVal,
		sortBy,
		sortOrder,
		columnsShown,
		viewerSettings,
		testerSettings,
	} = state;

	// Custom delete modal states
	const [deleteModalVisible, setDeleteModalVisible] = useState(false);
	const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(
		null,
	);

	// Fetch templates from the API
	useEffect(() => {
		const fetchTemplates = async () => {
			dispatch({ type: "SET_IS_LOADING", payload: true });
			try {
				const token = await auth?.currentUser?.getIdToken();
				const response = await fetch(
					`${
						import.meta.env.VITE_ALFRED_SERVICE_URL
					}/FacilityVision/OCR/review_templates`,
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
						method: "GET",
					},
				);
				if (response.status !== 200)
					throw new Error("Endpoint returned non-200 status");
				const data = await response.json();

				dispatch({ type: "SET_TEMPLATES", payload: data.templates });
			} catch (error) {
				dispatch({ type: "SET_ERROR", payload: true });
				message.error("⚠️ Failed to load templates, please try again later ⚠️");
				console.error("Error fetching data:", error);
			}
		};
		dispatch({ type: "SET_TABLE_COLUMNS", payload: tableColumns });
		fetchTemplates();
	}, []);

	// Handle filtering and sorting
	useEffect(() => {
		if (templateData) {
			let filteredData = templateData.filter(
				(template) =>
					template.name.toLowerCase().includes(searchVal.toLowerCase()) ||
					template.manufacturer.toLowerCase().includes(searchVal.toLowerCase()),
			);

			dispatch({ type: "SET_TOTAL", payload: filteredData.length });

			if (sortBy && sortOrder) {
				filteredData = filteredData.sort((a, b) => {
					let compareA: any = a[sortBy as keyof Template];
					let compareB: any = b[sortBy as keyof Template];

					if (typeof compareA === "string") compareA = compareA.toLowerCase();
					if (typeof compareB === "string") compareB = compareB.toLowerCase();

					if (compareA < compareB) return sortOrder === "asc" ? -1 : 1;
					if (compareA > compareB) return sortOrder === "asc" ? 1 : -1;
					return 0;
				});
			}

			dispatch({ type: "SET_DATA_SOURCE", payload: filteredData });
			dispatch({ type: "SET_IS_LOADING", payload: false });
		}
	}, [templateData, searchVal, sortBy, sortOrder]);

	const handleSearch = (value: string) =>
		dispatch({ type: "SET_SEARCH_VAL", payload: value });

	const handleTableChange = (
		pagination: TablePaginationConfig,
		_: any,
		sorter: any,
	) => {
		if (sorter?.field && sorter.order) {
			dispatch({
				type: "SET_SORT",
				payload: {
					sortBy: sorter.field,
					sortOrder: sorter.order === "ascend" ? "asc" : "desc",
				},
			});
		} else {
			dispatch({
				type: "SET_SORT",
				payload: { sortBy: "name", sortOrder: undefined },
			});
		}
	};

	const openViewer = (templateName: string, templateId: string) => {
		dispatch({ type: "OPEN_VIEWER", payload: { templateName, templateId } });
	};

	const closeViewer = () => {
		dispatch({ type: "CLOSE_VIEWER" });
	};

	const openTester = (templateName: string, templateId: string) => {
		dispatch({ type: "OPEN_TESTER", payload: { templateName, templateId } });
	};

	const closeTester = () => {
		dispatch({ type: "CLOSE_TESTER" });
	};

	const deleteTemplate = async (templateId: string) => {
		try {
			const token = await auth?.currentUser?.getIdToken();
			const response = await fetch(
				`${
					import.meta.env.VITE_ALFRED_SERVICE_URL
				}/FacilityVision/OCR/review_templates/delete?template_id=${templateId}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
					method: "GET",
				},
			);
			if (response.status !== 200)
				throw new Error("Endpoint returned non-200 status");
			message.success("Template deleted successfully");
			const updatedTemplates =
				templateData?.filter(
					(template) => template.template_id !== templateId,
				) || [];
			dispatch({ type: "SET_TEMPLATES", payload: updatedTemplates });
		} catch (error) {
			message.error("Failed to delete template");
			console.error("Error deleting template:", error);
		}
	};

	const confirmDelete = (templateId: string) => {
		setSelectedTemplateId(templateId);
		setDeleteModalVisible(true);
	};

	const handleDeleteConfirm = () => {
		if (selectedTemplateId) {
			deleteTemplate(selectedTemplateId);
		}
		setDeleteModalVisible(false);
		setSelectedTemplateId(null);
	};

	// Define table columns
	const tableColumns: ColumnType<Template>[] = [
		{
			title: <Typography.Text strong>Manufacturer</Typography.Text>,
			dataIndex: "manufacturer",
			key: "manufacturer",
			sorter: true,
			sortOrder:
				sortBy === "manufacturer" && sortOrder
					? sortOrder === "asc"
						? "ascend"
						: "descend"
					: undefined,
			render: (text, record) => (
				<CopyableTableCell text={text} isLoading={record.isLoading} />
			),
			width: "33%",
		},
		{
			title: <Typography.Text strong>Template Name</Typography.Text>,
			dataIndex: "name",
			key: "name",
			sorter: true,
			sortOrder:
				sortBy === "name" && sortOrder
					? sortOrder === "asc"
						? "ascend"
						: "descend"
					: undefined,
			render: (text, record) => (
				<CopyableTableCell text={text} isLoading={record.isLoading} />
			),
			width: "33%",
		},
		{
			title: <Typography.Text strong>Delete</Typography.Text>,
			dataIndex: "delete",
			key: "delete",
			render: (_, record) => {
				if (record.isLoading) {
					return <Skeleton.Input style={{ width: 100 }} active />;
				}
				return (
					<DeleteButton
						disabled={record.isLoading}
						style={{ fontSize: "16px", cursor: "pointer" }}
						onClick={(e) => {
							e.stopPropagation(); // Prevent row click
							confirmDelete(record.template_id);
						}}
					/>
				);
			},
			width: "33%",
		},
	];

	const tableProps = getTableProps(state, dispatch, {
		handleRowClick: (record) =>
			openViewer(`${record.manufacturer}_${record.name}`, record.template_id),
		rowKey: "template_id",
	});

	const csvData = state.dataSource || [];

	return (
		<Card className="manage-ocr-tab mt-4">
			<Space direction="vertical" style={{ width: "100%" }} size="large">
				<Space style={{ display: "flex", justifyContent: "space-between" }}>
					<Space>
						<Search
							placeholder="Search"
							allowClear
							style={{ width: 300 }}
							onSearch={handleSearch}
							onChange={(e) => handleSearch(e.target.value)}
							value={searchVal}
							disabled={isLoading}
						/>
					</Space>
					<Space>
						<Tooltip title="Export Templates">
							<CSVLink data={csvData} filename="templates.csv" target="_blank">
								<Button
									icon={<DownloadOutlined style={{ fontSize: "20px" }} />}
									type="text"
									disabled={state.isLoading || csvData.length === 0}
								/>
							</CSVLink>
						</Tooltip>
					</Space>
				</Space>
				{isError && (
					<Typography.Text type="danger">
						⚠️ Failed to load templates, please try again later ⚠️
					</Typography.Text>
				)}
				<Divider className="m-0" />
				<DFPTable
					locale={{
						emptyText: !state.isLoading && (
							<DFPEmpty
								className="w-100 h-100"
								description="This organization has no OCR templates."
							/>
						),
					}}
					{...tableProps}
				/>
			</Space>
			<ViewTemplateModal
				viewerSettings={viewerSettings}
				onClose={closeViewer}
				onTest={(templateName: string, templateId: string) => {
					closeViewer();
					openTester(templateName, templateId);
				}}
				onDelete={(templateId: string) => {
					closeViewer();
					confirmDelete(templateId);
				}}
			/>
			<TestTemplateModal
				testerSettings={testerSettings}
				onClose={closeTester}
			/>
			<Modal
				open={deleteModalVisible}
				onCancel={() => setDeleteModalVisible(false)}
				onOk={handleDeleteConfirm}
				title="Are you sure you want to delete this template?"
				okButtonProps={{ type: "primary", danger: true }}
				cancelButtonProps={{ type: "primary" }}
			>
				<div className="d-flex flex-column gap-1 justify-content-center align-items-center">
					<Typography.Title type="danger" level={4}>
						<ExclamationCircleOutlined style={{ color: "red" }} /> Warning
					</Typography.Title>
					<Typography.Text>This action cannot be undone.</Typography.Text>
				</div>
			</Modal>
		</Card>
	);
};
