import { DFPEmpty } from "@/components/Empty/dfp-empty";
import { DFPTable } from "@/components/Tables/dfp-table";
import {
	type BaseTableAction,
	type BaseTableState,
	baseInitialState,
	baseTableReducer,
} from "@/components/Tables/sharedTableState";
import { getOrganizationUsers } from "@/services/user-service";
import { useUserStore } from "@/stores";
import { Divider, Input, Space } from "antd";
import { Dispatch, FC, useEffect, useReducer } from "react";
import { ASCENDING } from "../AssetsTable/children/AssetsTable";
import { ColumnConfigDropdown } from "../SubmissionsTable/ColumnConfigDropdown";
import { DeleteButton } from "../SubmissionsTable/DeleteButton";
import { getTableProps } from "../SubmissionsTable/childFunctions/getTableProps";
import { getColumns } from "./UsersTableColumns";
import { AddUserButton } from "./children/AddUserButton";
import { AddUserModal } from "./children/AddUserModal";

const { Search } = Input;

export interface User {
	id: string | number;
	name: string;
	email: string;
	role: string;
	role_id: number;
	organization_id: number;
}

// Define action types
export type Action =
	| { type: "SET_ALL_USERS"; payload: User[] }
	| { type: "SET_SHOW_ADD_USER_MODAL"; payload: boolean }
	| BaseTableAction;

// Initial state
export interface State extends BaseTableState {
	allUsers: User[];
	showAddUserModal: boolean;
}

const initialState: State = {
	...baseInitialState,
	allUsers: [],
	sortBy: "name",
	sortOrder: ASCENDING,
	showAddUserModal: false,
};

function reducer(state: State, action: Action): State {
	switch (action.type) {
		case "SET_ALL_USERS":
			return { ...state, allUsers: action.payload };
		case "SET_SHOW_ADD_USER_MODAL":
			return { ...state, showAddUserModal: action.payload };
		default:
			return baseTableReducer(state, action);
	}
}

const UsersTable: FC = () => {
	// Generate columns
	const [state, dispatch] = useReducer(reducer, initialState);
	const {
		sortBy,
		sortOrder,
		pageNum,
		pageSize,
		searchVal,
		isLoading,
		allUsers,
	} = state;
	const user = useUserStore((state: any) => state.user);
	const organization = user?.organization?.id;

	const getUsers = async (dispatch: Dispatch<Action>) => {
		dispatch({ type: "SET_IS_LOADING", payload: true });
		const users = await getOrganizationUsers();
		dispatch({ type: "SET_ALL_USERS", payload: users });
	};

	const getRows = (state: State, dispatch: Dispatch<Action>) => {
		// local search and sort
		dispatch({ type: "SET_IS_LOADING", payload: true });
		let searchedUsers = allUsers;
		if (state.searchVal) {
			searchedUsers = allUsers.filter((user) => {
				return Object.values(user).some((value) =>
					value
						.toString()
						.toLowerCase()
						.includes(state.searchVal.toLowerCase()),
				);
			});
		}
		// Apply sorting based on `sortBy` and `sortOrder`
		const sortedUsers = searchedUsers.sort((a, b) => {
			if (a[state.sortBy] < b[state.sortBy]) {
				return state.sortOrder === ASCENDING ? -1 : 1;
			}
			if (a[state.sortBy] > b[state.sortBy]) {
				return state.sortOrder === ASCENDING ? 1 : -1;
			}
			return 0;
		});
		dispatch({ type: "SET_DATA_SOURCE", payload: sortedUsers });
		dispatch({ type: "SET_IS_LOADING", payload: false });
	};

	useEffect(() => {
		const columns = getColumns(
			() => getUsers(dispatch),
			organization,
			user?.role_id,
		);
		dispatch({
			type: "SET_TABLE_COLUMNS",
			payload: columns,
		});
		dispatch({
			type: "SET_COLUMNS_SHOWN",
			payload: columns.reduce((acc, col) => {
				acc[col.key] = true;
				return acc;
			}, {}),
		});
	}, [dispatch, organization, user?.role_id]);

	useEffect(() => {
		getUsers(dispatch);
	}, [organization]);

	useEffect(() => {
		if (allUsers.length > 0) {
			getRows(state, dispatch);
		}
	}, [pageNum, pageSize, sortBy, sortOrder, searchVal, allUsers]);

	const tableProps = getTableProps(state, dispatch, undefined);

	return (
		<div className="table-card">
			<div className="table-header d-flex justify-content-between">
				<Search
					placeholder="Search"
					allowClear
					style={{ width: 300 }}
					onSearch={(value: string) => {
						dispatch({ type: "SET_SEARCH_VAL", payload: value });
					}}
					disabled={isLoading}
				/>
				<Space>
					<AddUserButton
						state={state}
						dispatch={dispatch}
						disabled={user.role_id !== 4}
					/>
					<ColumnConfigDropdown state={state} dispatch={dispatch} />
					<DeleteButton state={undefined} disabled={true} />
				</Space>
			</div>
			<Divider />
			<DFPTable
				locale={{
					emptyText: !state.isLoading && (
						<DFPEmpty
							className="w-100 h-100"
							description="No users found. Please create a new user, or adjust your search filters."
						/>
					),
				}}
				{...tableProps}
			/>
			<AddUserModal state={state} dispatch={dispatch} getUsers={getUsers} />
		</div>
	);
};

export { UsersTable };
