import { CopyableTableCell } from "@/components/Typography/dfp-typography";
import { parseDateToLocalString } from "@/utils/transforms";
import { ColumnsType } from "antd/es/table";
import { Draft } from "../SubmissionsTable";

// Define table columns
export const draftColumns: ColumnsType<Draft> = [
	{
		title: "Address",
		dataIndex: "address",
		key: "address",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		width: "30%",
		render: (text, record) => (
			<CopyableTableCell text={text} isLoading={record.isLoading} />
		),
	},
	{
		title: "Submitting User",
		dataIndex: "submitting_user",
		key: "submitting_user",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (text, record) => (
			<CopyableTableCell text={text} isLoading={record.isLoading} />
		),
	},
	{
		title: "Submission Date",
		dataIndex: "submitted_date",
		key: "submitted_date",
		sorter: true,
		defaultSortOrder: "descend",
		sortDirections: ["descend", "ascend", "descend"],
		render: (text, record) => (
			<CopyableTableCell
				text={parseDateToLocalString(text)}
				isLoading={record.isLoading}
			/>
		),
	},
];
