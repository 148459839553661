import { adminUpdateUser } from "@/services/user-service";
import { message } from "antd";
import { User } from "../views/Admin/UsersTable/UsersTable";

export const handleSave = async (
	row: User,
	getUsers: () => Promise<void>,
	organizationId: number,
) => {
	try {
		if (row.name === "") {
			message.error("Name cannot be empty");
			return;
		}
		await adminUpdateUser(row.id, {
			name: row.name,
			role_id: row.role_id,
			organization_id: organizationId,
		});
		message.success("User updated");
		await getUsers();
	} catch (error) {
		console.error("Failed to update user:", error);
	}
};
