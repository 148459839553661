import { CopyableTableCell } from "@/components/Typography/dfp-typography";
import { Skeleton } from "antd";
import { EditableCell } from "../../../TableComponents/EditableCell";
import { handleSave } from "../../../TableComponents/handleEditSave";

const roleOptions = [
	{ value: 1, label: "USER" },
	{ value: 2, label: "VERIZON" },
	{ value: 3, label: "ADMIN" },
	{ value: 4, label: "SUPERUSER" },
];

export const getColumns = (
	getUsers: () => Promise<void>,
	organizationId: number,
	role_id: number,
) => [
	{
		title: "Name",
		dataIndex: "name",
		key: "name",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (text, record) => {
			if (record.isLoading) return <Skeleton.Input active size="small" />;
			return role_id === 4 ? (
				<EditableCell
					value={text}
					record={record}
					dataIndex="name"
					handleSave={(record) => handleSave(record, getUsers, organizationId)}
				/>
			) : (
				<CopyableTableCell text={text} />
			);
		},
		width: "33%",
	},
	{
		title: "Email",
		dataIndex: "email",
		key: "email",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (text, record) => (
			<CopyableTableCell text={text} isLoading={record.isLoading} />
		),
		width: "33%",
	},
	{
		title: "Role",
		dataIndex: ["role", "name"],
		key: "role",
		render: (text, record) => (
			<CopyableTableCell text={text} isLoading={record.isLoading} />
		),
		width: "33%",
	},
];
