import { Loader } from "@/components/layouts/SignedIn/Loader/Loader";
import { useGetSelfOrganizationReports } from "@/hooks/organizationQueries";
import { Card } from "antd";
import { useEffect, useState } from "react";
import Select from "react-select";
import { Button, CardBody, Spinner } from "reactstrap";
import {
	CampingWorldReport,
	generateCWReport,
} from "../Reports/CampingWorldReport";
import { generateCvsScReportFromXlsx } from "../Reports/CvsScReport";
import { callGenerateCvsWeeklyReportHttp } from "../Reports/CvsWeeklyReport";
import {
	LeaveBehindReport,
	generateLeaveBehindReport,
} from "../Reports/LeaveBehindReport";
import { LocationPicker } from "../Reports/ReportForms/LocationPicker";
import { XlsxFileInput } from "../Reports/ReportForms/XlsxFileInput";

const reportConfigurations = {
	serviceChannelAssetReport: {
		component: XlsxFileInput, // Component for XLSX file input
		generateFunction: generateCvsScReportFromXlsx, // Function to handle report generation
		tooltip:
			"Must be an .xlsx file. For this report to generate properly, the first column must contain the store numbers.",
	},
	cvsWeeklyReport: { generateFunction: callGenerateCvsWeeklyReportHttp },
	leaveBehindReport: {
		component: LocationPicker,
		generateFunction: generateLeaveBehindReport,
		type: "Leave Behind Report",
	},
	campingWorldReport: {
		component: LocationPicker,
		generateFunction: generateCWReport,
		type: "Camping World Report",
	},
};

const customStyles = {
	control: (provided) => ({
		...provided,
		color: "#000", // This is for the text in the control
	}),
	option: (provided, state) => ({
		...provided,
		color: state.isSelected ? "#000" : "#000", // Text color for all options
		backgroundColor: state.isSelected
			? "lightgray"
			: state.isFocused
				? "lightblue"
				: null,
	}),
	singleValue: (provided) => ({
		...provided,
		color: "#000", // This is for the selected value text color
	}),
};

export const ReportsTab = ({ organization, showToast }) => {
	const [selectedReport, setSelectedReport] = useState(null);
	// state to store the value that is needed to generate the report, like a file or a location
	const [selectedDependencyValue, setSelectedDependencyValue] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [tooltip, setTooltip] = useState(null);
	const [reportOptions, setReportOptions] = useState([]);
	const {
		userOrganizationReports,
		error: userOrganizationReportsError,
		isLoading: isLoadingUserOrganizationReports,
	} = useGetSelfOrganizationReports();

	// update later once more reports are added
	useEffect(() => {
		if (!isLoadingUserOrganizationReports) {
			getOrgReports();
		}
	}, [organization, isLoadingUserOrganizationReports]);

	const getOrgReports = async () => {
		//revisit_sql_forms
		const newOptions = [];
		if (
			userOrganizationReports?.some(
				(report) =>
					report.master_report_key.report_name === "Leave Behind Report",
			)
		) {
			newOptions.push({
				value: "leaveBehindReport",
				label: "Leave Behind Report",
			});
		}
		if (organization.external_firebase_id === "cvs.com") {
			newOptions.push({
				value: "cvsWeeklyReport",
				label: "CVS Locations Weekly Report",
			});
			newOptions.push({
				value: "serviceChannelAssetReport",
				label: "Service Channel Asset Report",
			});
		}
		if (organization.external_firebase_id === "campingworld.com") {
			newOptions.push({
				value: "campingWorldReport",
				label: "Camping World Report",
			});
		}
		setReportOptions(newOptions);
	};

	const handleFileSelect = (e) => {
		const file = e.target.files[0];
		setSelectedDependencyValue(file);
	};

	const handleReportSelect = (selectedOption) => {
		setSelectedDependencyValue(null);
		const report = reportConfigurations[selectedOption.value];
		const tooltip = report.tooltip;
		setSelectedReport(report);
		if (tooltip) {
			setTooltip(reportConfigurations[selectedOption.value].tooltip);
		} else {
			setTooltip(null);
		}
	};

	const handleGenerateReport = async () => {
		if (selectedReport?.generateFunction) {
			setIsLoading(true);

			try {
				// change logic later once different conditions are added. for now pass the file if there is a component attached to the report
				if (selectedReport.component) {
					selectedReport.generateFunction(
						selectedDependencyValue,
						setIsLoading,
						showToast,
					);
				} else {
					selectedReport.generateFunction(setIsLoading, showToast);
				}
			} catch (error) {
				console.error("Error generating report:", error);
				setIsLoading(false);
			}
		}
	};

	if (isLoadingUserOrganizationReports !== false) {
		return <Loader />;
	}

	return (
		<Card
			className="my-3"
			style={{ overflow: "visible" }}
			title={<span className="fw-bold">Download Reports</span>}
		>
			<CardBody>
				<Select
					id="report-select"
					options={reportOptions}
					onChange={handleReportSelect}
					className="mb-3 report-dropdown reports-tab-select"
					styles={customStyles}
					placeholder="Select a report"
				/>

				{tooltip && <div className="text-gray fs-6">{tooltip}</div>}
				<div className="d-flex" style={{ marginTop: "20px", color: "black" }}>
					{selectedReport && (
						<Button
							onClick={handleGenerateReport}
							style={{
								width: "100px",
								textWrap: "nowrap",
								marginRight: "10px",
								backgroundColor: "#BF1E2D",
							}}
						>
							{isLoading ? <Spinner size="sm" /> : "Generate"}
						</Button>
					)}
					{selectedReport?.component && (
						<selectedReport.component
							selectedDependencyValue={selectedDependencyValue}
							setSelectedDependencyValue={setSelectedDependencyValue}
							onFileSelect={handleFileSelect}
							organization={organization}
						/>
					)}
				</div>
				{selectedReport && selectedReport.type === "Leave Behind Report" && (
					<LeaveBehindReport
						selectedDependencyValue={selectedDependencyValue}
						showToast={showToast}
						organization={organization}
						isLoading={isLoading}
						setIsLoading={setIsLoading}
					/>
				)}
				{selectedReport && selectedReport.type === "Camping World Report" && (
					<CampingWorldReport
						selectedDependencyValue={selectedDependencyValue}
						showToast={showToast}
						organization={organization}
						isLoading={isLoading}
						setIsLoading={setIsLoading}
					/>
				)}
			</CardBody>
		</Card>
	);
};
