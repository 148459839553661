import { CopyableTableCell } from "@/components/Typography/dfp-typography";
import { HealthScorePill } from "@/components/layouts/SignedIn/HealthScorePill/HealthScorePill";
import { Skeleton } from "antd";
import { DB_ORG } from "@/constants/db";
import type { ColumnsType } from "antd/es/table";

export const columns: ColumnsType = [
	{
		title: "Address",
		dataIndex: "address1",
		key: "address1",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		filterMode: "tree",
		filterSearch: true,
		onFilter: (value, record) => true,
		render: (text, record) => (
			<CopyableTableCell text={text} isLoading={record.isLoading} />
		),
		width: "30%",
	},
	{
		title: "City",
		dataIndex: "city",
		key: "city",
		width: "15%",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (text, record) => (
			<CopyableTableCell text={text} isLoading={record.isLoading} />
		),
	},
	{
		title: "State",
		dataIndex: "state",
		key: "state",
		width: "10%",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (text, record) => (
			<CopyableTableCell text={text} isLoading={record.isLoading} />
		),
	},
	{
		title: "Zip",
		dataIndex: "zip",
		key: "zip",
		width: "10%",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (text, record) => (
			<CopyableTableCell text={text} isLoading={record.isLoading} />
		),
	},
	{
		title: "Submissions",
		dataIndex: "submission_count",
		key: "submission_count",
		width: "10%",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (text, record) => (
			<CopyableTableCell
				text={text ? String(text) : "0"}
				isLoading={record.isLoading}
			/>
		),
	},
	{
		title: "Manager",
		dataIndex: "manager",
		key: "manager",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (text, record) => (
			<CopyableTableCell
				text={text || "Not Assigned"}
				isLoading={record.isLoading}
			/>
		),
		width: 200,
	},
	{
		title: "Health Score",
		dataIndex: ["health, health_score"],
		key: "health_score",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (_healthScore, record) =>
			record.isLoading ? (
				<Skeleton.Input style={{ width: 120 }} active size="small" />
			) : (
				<HealthScorePill healthScore={record?.health?.health_score} />
			),
	},
];

export const cushmanColumns: ColumnsType = [
	{
		title: "Site Name",
		dataIndex: "name",
		key: "name",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		filterMode: "tree",
		filterSearch: true,
		onFilter: (value, record) => true,
		render: (text, record) => (
			<CopyableTableCell text={text} isLoading={record.isLoading} />
		),
		width: "25%",
	},
	{
		title: "City",
		dataIndex: "city",
		key: "city",
		width: "15%",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (text, record) => (
			<CopyableTableCell text={text} isLoading={record.isLoading} />
		),
	},
	{
		title: "State",
		dataIndex: "state",
		key: "state",
		width: "10%",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (text, record) => (
			<CopyableTableCell text={text} isLoading={record.isLoading} />
		),
	},
	{
		title: "Zip",
		dataIndex: "zip",
		key: "zip",
		render: (text) => text || "N/A",
		width: "10%",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (text, record) => (
			<CopyableTableCell text={text} isLoading={record.isLoading} />
		),
	},
	{
		title: "Submissions",
		dataIndex: "submission_count",
		key: "submission_count",
		width: "10%",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (text, record) => (
			<CopyableTableCell
				text={text ? String(text) : "0"}
				isLoading={record.isLoading}
			/>
		),
	},
	{
		title: "Site/Facility Analyst",
		dataIndex: "contacts",
		key: "contacts",
		render: (contacts, record) => {
			if (record.isLoading) {
				return <Skeleton.Input style={{ width: 100 }} active size="small" />;
			}
			if (!contacts || !Array.isArray(contacts)) return "Not Assigned";
			// Filter for the desired role and sort by id in descending order in one step
			const filteredContacts = contacts
				.filter((contact) => contact.role === "GRE Senior Analyst")
				.sort((a, b) => b.id - a.id);
			// Return the name of the most recent contact, or a fallback if none found
			return filteredContacts.length > 0
				? filteredContacts[0].name
				: "Not Assigned";
		},
		width: "15%",
	},
	{
		title: "Location Health",
		dataIndex: "health_score",
		key: "health_score",
		sorter: true,
		sortDirections: ["descend", "ascend"],
		render: (_healthScore, record) =>
			record.isLoading ? (
				<Skeleton.Input style={{ width: 120 }} active size="small" />
			) : (
				<HealthScorePill healthScore={record?.health?.health_score} />
			),
	},
];

export const getOrgColumns = (organization: any): ColumnsType => {
	switch (organization) {
		case DB_ORG.CUSHMANWAKEFIELD:
			return cushmanColumns;
		default:
			return columns;
	}
};
