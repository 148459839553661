// SubmissionRC.tsx

import { CopyableCellRenderer } from "@/components/Tables/Renderers/copyable-cell-renderer";
import { FilePdfOutlined, LeftOutlined } from "@ant-design/icons";
import { getSubmissionDataRC } from "@services/submission-service";
import { useFormStore, useUserStore } from "@stores";
import { isValidUrl } from "@utils/validation";
import {
	Button,
	Card,
	Descriptions,
	Image,
	Spin,
	Typography,
	message,
	theme,
} from "antd";
import dayjs from "dayjs";
import html2pdf from "html2pdf.js";
import { useEffect, useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { LuXCircle } from "react-icons/lu";
import { MdDownload } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";

const { Title } = Typography;

interface Question {
	label: string;
	value: any;
	type: string;
	key?: string;
	responseTag?: string;
	name?: string;
	format?: {
		formatFn?: string;
		inputSuffix?: string;
	};
	fields?: Question[]; // For dynamicList or group fields
}

interface Section {
	title: string;
	questions?: Question[];
	fields?: Question[];
}

interface SubmissionData {
	title?: string;
	sections?: Section[];
}

interface DynamicSubmissionDetailProps {
	submissionId: string;
	formId: string;
	toggleSubmission?: () => void;
	quickView?: boolean;
	setModalIndex?: React.Dispatch<React.SetStateAction<number>>;
	responsesLength?: number;
}

const DynamicSubmissionDetail: React.FC<DynamicSubmissionDetailProps> = (
	props,
) => {
	const {
		submissionId,
		formId,
		toggleSubmission,
		quickView = false,
		setModalIndex,
		responsesLength = 0,
	} = props;

	const location = useLocation();
	const navigate = useNavigate();
	const { user } = useUserStore();
	const { setIsEditingRaisingCanes } = useFormStore();
	const { token } = theme.useToken();

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [submissionData, setSubmissionData] = useState<SubmissionData>({});
	const [formName, setFormName] = useState<string>("");
	const [formLogo, setFormLogo] = useState<string>("");
	const [dateOfVisit, setDateOfVisit] = useState<string>("");
	const [address, setAddress] = useState<string>("");
	const [pdfLoading, setPdfLoading] = useState<boolean>(false);
	const [locationId, setLocationId] = useState<string>("");

	useEffect(() => {
		const fetchSubmission = async () => {
			try {
				await getSubmissionDataRC(
					formId,
					submissionId,
					(data: SubmissionData) => setSubmissionData(data),
					(name: string) => setFormName(name),
					(logo: string) => setFormLogo(logo),
					(addr: string) => setAddress(addr),
					(date: string) => setDateOfVisit(dayjs(date).format("YYYY-MM-DD")),
					(locId: string) => setLocationId(locId),
				);
			} catch (error) {
				console.error("Error fetching submission data:", error);
				message.error("Failed to load submission data.");
			} finally {
				setIsLoading(false);
			}
		};

		fetchSubmission();
	}, [formId, submissionId]);

	const canUserEdit = (): boolean => {
		return user.role.access_level >= 1000;
	};

	const editSubmission = () => {
		if (!canUserEdit()) return;
		setIsEditingRaisingCanes(true);
		navigate(`/forms/${formId}`, {
			state: {
				isEditing: true,
				inputs: submissionData,
				submissionId,
				dateOfVisit,
				address,
				path: location.pathname,
				locationId,
				formId,
			},
		});
	};

	const downloadPdf = () => {
		setPdfLoading(true);
		try {
			const element = document.getElementById("submission-content");
			if (element) {
				const imageElements = element.getElementsByTagName("img");
				const imagePromises = Array.from(imageElements).map((img) => {
					return new Promise<void>((resolve, reject) => {
						if (img.complete) {
							resolve();
						} else {
							img.onload = () => resolve();
							img.onerror = () => reject();
						}
					});
				});

				Promise.all(imagePromises)
					.then(() => {
						const opt = {
							margin: [0.75, 0.25, 0.75, 0.25],
							filename: `${formName} - ${address}.pdf`,
							image: { type: "jpeg", quality: 1 },
							html2canvas: {
								scale: 1,
								useCORS: true,
								ignoreElements: (element: HTMLElement) => {
									const className = element.getAttribute("class");
									if (className?.includes("submission-edit-log")) {
										return true;
									}
									if (className?.includes("ant-typography-copy")) {
										return true;
									}
									return false;
								},
							},
							jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
							pagebreak: { mode: ["avoid-all", "css"] },
						};

						html2pdf()
							.from(element)
							.set(opt)
							.save()
							.then(() => {
								setPdfLoading(false);
							});
					})
					.catch((error) => {
						console.error("Error loading images:", error);
						message.error("Failed to load images for PDF.");
						setPdfLoading(false);
					});
			}
		} catch (error) {
			console.error("Error generating PDF:", error);
			message.error("Failed to generate PDF.");
			setPdfLoading(false);
		}
	};

	/**
	 * Flatten fields for CSV export.
	 * This function recursively handles:
	 * - Simple fields: returns a single row
	 * - Image fields with multiple URLs: returns multiple rows (one per image)
	 * - DynamicList fields (array): processes each item and returns multiple rows
	 * - Group fields: recursively processes subfields
	 *
	 * @param fields array of questions/fields
	 * @param sectionTitle title of the current section
	 * @param parentLabel optional parent label to nest field names
	 * @returns array of {Section, Question, Answer}
	 */
	const flattenFieldsForCSV = (
		fields: Question[],
		sectionTitle: string,
		parentLabel = "",
	): { Section: string; Question: string; Answer: string }[] => {
		const rows: { Section: string; Question: string; Answer: string }[] = [];

		for (const field of fields) {
			if (
				field.value === undefined ||
				field.value === null ||
				field.value === ""
			) {
				continue;
			}

			const baseLabel = parentLabel
				? `${parentLabel} > ${field.label}`
				: field.label;

			// Handle dynamicList fields (value is array of objects)
			if (
				field.type === "dynamicList" &&
				Array.isArray(field.value) &&
				field.fields
			) {
				const items = field.value as Record<string, any>[];
				items.forEach((item, index) => {
					// Append index if more than one item
					const itemLabel =
						items.length > 1 ? `${baseLabel} (#${index + 1})` : baseLabel;
					rows.push(
						...flattenFieldsForCSV(
							field.fields.map((f) => ({
								...f,
								value: item[f.name || f.key || f.responseTag || ""],
							})),
							sectionTitle,
							itemLabel,
						),
					);
				});
				continue;
			}

			// Handle group fields: just a nested set of fields
			if (
				field.fields &&
				Array.isArray(field.fields) &&
				field.fields.length > 0
			) {
				// Treat it similarly to dynamicList but without indexing
				rows.push(
					...flattenFieldsForCSV(field.fields, sectionTitle, baseLabel),
				);
				continue;
			}

			// Handle image fields
			if (field.type === "image") {
				const images = Array.isArray(field.value) ? field.value : [field.value];
				const validImages = images.filter((url: string) => isValidUrl(url));
				if (validImages.length === 0) continue;
				validImages.forEach((url, index) => {
					const imageLabel =
						validImages.length > 1 ? `${baseLabel} (#${index + 1})` : baseLabel;
					rows.push({
						Section: sectionTitle,
						Question: imageLabel,
						Answer: url,
					});
				});
				continue;
			}

			// Handle date fields
			if (field.type === "date") {
				const dateVal = dayjs(field.value).isValid()
					? dayjs(field.value).format("MM/DD/YYYY")
					: field.value.toString();
				rows.push({
					Section: sectionTitle,
					Question: baseLabel,
					Answer: dateVal,
				});
				continue;
			}

			// Handle location field
			if (field.type === "location") {
				if (address) {
					rows.push({
						Section: sectionTitle,
						Question: baseLabel,
						Answer: address,
					});
				}
				continue;
			}

			// Boolean fields
			if (typeof field.value === "boolean") {
				rows.push({
					Section: sectionTitle,
					Question: baseLabel,
					Answer: field.value ? "Yes" : "No",
				});
				continue;
			}

			// If the value is an array of primitives
			if (
				Array.isArray(field.value) &&
				field.value.every(
					(v: any) => typeof v === "string" || typeof v === "number",
				)
			) {
				rows.push({
					Section: sectionTitle,
					Question: baseLabel,
					Answer: field.value.join(", "),
				});
				continue;
			}

			// If object (not dynamicList)
			if (typeof field.value === "object" && !Array.isArray(field.value)) {
				rows.push({
					Section: sectionTitle,
					Question: baseLabel,
					Answer: JSON.stringify(field.value),
				});
				continue;
			}

			// Format string/number values if needed
			let displayValue = field.value;
			if (field.format) {
				let valStr = displayValue?.toString() ?? "";
				if (field.format.formatFn) {
					try {
						const fFn = eval(`(${field.format.formatFn})`);
						if (typeof fFn === "function") {
							valStr = fFn(valStr);
						}
					} catch (e) {
						console.warn("Failed to parse formatFn:", e);
					}
				}
				if (field.format.inputSuffix && typeof valStr === "string") {
					valStr = `${valStr} ${field.format.inputSuffix}`;
				}
				displayValue = valStr;
			}

			rows.push({
				Section: sectionTitle,
				Question: baseLabel,
				Answer: displayValue.toString(),
			});
		}

		return rows;
	};

	const exportToCSV = () => {
		try {
			const sections =
				submissionData.sections ||
				(submissionData as any)?.[0]?.sections ||
				([] as Section[]);

			let formattedData: {
				Section: string;
				Question: string;
				Answer: string;
			}[] = [];

			for (const section of sections) {
				const fields = section.fields || section.questions || [];
				formattedData.push(...flattenFieldsForCSV(fields, section.title));
			}

			// Generate CSV content
			const headers = ["Section", "Question", "Answer"];
			const csvRows = [headers.join(",")];

			for (const row of formattedData) {
				const values = headers.map((header) => {
					const escapedValue = String(row[header as keyof typeof row]).replace(
						/"/g,
						'""',
					);
					return `"${escapedValue}"`;
				});
				csvRows.push(values.join(","));
			}

			const csvContent = csvRows.join("\n");
			const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

			const link = document.createElement("a");
			if (link.download !== undefined) {
				const url = URL.createObjectURL(blob);
				link.setAttribute("href", url);
				link.setAttribute(
					"download",
					`${formName || "Submission"} - ${address || "Details"}.csv`,
				);
				link.style.visibility = "hidden";
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		} catch (error) {
			console.error("Error exporting to CSV:", error);
			message.error("Failed to export to CSV.");
		}
	};

	/**
	 * Recursively render the value of a question in the UI.
	 * Similar logic to CSV flattening, but returns JSX elements instead of arrays of rows.
	 */
	const renderQuestionValue = (
		question: Question,
		token: any,
	): React.ReactNode => {
		let { value } = question;

		if (
			value === undefined ||
			value === null ||
			value === "" ||
			(Array.isArray(value) && value.length === 0)
		) {
			return null;
		}

		if (
			Array.isArray(value) &&
			value.length > 0 &&
			typeof value[0] === "object" &&
			question.fields
		) {
			return (
				<>
					{value.map((item: Record<string, any>, itemIndex: number) => (
						<Descriptions
							key={`${question.name || question.key}-dynamic-${itemIndex}`}
							bordered
							column={1}
							size="small"
							style={{ marginBottom: "16px" }}
							labelStyle={{ fontWeight: "bold", width: "30%" }}
						>
							{question.fields?.map((subField) => {
								const fieldName =
									subField.name || subField.key || subField.responseTag;
								if (!fieldName) return null;

								const fieldVal = item[fieldName];
								const renderedVal = renderQuestionValue(
									{ ...subField, value: fieldVal },
									token,
								);

								if (
									renderedVal === null ||
									(Array.isArray(renderedVal) && renderedVal.length === 0)
								) {
									return null;
								}

								return (
									<Descriptions.Item
										key={fieldName}
										label={
											subField.label
												? `${subField.label}${
														value.length > 1 ? ` (#${itemIndex + 1})` : ""
													}`
												: fieldName
										}
									>
										{renderedVal}
									</Descriptions.Item>
								);
							})}
						</Descriptions>
					))}
				</>
			);
		}

		if (question.type === "image") {
			const images = Array.isArray(value) ? value : [value];
			const validImages = images.filter((url: string) => isValidUrl(url));
			if (validImages.length === 0) return null;
			return validImages.map((url: string, imageIndex: number) => (
				<Image
					key={`${question.key || question.name}-image-${imageIndex}`}
					src={url}
					alt={question.label}
					wrapperClassName="w-50 cursor-pointer"
					style={{ maxWidth: "300px", maxHeight: "300px" }}
					wrapperStyle={{ maxWidth: "300px", maxHeight: "300px" }}
					preview={{
						destroyOnClose: true,
						closeIcon: (
							<span
								className="p-2 bg-white"
								style={{ borderRadius: token.borderRadiusDefault }}
							>
								<LuXCircle size={"1.5rem"} color={token.colorPrimary} />
							</span>
						),
						maskStyle: { backgroundColor: token.colorBgMask },
						toolbarRender(originalNode) {
							return (
								<div
									style={{
										color: token.colorPrimary,
										backgroundColor: token.colorWhite,
										borderRadius: token.borderRadiusDefault,
									}}
								>
									{originalNode}
								</div>
							);
						},
					}}
				/>
			));
		}

		if (question.type === "location") {
			return address ? <CopyableCellRenderer value={address} /> : null;
		}

		if (question.type === "date") {
			const dateVal = dayjs(value || dateOfVisit).format("MM/DD/YYYY");
			return <CopyableCellRenderer value={dateVal} />;
		}

		if (typeof value === "boolean") {
			return <CopyableCellRenderer value={value ? "Yes" : "No"} />;
		}

		if (typeof value === "object" && !Array.isArray(value)) {
			const strVal = JSON.stringify(value);
			return <CopyableCellRenderer value={strVal} />;
		}

		if (
			Array.isArray(value) &&
			value.every((v) => typeof v === "string" || typeof v === "number")
		) {
			value = value.join(", ");
		}

		let displayValue = value;

		if (question.format) {
			let valStr = displayValue?.toString() ?? "";
			if (question.format.formatFn) {
				try {
					const fFn = eval(`(${question.format.formatFn})`);
					if (typeof fFn === "function") {
						valStr = fFn(valStr);
					}
				} catch (e) {
					console.warn("Failed to parse formatFn:", e);
				}
			}
			if (question.format.inputSuffix && typeof valStr === "string") {
				valStr = `${valStr} ${question.format.inputSuffix}`;
			}
			displayValue = valStr;
		}

		if (typeof displayValue === "string" || typeof displayValue === "number") {
			return <CopyableCellRenderer value={displayValue.toString()} />;
		}

		return displayValue;
	};

	const generateQuestions = () => {
		const renderedKeys = new Set<string>();
		const items: React.ReactNode[] = [];

		const sections =
			submissionData.sections ||
			(submissionData as any)?.[0]?.sections ||
			([] as Section[]);

		sections?.forEach((section, i) => {
			const fields = section.fields || section.questions || [];
			const fieldsHaveValues = fields.some(
				(question) =>
					question.value !== undefined &&
					question.value !== null &&
					question.value !== "" &&
					(!Array.isArray(question.value) || question.value.length > 0),
			);
			if (!fieldsHaveValues) {
				return;
			}

			items.push(
				<Descriptions.Item key={`section-${i}`} span={3}>
					<Title level={4}>{section.title}</Title>
				</Descriptions.Item>,
			);

			fields.forEach((question, j) => {
				const questionKey =
					question.key || question.responseTag || question.name || `${i}-${j}`;
				if (renderedKeys.has(questionKey)) {
					console.warn(`Duplicate key found: ${questionKey}`);
					return;
				}
				renderedKeys.add(questionKey);

				const displayValue = renderQuestionValue(question, token);
				if (
					displayValue === null ||
					(Array.isArray(displayValue) && displayValue.length === 0)
				) {
					return;
				}

				items.push(
					<Descriptions.Item key={questionKey} label={question.label} span={1}>
						{displayValue}
					</Descriptions.Item>,
				);
			});
		});

		return (
			<Descriptions
				bordered
				column={1}
				labelStyle={{ fontWeight: "bold", width: "30%" }}
				contentStyle={{}}
			>
				{items}
			</Descriptions>
		);
	};

	return isLoading ? (
		<div
			style={{ textAlign: "center", marginTop: "50px", minHeight: "80dvh" }}
			className="h-100 d-flex flex-column align-content-center align-items-center justify-content-center"
		>
			<Spin size="large" tip="Loading submission data..." />
		</div>
	) : (
		<div className="h-100">
			<Card className="p-3 submission-wrapper" id="submission">
				<div className="submission-header d-flex flex-row align-items-center justify-content-between h-100">
					{!quickView ? (
						toggleSubmission && (
							<Button
								type="link"
								onClick={toggleSubmission}
								icon={<LeftOutlined />}
							>
								Back to Submissions
							</Button>
						)
					) : (
						<Button
							type="link"
							icon={<LeftOutlined />}
							onClick={() => {
								setIsLoading(true);
								if (setModalIndex) {
									setModalIndex((prev) =>
										prev === 0 ? responsesLength - 1 : prev - 1,
									);
								}
								setTimeout(() => setIsLoading(false), 500);
							}}
						/>
					)}
					{toggleSubmission && (
						<div
							className={`d-flex gap-2 ${quickView ? "" : "align-content-start"}`}
						>
							<div className="d-flex flex-row gap-2">
								<Button icon={<MdDownload />} onClick={exportToCSV}>
									CSV
								</Button>
								<Button
									onClick={downloadPdf}
									loading={pdfLoading}
									icon={<FilePdfOutlined />}
								>
									PDF
								</Button>
							</div>
							<Button
								type="primary"
								disabled={!canUserEdit()}
								onClick={editSubmission}
								icon={<AiOutlineEdit />}
							>
								Edit
							</Button>
						</div>
					)}
					{quickView && (
						<Button
							type="link"
							icon={<LeftOutlined style={{ transform: "rotate(180deg)" }} />}
							onClick={() => {
								setIsLoading(true);
								if (setModalIndex) {
									setModalIndex((prev) =>
										prev === responsesLength - 1 ? 0 : prev + 1,
									);
								}
								setTimeout(() => setIsLoading(false), 500);
							}}
						/>
					)}
				</div>
				<div id="submission-content">
					{formLogo && (
						<div style={{ textAlign: "center", marginBottom: "20px" }}>
							<img
								src={formLogo}
								alt="Form Logo"
								style={{ maxWidth: "200px", maxHeight: "200px" }}
							/>
						</div>
					)}
					<div
						className="d-flex flex-row align-items-center justify-content-between"
						style={{
							backgroundColor: "#ffffff",
							border: "none",
						}}
					>
						<Title level={2} className="submission-title">
							{formName}
						</Title>
					</div>
					{generateQuestions()}
				</div>
			</Card>
		</div>
	);
};

export { DynamicSubmissionDetail };
