import { normalizeStringForComparison } from "@/utils/transforms";
import { escapeQuotes } from "@/utils/transforms";
import { DB_ORG } from "@constants/db";
import { useEffect } from "react";
import { CSVDownload } from "react-csv";
import { FORM_IDS } from "./constant";

const flattenObjectWithOrder = (
	obj,
	parent = "",
	res = {},
	nullReplacement = "N/A",
	order = [],
) => {
	for (const key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			const propName = parent ? `${parent}.${key}` : key;

			if (Array.isArray(obj[key])) {
				// Handle arrays
				const array = obj[key];
				if (array.length === 0) {
					res[propName] = nullReplacement;
					order.push(propName);
				} else {
					array.forEach((item, index) => {
						const arrayPropName = `${propName}[${index}]`;
						if (typeof item === "object" && item !== null) {
							flattenObjectWithOrder(
								item,
								arrayPropName,
								res,
								nullReplacement,
								order,
							);
						} else {
							// Handle primitive values in array
							let value =
								item !== undefined &&
								item !== null &&
								String(item).trim() !== ""
									? item
									: nullReplacement;
							value = escapeQuotes(value);
							res[arrayPropName] = value;
							order.push(arrayPropName);
						}
					});
				}
			} else if (typeof obj[key] === "object" && obj[key] !== null) {
				// Handle nested objects
				flattenObjectWithOrder(obj[key], propName, res, nullReplacement, order);
			} else {
				// Handle primitive values
				let value =
					obj[key] !== undefined &&
					obj[key] !== null &&
					String(obj[key]).trim() !== ""
						? obj[key]
						: nullReplacement;
				value = escapeQuotes(value);
				res[propName] = value;
				order.push(propName);
			}
		}
	}
	return { res, order };
};

// Helper function to format column names from snake_case.notation to a human-readable format
const formatColumnName = (name: string) => {
	return name
		.replace(/\./g, ">") // Replace dot notation with spaces
		.replace(/_/g, " ") // Replace underscores with spaces
		.replace(/\b\w/g, (c: string) => c.toUpperCase()); // Capitalize the first letter of each word
};

// Helper function to identify if a field is a flat (primitive) type
const isPrimitiveField = (field: string | string[]): boolean =>
	!field.includes(".");

// Helper function to calculate the depth of a property based on the dot notation
const getPropertyDepth = (property: string): number =>
	property.split(".").length;

// Group and sort fields where flat fields come first, then nested fields
const groupFieldsWithFlatFirst = (fields: string[]): string[] => {
	const flatFields: string[] = [];
	const nestedFields: string[] = [];

	// Separate flat and nested fields
	for (const field of fields) {
		if (isPrimitiveField(field)) {
			flatFields.push(field);
		} else {
			nestedFields.push(field);
		}
	}

	// Sort flat fields alphabetically
	flatFields.sort((a, b) => a.localeCompare(b));

	// Put id to the front
	const idIndex = flatFields.indexOf("id");
	if (idIndex > -1) {
		flatFields.splice(idIndex, 1);
		flatFields.unshift("id");
	}

	// Group nested fields by their top-level parent, then sort
	const nestedGroups: { [x: string]: string[] } = {};
	for (const field of nestedFields) {
		const topLevelParent = field.split(".")[0];
		if (!nestedGroups[topLevelParent]) {
			nestedGroups[topLevelParent] = [];
		}
		nestedGroups[topLevelParent].push(field);
	}

	// Iterate over the actual keys of nestedGroups and sort them
	for (const key of Object.keys(nestedGroups)) {
		if (nestedGroups[key]) {
			nestedGroups[key].sort(
				(a: string, b: string) =>
					getPropertyDepth(a) - getPropertyDepth(b) || a.localeCompare(b),
			);
		}
	}

	const sortedNestedFields: string[] =
		Object.values(nestedGroups).flat<string[][]>();

	// Merge flat fields first, then nested fields
	return [...flatFields, ...sortedNestedFields];
};

interface CSVExporterProps {
	data: any[];
	filename?: string;
	fields?: string[];
	delimiter?: string;
	transform?: CallableFunction;
	fieldTransforms?: { [x: string]: CallableFunction };
	autoDownload?: boolean;
	nullReplacement?: string;
	organization?: string;
	formId?: string;
	onComplete?: () => void;
}

const CSVExporter = ({
	data,
	filename = "export.csv",
	fields = [],
	delimiter = ",",
	transform = flattenObjectWithOrder,
	fieldTransforms = {},
	autoDownload = true,
	nullReplacement = "N/A",
	organization,
	formId,
	onComplete, // Destructure onComplete from props
}: CSVExporterProps) => {
	// Process and flatten data, applying the order and transformations
	const processData = () => {
		return data.map((item: any) => {
			const { res: flattenedItem, order } = transform(
				item,
				"",
				{},
				nullReplacement,
				[],
			);

			const sortedOrder =
				organization === DB_ORG.CUSHMANWAKEFIELD ||
				(organization === DB_ORG.RAISINGCANES &&
					normalizeStringForComparison(formId || "") ===
						normalizeStringForComparison(
							FORM_IDS.PROPERTY_CONDITION_ASSESSMENT,
						))
					? fields.length > 0
						? fields
						: order
					: groupFieldsWithFlatFirst(fields.length > 0 ? fields : order);

			const transformedItem: { [x: string]: unknown } = {};
			for (const field of sortedOrder) {
				const rawValue = flattenedItem[field];
				const transformFn: CallableFunction = fieldTransforms[field];

				// Apply transformation if provided, else fall back to rawValue or nullReplacement
				transformedItem[formatColumnName(field)] = transformFn
					? transformFn(rawValue, flattenedItem)
					: rawValue !== null && rawValue !== undefined && rawValue !== ""
						? rawValue
						: nullReplacement; // Default replacement for null/undefined
			}

			return transformedItem;
		});
	};

	const csvData = processData();

	// Use useEffect to call onComplete after the component mounts
	useEffect(() => {
		if (onComplete) {
			// Call onComplete after a slight delay to ensure CSVDownload has initiated
			const timer = setTimeout(() => {
				onComplete();
			}, 0);

			return () => clearTimeout(timer);
		}
	}, [onComplete]);

	return (
		<>
			{autoDownload && (
				<CSVDownload
					data={csvData}
					filename={filename}
					target="_blank"
					separator={delimiter}
				/>
			)}
		</>
	);
};

export { CSVExporter };
