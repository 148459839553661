import { DB_ORG } from "@constants/db";
import type { User as FirebaseUser } from "@firebase/auth";
import { getUserSelf } from "@services/user-service";
import { UserAccessLevel, useUserStore } from "@stores/userStore";
import { useQuery } from "@tanstack/react-query";
import { message } from "antd";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { auth } from "./assets/services/auth-service";
import { ProtectedRoute } from "./assets/services/protected-route";
import { Loader } from "./components/layouts/SignedIn/Loader/Loader";
import { ENV } from "./constants/env";
import { Landing } from "./views/Landing/Landing";
import { Login } from "./views/Login/Login";

export const App: FC = () => {
	message.config({ maxCount: 1 });
	useScript("https://static.matterport.com/showcase-sdk/latest.js");

	function useScript(src: string | null): string {
		const [status, setStatus] = useState<string>(src ? "loading" : "idle");

		useEffect(() => {
			if (!src) {
				setStatus("idle");
				return;
			}

			let script = document.querySelector<HTMLScriptElement>(
				`script[src="${src}"]`,
			);
			if (!script) {
				script = document.createElement("script");
				script.src = src;
				script.async = true;
				script.setAttribute("data-status", "loading");
				document.body.appendChild(script);

				const setAttributeFromEvent = (event: Event) => {
					script?.setAttribute(
						"data-status",
						event.type === "load" ? "ready" : "error",
					);
				};
				script.addEventListener("load", setAttributeFromEvent);
				script.addEventListener("error", setAttributeFromEvent);
			} else {
				setStatus(script.getAttribute("data-status") || "loading");
			}

			const setStateFromEvent = (event: Event) => {
				setStatus(event.type === "load" ? "ready" : "error");
			};

			script.addEventListener("load", setStateFromEvent);
			script.addEventListener("error", setStateFromEvent);

			return () => {
				script?.removeEventListener("load", setStateFromEvent);
				script?.removeEventListener("error", setStateFromEvent);
			};
		}, [src]);

		return status;
	}

	const {
		setAuthInfo,
		setIsAdminOrSuperUser,
		setIsVerizonUser,
		setCanViewVendors,
		setUser,
		setThemeMode,
		themeMode,
	} = useUserStore();

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const { isFetching, refetch } = useQuery({
		queryKey: ["self_user"],
		queryFn: getUserSelf,
		enabled: false,
	});

	useEffect(() => {
		const keys = ["adminStore", "pageStore", "formStore", "locationStore"];
		keys.forEach((key) => {
			if (localStorage.getItem(key)) {
				localStorage.removeItem(key);
			}
		});
	}, []);

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(
			async (result: FirebaseUser | null) => {
				if (isLoading) {
					setIsLoading(false);
				}

				if (result) {
					const user = await refetch();
					if (user.data) {
						setUser(user.data);
						await setAuthInfo({
							loggedIn: true,
							id: result.uid,
							displayName: result.displayName ?? "",
							email: result.email, // Firebase User email is always a string, not nullable
							emailVerified: result.emailVerified,
							org: user.data.organization.external_firebase_id,
							role: user.data.role,
						});
						await setIsAdminOrSuperUser(
							user.data.role.access_level >= UserAccessLevel.ADMIN,
						);
						await setIsVerizonUser(user.data.role.id === 2);
						await setCanViewVendors(
							user.data.organization !== DB_ORG.CUSHMANWAKEFIELD ||
								(user.data.role.access_level >= UserAccessLevel.ADMIN &&
									user.data.organization === DB_ORG.CUSHMANWAKEFIELD),
						);
					}
				}
			},
		);

		return unsubscribe;
	}, [isLoading]);

	useEffect(() => {
		setThemeMode(themeMode);
		try {
			if (ENV === "production" && "serviceWorker" in navigator) {
				navigator.serviceWorker
					.register("/service-worker.js")
					.then((registration) => {
						console.log(
							"Service Worker registered with scope:",
							registration.scope,
						);
						registration.update();
					})
					.catch((error) => {
						console.log("Service Worker registration failed:", error);
					});
			}
		} catch (error) {
			console.log("Service Worker registration failed:", error);
		}
	}, [themeMode]);

	return (
		<>
			{isFetching && isLoading ? (
				<Loader />
			) : (
				<BrowserRouter>
					<Routes>
						<Route path="landing" element={<Landing />} />
						<Route path="login" element={<Login />} />
						<Route path="/*" element={<ProtectedRoute />} />
					</Routes>
				</BrowserRouter>
			)}
		</>
	);
};
