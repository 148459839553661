// DynamicLocationPicker.jsx

import { auth } from "@/assets/services/auth-service";
import { DFPEmpty } from "@/components/Empty/dfp-empty";
import { ALFRED_SERVICE_URL } from "@/constants/env";
import { Button, Input, List, Modal, Spin, message, theme } from "antd";
import { useCallback, useEffect, useReducer } from "react";
import { FiSearch } from "react-icons/fi";
import { HiOutlineLocationMarker } from "react-icons/hi";

const { Search } = Input;

const initialState = {
	isOpen: false,
	isLoading: false,
	searchVal: "",
	searchedLocations: [],
	geoLocation: [],
	latLon: {},
	locationText: "Select a Location",
};

const locationReducer = (state, action) => {
	switch (action.type) {
		case "TOGGLE_MODAL":
			return { ...state, isOpen: action.payload };
		case "SET_LOADING":
			return { ...state, isLoading: action.payload };
		case "SET_SEARCH_VALUE":
			return { ...state, searchVal: action.payload };
		case "SET_SEARCH_RESULTS":
			return { ...state, searchedLocations: action.payload };
		case "SET_GEO_LOCATION":
			return { ...state, geoLocation: action.payload };
		case "SET_LAT_LON":
			return { ...state, latLon: action.payload };
		case "SET_LOCATION_TEXT":
			return { ...state, locationText: action.payload };
		default:
			return state;
	}
};

const DynamicLocationPicker = ({ value, onChange, disabled, placeholder }) => {
	const [state, dispatch] = useReducer(locationReducer, initialState);

	const { token } = theme.useToken();

	const {
		isOpen,
		isLoading,
		searchVal,
		searchedLocations,
		geoLocation,
		latLon,
		locationText,
	} = state;

	// Fetch location data when value (ID) changes or component mounts
	useEffect(() => {
		if (value) {
			// Fetch the location data based on the ID
			const fetchLocationById = async (id) => {
				try {
					const url = `${ALFRED_SERVICE_URL}/location/${id}`;
					const response = await fetch(url, {
						method: "GET",
						headers: {
							Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
						},
					});
					if (response.status !== 200) {
						const errorDetails = await response.json();
						throw new Error(
							errorDetails.detail || "Failed to fetch location data",
						);
					}
					const locationData = await response.json();
					const text = formatLocationText(locationData);
					dispatch({ type: "SET_LOCATION_TEXT", payload: text });
				} catch (error) {
					console.error("Error fetching location by ID:", error);
					message.error("Failed to fetch location data.");
				}
			};
			fetchLocationById(value);
		} else if (!value && state.locationText === "Select a Location") {
			dispatch({ type: "SET_LOCATION_TEXT", payload: "Select a Location" });

			// If no value is set, request geolocation to find the closest location
			requestGeolocation();
		}
	}, [value]);

	const toggleModal = () => {
		const newIsOpen = !isOpen;
		dispatch({ type: "TOGGLE_MODAL", payload: newIsOpen });
		if (newIsOpen) {
			// Request geolocation when the modal is opened
			requestGeolocation();
		} else {
			dispatch({ type: "SET_SEARCH_VALUE", payload: "" });
			dispatch({ type: "SET_LOADING", payload: false });
		}
	};

	const requestGeolocation = () => {
		if (
			!value &&
			state.locationText !== "Select a Location" &&
			"geolocation" in navigator
		) {
			dispatch({ type: "SET_LOADING", payload: true });
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const { latitude, longitude } = position.coords;
					const loc = { latitude, longitude };
					dispatch({ type: "SET_LAT_LON", payload: loc });
					getGeoLocations(latitude, longitude);
				},
				(error) => {
					console.error("Geolocation error:", error);
					message.warning(
						"Location services disabled. Please search for a location.",
					);
					dispatch({ type: "SET_LOADING", payload: false });
				},
				{ enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 },
			);
		}
	};

	const updateSearchValue = useCallback((e) => {
		const val = e.target.value;
		dispatch({ type: "SET_SEARCH_VALUE", payload: val });
		if (val.trim() === "") {
			dispatch({ type: "SET_SEARCH_RESULTS", payload: [] });
			return;
		}
		dispatch({ type: "SET_LOADING", payload: true });
		const debounceTimer = setTimeout(() => {
			searchForLocation(val);
			clearTimeout(debounceTimer);
		}, 500);
	}, []);

	const searchForLocation = async (searchValue) => {
		try {
			const url = `${ALFRED_SERVICE_URL}/location/search/location_picker?search_value=${encodeURIComponent(
				searchValue,
			)}`;
			const response = await fetch(url, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
				},
			});
			if (response.status !== 200) {
				const errorDetails = await response.json();
				throw new Error(errorDetails.detail || "Failed to search for location");
			}
			const results = await response.json();
			dispatch({ type: "SET_SEARCH_RESULTS", payload: results });
		} catch (error) {
			console.error("Location search error:", error);
			message.error("Failed to search for locations.");
		} finally {
			dispatch({ type: "SET_LOADING", payload: false });
		}
	};

	const getGeoLocations = async (latitude, longitude) => {
		try {
			const url = `${ALFRED_SERVICE_URL}/location/geo/search?latitude=${latitude}&longitude=${longitude}`;
			const response = await fetch(url, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${await auth?.currentUser?.getIdToken()}`,
				},
			});
			if (response.status !== 200) {
				const errorDetails = await response.json();
				throw new Error(errorDetails.detail || "Failed to fetch geo-locations");
			}
			const results = await response.json();
			dispatch({ type: "SET_GEO_LOCATION", payload: results });

			// Automatically set the closest location if no value is set
			if (results.length > 0 && !value) {
				selectLocation(results[0]);
			}
		} catch (error) {
			console.error("Geo-location fetch error:", error);
			message.error("Failed to fetch closest locations.");
		} finally {
			dispatch({ type: "SET_LOADING", payload: false });
		}
	};

	const selectLocation = (location) => {
		const text = formatLocationText(location);
		dispatch({ type: "SET_LOCATION_TEXT", payload: text });
		onChange?.(location.id); // Notify the form of the new value
	};

	const formatLocationText = (location) => {
		const name = location?.name || "";
		const address = location?.address1 || "";
		return name || address
			? `${name}${name && address ? ": " : ""}${address}`
			: "Select a Location";
	};

	const renderLocations = () => {
		const locations = searchVal ? searchedLocations || [] : geoLocation || [];

		if (isLoading) {
			return (
				<div style={{ textAlign: "center", marginTop: "20px" }}>
					<Spin tip="Loading locations..." />
				</div>
			);
		}

		if (locations?.length === 0) {
			return (
				<DFPEmpty
					image={
						<HiOutlineLocationMarker size={"4rem"} color={token.colorPrimary} />
					}
					description="No locations found. Please try a different search term."
				/>
			);
		}

		return (
			<List
				itemLayout="vertical"
				dataSource={locations}
				renderItem={(loc, i) => (
					<List.Item
						key={`loc-${loc.id}-${i}`}
						onClick={() => {
							selectLocation(loc);
							toggleModal();
						}}
						style={{ cursor: "pointer" }}
					>
						<div>
							{searchVal === "" && loc.distance && (
								<div style={{ fontSize: "12px", color: "#888" }}>
									{i === 0
										? `Closest Location - ${loc.distance} miles`
										: i === 1
											? `2nd Closest Location - ${loc.distance} miles`
											: i === 2
												? `3rd Closest Location - ${loc.distance} miles`
												: null}
								</div>
							)}
							<div>
								{`${loc.name ? `${loc.name}: ` : ""}${loc.address || loc.address1 || ""}, ${
									loc.city
								}, ${loc.state}`}
							</div>
						</div>
					</List.Item>
				)}
			/>
		);
	};

	return (
		<div>
			<Button
				type="primary"
				size="large"
				loading={isLoading}
				onClick={toggleModal}
				disabled={disabled}
				style={{ width: "100%", textAlign: "left" }}
				icon={<HiOutlineLocationMarker size={"1.25rem"} />}
			>
				{locationText}
			</Button>
			<Modal
				open={isOpen}
				onCancel={toggleModal}
				footer={null}
				title="Select a Location"
				destroyOnClose
			>
				<Search
					placeholder={placeholder || "Type to Search for Location"}
					onChange={updateSearchValue}
					autoFocus={true}
					prefix={<FiSearch />}
					allowClear
					style={{ marginBottom: "20px" }}
				/>
				<div style={{ overflowY: "auto", maxHeight: "400px" }}>
					{renderLocations()}
				</div>
			</Modal>
		</div>
	);
};

export default DynamicLocationPicker;
