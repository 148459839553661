import { BackButton } from "@/components/Buttons/BackButton";
import { DFPTable } from "@/components/Tables/dfp-table";
import {
	BaseTableAction,
	BaseTableState,
	baseInitialState,
	baseTableReducer,
} from "@/components/Tables/sharedTableState";
import { CopyableTableCell } from "@/components/Typography/dfp-typography";
import { HealthScorePill } from "@/components/layouts/SignedIn/HealthScorePill/HealthScorePill";
import { ColumnConfigDropdown } from "@/components/layouts/SignedIn/views/Admin/SubmissionsTable/ColumnConfigDropdown";
import { getTableProps } from "@/components/layouts/SignedIn/views/Admin/SubmissionsTable/childFunctions/getTableProps";
import { formatMillions } from "@/utils/formatNumberToMillions";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useReducer } from "react";
import { exportToCSV } from "./childFunctions/exportCsv";
import { getSummaryRows } from "./childFunctions/getSummaryRows";

const ASCENDING = "asc";
const DESCENDING = "desc";

const columns: ColumnsType = [
	{
		title: "Asset",
		dataIndex: "make",
		key: "make",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (text) => (text ? <CopyableTableCell text={text} /> : "N/A"),
		width: "20%",
	},
	{
		title: "Asset Type",
		dataIndex: "assetType",
		key: "assetType",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (text) => (text ? <CopyableTableCell text={text} /> : "N/A"),
		width: "30%",
	},
	{
		title: "Health Score",
		dataIndex: "healthScore",
		key: "healthScore",
		width: 300,
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (healthScore) => <HealthScorePill healthScore={healthScore} />,
	},
	{
		title: "Useful Life Remaining",
		dataIndex: "usefulLifeRemaining",
		key: "usefulLifeRemaining",
		width: 300,
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (usefulLifeRemaining) =>
			usefulLifeRemaining
				? getRemainingUsefulLifeFromDays(usefulLifeRemaining.value)
				: "N/A",
	},
	{
		title: "Replacement Cost",
		dataIndex: "avgReplCostUSD",
		key: "avgReplCostUSD",
		width: 300,
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (text) => (text ? formatMillions(text) : "N/A"),
	},
];

const getRemainingUsefulLifeFromDays = (days) => {
	if (days <= 0) {
		return "Expired";
	}
	const years = Math.floor(days / 365);
	const months = Math.floor((days % 365) / 30);
	let response = years ? `${years} ${years === 1 ? "year" : "years"}` : "";
	response += months
		? `${response ? " " : ""}${months} ${months === 1 ? "month" : "months"}`
		: "";
	return response || "Broken";
};

const summaryColumns: ColumnsType = [
	{
		title: "Asset Type",
		dataIndex: "assetType",
		key: "assetType",
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (text) => (text ? <CopyableTableCell text={text} /> : "N/A"),
		width: "30%",
	},
	{
		title: "Unit Count",
		dataIndex: "unitCount",
		key: "unitCount",
		width: 300,
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
	},
	{
		title: "Average Useful Life Remaining",
		dataIndex: "usefulLifeRemainingDays",
		key: "usefulLifeRemainingDays",
		width: 300,
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (text) => (text ? getRemainingUsefulLifeFromDays(text) : "N/A"),
	},
	{
		title: "Average Replacement Cost",
		dataIndex: "totalReplCost",
		key: "totalReplCost",
		width: 300,
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (text) => (text ? formatMillions(text) : "N/A"),
	},
	{
		title: "Average Health Score",
		dataIndex: "healthScore",
		key: "healthScore",
		width: 300,
		sorter: true,
		sortDirections: ["descend", "ascend", "descend"],
		render: (healthScore) => <HealthScorePill healthScore={healthScore} />,
	},
];

export interface Asset {
	id: string | number;
	asset_type: string;
	health_score: number | string;
	useful_life_remaining: number | string;
	optimal_replacement_year: number | string;
	replacement_cost: number | string;
}

interface AssetsTableProps {
	assets: Asset[];
}

export type Action =
	| { type: "SET_SHOW_SUMMARY"; payload: boolean }
	| BaseTableAction;

export interface State extends BaseTableState {
	assets: Asset[];
	showSummary: boolean;
}

const initialState: State = {
	...baseInitialState,
	tableColumns: summaryColumns,
	columnsShown: summaryColumns
		.map((column) => column.key)
		.reduce((acc, key) => ({ ...acc, [key]: true }), {}),
	isLoading: false,
	pageSize: 5,
	showSummary: true,
};

function reducer(state: State, action: Action): State {
	switch (action.type) {
		case "SET_SHOW_SUMMARY":
			return { ...state, showSummary: action.payload };
		default:
			return baseTableReducer(state, action);
	}
}

const AssetsTable: React.FC = (props: AssetsTableProps) => {
	const [state, dispatch] = useReducer(reducer, {
		...initialState,
		dataSource: getSummaryRows(props.assets),
	});

	const { sortBy, sortOrder } = state;
	const assets = props.assets;

	useEffect(() => {
		if (!sortBy || !sortOrder) return;

		const sortedData = [...state.dataSource].sort((a, b) => {
			const aValue =
				sortBy === "usefulLifeRemaining"
					? a.usefulLifeRemaining?.value
					: a[sortBy];
			const bValue =
				sortBy === "usefulLifeRemaining"
					? b.usefulLifeRemaining?.value
					: b[sortBy];

			if (sortOrder === ASCENDING) {
				return aValue > bValue ? 1 : -1;
			} else if (sortOrder === DESCENDING) {
				return aValue < bValue ? 1 : -1;
			}
			return 0;
		});

		dispatch({ type: "SET_DATA_SOURCE", payload: sortedData });
	}, [sortBy, sortOrder]);

	const tableProps = getTableProps(state, dispatch, {
		handleRowClick: (record) => {
			const assetType = record.assetType;
			const filteredAssets = assets.filter(
				(asset) => asset.assetType === assetType,
			);
			dispatch({ type: "SET_TABLE_COLUMNS", payload: columns });
			dispatch({ type: "SET_DATA_SOURCE", payload: filteredAssets });
			dispatch({ type: "SET_SHOW_SUMMARY", payload: false });
			dispatch({
				type: "SET_COLUMNS_SHOWN",
				payload: columns
					.map((column) => column.key)
					.reduce((acc, key) => ({ ...acc, [key]: true }), {}),
			});
		},
	});

	return (
		<div className="p-0">
			<div className="d-flex justify-content-between">
				<BackButton
					label={"Back"}
					handler={() => {
						dispatch({ type: "SET_TABLE_COLUMNS", payload: summaryColumns });
						dispatch({
							type: "SET_DATA_SOURCE",
							payload: getSummaryRows(assets),
						});
						dispatch({ type: "SET_SHOW_SUMMARY", payload: true });
						dispatch({
							type: "SET_COLUMNS_SHOWN",
							payload: summaryColumns
								.map((column) => column.key)
								.reduce((acc, key) => ({ ...acc, [key]: true }), {}),
						});
					}}
					show={!state.showSummary}
				/>
				<Space>
					<ColumnConfigDropdown state={state} dispatch={dispatch} />
					<Button
						icon={<DownloadOutlined style={{ fontSize: "20px" }} />}
						type="text"
						onClick={() => exportToCSV(assets)}
					/>
				</Space>
			</div>
			<DFPTable {...tableProps} />
		</div>
	);
};

export { AssetsTable };
